import { FC, useMemo, useState } from "react";

import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { Dropdown, MenuProps, message } from "antd";

import { ISnapshot } from "domains/altCustody/shared/types";
import { useSnapshots } from "domains/altCustody/shared-hooks/useSnapshots";
import { Button } from "common/lib/components";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";

interface IProps {
  handleSelectSnapshot: (snapshot: ISnapshot | null) => void;
  reset: () => void;
}

const SnapshotsFilter: FC<IProps> = ({ handleSelectSnapshot, reset }) => {
  const { t } = useTranslation("");
  const { snapshots = [] } = useAltCustodySelector()

  const [ selectedKey, setSelectedKey ] = useState("");

  const dropdownItems: MenuProps["items"] = useMemo(() => {
    if (!snapshots.length) return [];

    return snapshots
      .map((snapshot: any) => ({
        key: snapshot.id,
        label: snapshot.name,
      }))
      .concat([
        {
          // @ts-ignore
          type: "divider",
        },
        {
          key: "reset",
          label: t("Reset"),
        },
      ]);
  }, [ snapshots ]);

  const handleOpenChange = () => {
    if (!snapshots.length) {
      message.info(t("Add snapshots to be able to filter using them"));
    }
  };

  const handleMenuItemClick: MenuProps["onClick"] = ({ key }) => {
    if (key === "reset") {
      reset()
      return;
    }

    flushSync(() => {
      setSelectedKey(key);
    });
    const selectedSnapshot = snapshots.find((snapshot: any) => `${ snapshot.id }` === `${ key }`);
    selectedSnapshot && handleSelectSnapshot(selectedSnapshot);
  };

  return (
    <Dropdown
      key={ snapshots.length }
      trigger={ [ "click" ] }
      onOpenChange={ handleOpenChange }
      menu={ {
        selectable: true,
        items: dropdownItems,
        onClick: handleMenuItemClick,
        selectedKeys: [ selectedKey ],
      } }
    >
      <Button type="primary">{t("Filter Using Snapshots")}</Button>
    </Dropdown>
  );
};

export default SnapshotsFilter;
