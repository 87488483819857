import { FC, useState, useEffect } from "react";

import { useNavigate, useLocation } from "react-router-dom";
import { Select, Tabs, TabsProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";

import { useScreen } from "common/shared-hooks";
import { useClientsSelector } from "domains/clients";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { PortFolioAnalyticsTab } from "./PortfolioAnalytics";
import PortfolioDocumentsTab from "./PortfolioDocumentsTab";
import { useAppDispatch } from "store/store.hooks";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { Spinner } from "common/lib/components";

import "./portfolioManager.scss";

interface IProps {
}

const PortfolioManager: FC<IProps> = () => {
  const { chartData, isChartDataFetching } = useAltCustodySelector();
  const [ activeTabKey, setActiveTabKey ] = useState<string>('');
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ tabs, setTabs ] = useState<any>([
    {
      key: 'PortfolioAnalytics',
      label: <Spinner>Portfolio Analytics</Spinner>,
      children: <Spinner></Spinner>,
    },
    {
      key: 'PortfolioDocuments',
      label: "Portfolio Documents",
      children: <PortfolioDocumentsTab />,
    },
  ])
  const { pathname, search } = useLocation();
  const { mobile } = useScreen();
  const urlParams = new URLSearchParams(search);
  const tabFromUrl = urlParams.get("tab");
  const navigate = useNavigate();
  
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
    navigate(`${ pathname }?tab=${ key }`);
  };

  const updateTabContents = () => {
    if (chartData.length && chartData !== 'nodata') {
      setTabs([
        {
          key: 'PortfolioAnalytics',
          label: "Portfolio Analytics",
          children: <PortFolioAnalyticsTab />,
        },
        {
          key: 'PortfolioDocuments',
          label: "Portfolio Documents",
          children: <PortfolioDocumentsTab />,
        },
      ])
    } else {
      setTabs([
        {
          key: 'PortfolioDocuments',
          label: "Portfolio Documents",
          children: <PortfolioDocumentsTab />,
        },
      ])
    }
  }

  useEffect(() => {
    updateTabContents()
    onTabChange(tabFromUrl || 'PortfolioDocuments'); 
    if ((!chartData?.length || chartData === 'nodata' ) && tabFromUrl === 'PortfolioAnalytics') {
      onTabChange('PortfolioDocuments'); 
    }
  }, [ chartData ])
  
  useEffect(() => {
    setIsLoading(isChartDataFetching); 
  }, [ isChartDataFetching ])

  if (isLoading) {
    return (<><Spinner /></>)
  }

  if (mobile) {
    return (
      <>
        <Select
          onSelect={ setActiveTabKey }
          value={ activeTabKey }
          className="ben-select"
          suffixIcon={ <DownOutlined style={ { color: "#fff" } } /> }
        >
          
          {tabs.map((tab: any) => (
            <Select.Option key={ tab.key } value={ tab.key }>
              {tab.label}
            </Select.Option>
          ))}
        </Select>

        {tabs.find((tab: any) => tab.key === activeTabKey)?.children}
      </>
    );
  }

  return (
    <div className="portfolio-management-container" >
      <Tabs
        type="card"
        activeKey={ activeTabKey }
        onChange={ onTabChange }
        tabBarStyle={ { textTransform: "uppercase" } }
        items={ tabs }
      />
    </div>
  );
};

export default PortfolioManager;
