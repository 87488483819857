/* eslint-disable import/no-named-as-default-member */
import axios, { AxiosError, AxiosPromise, CancelTokenSource } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

import {
  getImpersonationHeaders,
  getNoCacheHeaders,
  getPrivateHeaders,
  getTenantHeader,
} from "common/shared/services/api.headers/api.headers";
import { isHandlerEnabled } from "common/shared/services/api.helpers/api.helpers";
import portalEnvConstants from "common/shared/services/portal/portal.env.constants";
import apiEnvs from "common/shared/services/api.env.constants/api.env.constants";
import { errorHandler, successHandler } from "common/shared/services/api.interceptors/api.interceptors";
import { decodeBase64String } from "common/shared/utils/base64.utils";
import { IErrorResponse, IRequestConfig } from "common/shared/types";

export class ApiService {
  static readonly TIMEOUT = 60000;

  constructor() {
    axios.interceptors.response.use(
      (response) => successHandler(response),
      (error) => errorHandler(error),
    );
    this.getEndpoints().then(
      (env: any) => {
        apiEnvs.setApiEnv(env);
      },
      () => {
        apiEnvs.setApiEnv(null);
      },
    );
  }

  async get<T = any>(path: string, params?: any): Promise<T> {
    if (isHandlerEnabled(params)) {
      const impersonationHeaders = getImpersonationHeaders();
      const privateHeaders = await getPrivateHeaders();
      params = {
        ...params,
        headers: { ...impersonationHeaders, ...privateHeaders },
      };
    }
    return axios.get(path, params);
  }

  async post<T = any>(path: string, data: any, config: any = {}): Promise<T> {
    const customHeaders = config?.headers || {};
    const commonHeaders = getTenantHeader();
    const privateHeaders = await getPrivateHeaders();
    config = {
      ...config,
      headers: {
        ...privateHeaders,
        ...commonHeaders,
        ...customHeaders,
      },
    };
    return axios.post(path, data, config);
  }

  async patch<T = any>(path: string, data: any, config: any = {}): Promise<T> {
    const customHeaders = config?.headers || {};
    const commonHeaders = getTenantHeader();
    const privateHeaders = await getPrivateHeaders();
    config = {
      ...config,
      headers: {
        ...privateHeaders,
        ...commonHeaders,
        ...customHeaders,
      },
    };
    return axios.patch(path, data, config);
  }

  async put<T = any>(path: string, data: any, config: any = {}): Promise<T> {
    const customHeaders = config?.headers || {};
    const commonHeaders = getTenantHeader();
    config = {
      ...config,
      headers: {
        ...commonHeaders,
        ...customHeaders,
      },
    };
    return axios.put(path, data, config);
  }

  async delete<T = any>(path: string, params?: any): Promise<T> {
    if (isHandlerEnabled(params)) {
      const impersonationHeaders = getImpersonationHeaders();
      const privateHeaders = await getPrivateHeaders();
      params = {
        ...params,
        headers: { ...impersonationHeaders, ...privateHeaders },
      };
    }
    return axios.delete(path, params);
  }

  public axiosBaseQuery =
    ({ baseUrl }: { baseUrl: string } = { baseUrl: "" }): BaseQueryFn<IRequestConfig, unknown, IErrorResponse> =>
    async (
      requestConfig,
      _baseQueryApi,
    ): Promise<
      | { data: unknown }
      | {
          error: IErrorResponse;
        }
    > => {
      try {
        const headers = await this.getRequestHeaders(requestConfig.headers);

        const result = await this.fetch({
          ...requestConfig,
          url: baseUrl + requestConfig.url,
          headers,
        });

        return { data: result };
      } catch (axiosError) {
        const error = axiosError as AxiosError;

        return {
          error: {
            status: error.response?.status,
            data: error.response?.data || error.message,
          },
        };
      }
    };

  public fetch = (requestConfig: IRequestConfig): AxiosPromise =>
    axios(requestConfig).catch((axiosError: AxiosError) => {
      throw axiosError;
    });

  public getRequestHeaders = async (headers: any): Promise<object> => {
    const commonHeaders = getTenantHeader();
    const impersonationHeaders = getImpersonationHeaders();
    const privateHeaders = await getPrivateHeaders();

    const requestHeaders = {
      ...commonHeaders,
      ...impersonationHeaders,
      ...privateHeaders,
      ...headers,
    };

    return requestHeaders;
  };

  formatResponseError = (error: AxiosError) => ({
    error: {
      status: error.response?.status,
      data: error.response?.data || error.message,
    } as IErrorResponse,
  });

  async getEndpoints() {
    // change to "" when running tests locally
    let host = window.location.hostname === "localhost" ? "https://baatest.bcpbenapp-dev.bankben.org" : "";

    const response: any = await axios.get(`${ host }/env.json`, {
      timeout: ApiService.TIMEOUT,
      headers: {
        ...getNoCacheHeaders(),
      },
    });
    const result = decodeBase64String(response);
    portalEnvConstants.setData(result);
    return result;
  }

  getCancelToken(): CancelTokenSource {
    const cancelToken = axios.CancelToken;
    return cancelToken.source();
  }

  cancel(cancelToken: any, message?: string) {
    cancelToken.cancel(message);
    return this.getCancelToken();
  }
}

const apiService = new ApiService();
export default apiService;
