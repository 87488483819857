import { TAccountType } from "common/lib/constants";
import { TWhiteLabel } from "common/shared/types";
import { ICustodyAccountAPI } from "domains/custodyAccounts/shared/types";

interface IAddress {
  city: string | null;
  country: string;
  is_default: boolean;
  label: string | null;
  postal_code: string | null;
  region: string | null;
  street1: string | null;
  street2: string | null;
}

interface IEmailAddress {
  email: string | null;
  is_default: boolean;
  label: string | null;
  validation: string | null;
}

interface IPhone {
  accepts_text_message: boolean;
  country_code: string;
  is_default: boolean;
  is_validated: boolean;
  label: string | null;
  phone_country: string | null;
  phone_number: string | null;
  phone_type: string | null;
}

export enum UserType {
  Principal = "principal",
  Employee = "employee",
  Advisor = "advisor",
  GeneralPartner = "gp",
  BenOriginations = "ben_originations",
}

export type TUserType = "principal" | "employee" | "advisor" | "gp" | "ben_originations";

export enum RelationshipTypes {
  AdvisoryFirm = "advisory_firm",
  Client = "client",
  Self = "self",
  Custodial = "custodial",
  CustodialAdvisor = "custodial_advisor",
}

export interface IUserDetails extends Pick<IAccountLevelDetails, "institution_data"> {
  account_display_id: string;
  account_id: string;
  account_name: string;
  account_users?: ICustodyAccountAPI[];
  addresses: IAddress[];
  created_date: string;
  custodial_agreement_date: string | null;
  custodial_agreement_expiry_date: string | null;
  customer_ip_address: string;
  date_of_birth: string | null;
  email_addresses: IEmailAddress[];
  first_name: string;
  is_anonymous: boolean;
  is_custodial_agreement_active: boolean;
  is_notification_allowed: boolean;
  is_test_data: string | null;
  last_name: string;
  locale: string;
  middle_name: string | null;
  nick_name: string | null;
  phones: IPhone[];
  salesforce_user_id: string | null;
  should_receive_email_notifications: boolean;
  ssn: string | null;
  suffix: string | null;
  tax_id_country: string;
  time_zone_abbreviation: string | null;
  updated_date: string;
  user_alt_quote_switch: boolean;
  user_display_id: string;
  user_id: string;
  user_performance_and_targeted_cookies: boolean | null;
  user_strictly_necessary_cookies: boolean | null;
  user_type: TUserType;
  white_label_instance: TWhiteLabel;
}

export interface IAccountLevelDetails {
  access_granted: boolean;
  account_id: string;
  account_name: string;
  account_type: TAccountType;
  account_users: ICustodyAccountAPI[];
  accreditation_approver: null;
  accreditation_status: string;
  created_date: string;
  display_id: string;
  id_country: null;
  id_expiration: null;
  id_number: null;
  id_type: null;
  institution_data?: IInstitutionData;
  is_anonymous: null;
  is_test_account: boolean;
  is_test_data: boolean;
  kyc_approver: null;
  kyc_status: string;
  principal_data?: IPrincipalData;
  relationship_type: `${ RelationshipTypes }`;
  salesforce_account_id: string;
  source: string;
  status: string;
  status_note: null;
  users: number;
}

interface IPageInfo {
  index: number;
  size: number;
}


export interface IUserClient {
  account_id: string;
  account_display_id: string;
  user_id: string;
  account_name: string;
  account_type: string;
  investor_type?: string;
  status: string;
  access_granted: boolean;
  created_date: string;
  users: number;
  advisor_id: string;
  advisor_display_id: string;
  advisor_first_name: string;
  advisor_last_name: string;
  advisor_account_id: string;
  advisor_account_name: string;
  last_viewed_date: string;
  is_custodial_agreement_active: boolean;
  custodial_agreement_date: string | null;
  is_created_as_anonymous: boolean;
  is_anonymous: boolean;
  custodial_agreement_expiry_date: string | null;
}

export interface IUserClientsDetails {
  total: number;
  page: IPageInfo;
  items: IUserClient[];
}

export interface IClientProfile {
  first_name: string;
  last_name: string;
  investor_type: string;
  company_name: string;
  email_address: string;
  phone_number: string;
  country_code: string;
  isAnonymous?: boolean;
}

interface IPrimaryContactUser {
  user_id: string;
  display_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  country_code: null;
  phone_number: null;
  email: string;
}

export interface IInstitutionData {
  tax_id: null;
  tax_id_country: string;
  institution_type: string;
  primary_contact_user: IPrimaryContactUser;
  addresses: IAddress;
  emails: IEmailAddress[];
  phones: IPhone[];
}

export interface IPrincipalData {
  display_id: string;
  email: string;
  first_name: string;
  last_name: string;
  user_id: string;
}

export type TAccountDetails = "user" | "custody" | "client";

export type TUserDetails = "user" | "custody";
