/* eslint-disable max-len */
import { AxiosError } from "axios";
import { UploadFile, message } from "antd";

import { apiService } from "common/shared";
import documentsService from "domains/documents/shared/document.api.service";
import { rootApiReducer } from "store/root.api.reducer";
import {
  DocumentGridStatus,
  IDocument,
  IDocumentGridData,
  IDocumentUrlData,
  IPDFDocument,
} from "domains/documents/shared/types";

export const documentsApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "lrDocumentGridData" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchDocumentsById: build.query<IDocument[], string>({
        queryFn: async (accountId) => {
          try {
            const response = await documentsService.fetchDocumentsById(accountId);

            return { data: response.items };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchLRDocuments: build.query<IDocument[], { LRId: string; lrAccountId: string }>({
        queryFn: async ({ LRId, lrAccountId }) => {
          try {
            const docs = await documentsService.fetchLRDocuments(lrAccountId, LRId);

            return { data: docs };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchLiquidityRequestDocumentGridData: build.query<IDocumentGridData[], string>({
        queryFn: async (LRId) => {
          try {
            const data = await documentsService.fetchLiquidityRequestDocumentGridData(LRId);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, LRId) => [ { type: "lrDocumentGridData", id: LRId } ],
      }),

      fetchAccountDocumentGridData: build.query<IDocumentGridData[], string>({
        queryFn: async (accountId) => {
          try {
            const data = await documentsService.fetchAccountDocumentGridData(accountId);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, LRId) => [ { type: "lrDocumentGridData", id: LRId } ],
      }),

      setLRDocumentGridData: build.mutation<IDocumentGridData[], { LRId: string; status: `${ DocumentGridStatus }` }>({
        queryFn: async ({ LRId, status }) => {
          try {
            const documentGridData: IDocumentGridData[] = await documentsService.fetchLiquidityRequestDocumentGridData(LRId);
            documentGridData.forEach((asset) => {
              asset.is_active = true;
              Object.entries(asset).forEach(([ key, value ]) => {
                if (value === DocumentGridStatus.NotReceived) {
                  // @ts-ignore
                  asset[ key as keyof IDocumentGridData ] = status;
                }
              });
            });

            await documentsService.setDocumentGridData(LRId, { is_append_row: false, fund_assets: documentGridData });

            return { data: documentGridData };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, { LRId }) => [ { type: "lrDocumentGridData", id: LRId } ],
      }),

      fetchDocumentUrlByDocID: build.query<IDocumentUrlData[], { accountId: string; documentIds: string }>({
        queryFn: async ({ accountId, documentIds }) => {
          try {
            const docIds = documentIds.split(",");
            let documentUrlDataResp: IDocumentUrlData[] = [];

            for (let indx in docIds) {
              await documentsService.fetchDocumentURLData(accountId, docIds[ indx ]).then((response) => {
                documentUrlDataResp.push(response);
              });
            }

            return { data: documentUrlDataResp };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchDocumentPassword: build.query<string, { documentId: string; accountId: string }>({
        queryFn: async ({ documentId, accountId }) => {
          try {
            const response = await documentsService.fetchDocumentPassword(accountId, documentId);

            return { data: response ? response[ 0 ].value : "" };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      setDocumentPassword: build.mutation<string, { documentId: string; accountId: string; password: string }>({
        queryFn: async ({ documentId, accountId, password }, { dispatch }) => {
          try {
            await documentsService.setDocumentPassword(accountId, documentId, password);

            dispatch(
              documentsApiReducer.util.updateQueryData(
                "fetchDocumentPassword",
                { documentId, accountId },
                () => password,
              ),
            );

            return { data: documentId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      uploadDocument: build.mutation<
        IDocument,
        {
          accountId: string;
          document: IDocument | IPDFDocument;
          file: (File & UploadFile) | (Blob & UploadFile) | Blob;
          LRId?: string;
        }
      >({
        queryFn: async ({ accountId, document, file }, { dispatch }) => {
          try {
            
            const documentId = await documentsService.uploadDocument(accountId, document, file);

            const updatedDocument = { ...document, id: documentId };
            "percent" in updatedDocument && delete updatedDocument.percent;
            "cancelToken" in updatedDocument && delete updatedDocument.cancelToken;

            return { data: updatedDocument as IDocument };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        onQueryStarted: async ({ LRId, accountId }, { queryFulfilled, dispatch }) => {
          try {
            const { data: document } = await queryFulfilled;

            if (LRId) {
              await dispatch(
                documentsApiReducer.util.upsertQueryData(
                  "fetchDocumentPassword",
                  { accountId, documentId: document.id },
                  "",
                ),
              );
              await dispatch(
                documentsApiReducer.util.updateQueryData(
                  "fetchLRDocuments",
                  { lrAccountId: accountId, LRId },
                  (draft) => {
                    if (!draft) return;
                    draft.push(document);
                  },
                ),
              );
            } else {
              dispatch(
                documentsApiReducer.util.updateQueryData("fetchDocumentsById", accountId, (draft) => {
                  if (!draft) return;
                  draft.push(document);
                }),
              );
            }
          } catch (error) {
            /* empty */
          }
        },
      }),

      deleteDocument: build.mutation<
        string,
        {
          accountId: string;
          documentId: string;
          LRId?: string;
        }
      >({
        queryFn: async ({ accountId, documentId }) => {
          try {
            await documentsService.deleteDocument(accountId, documentId);

            return { data: documentId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        onQueryStarted: async ({ LRId, accountId, documentId }, { queryFulfilled, dispatch }) => {
          try {
            await queryFulfilled;

            LRId
              ? dispatch(
                  documentsApiReducer.util.updateQueryData(
                    "fetchLRDocuments",
                    { lrAccountId: accountId, LRId },
                    (draft) => {
                      if (!draft) return;
                      return draft.filter((document) => document.id !== documentId);
                    },
                  ),
                )
              : dispatch(
                  documentsApiReducer.util.updateQueryData("fetchDocumentsById", accountId, (draft) => {
                    if (!draft) return;
                    return draft.filter((document) => document.id !== documentId);
                  }),
                );
          } catch (error) {
            message.error("Failed deleting document");
          }
        },
      }),

      downloadDocument: build.mutation<string, { documentId: string; accountId: string }>({
        queryFn: async ({ documentId, accountId }) => {
          try {
            const link = document.createElement("a");
            const response = await documentsService.getDownloadUrl(accountId, documentId);

            link.href = response.url;
            link.click();

            return { data: documentId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
