import { realEstatePropertyTypeList } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/forms/DirectRealEstateInvestmentForm/constants";
import { assetTypes } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/constants";
import { TNonFundAsset } from "domains/liquidity/shared/types";
import { AssetType } from "domains/liquidity/shared/constants";

export const getAssetName = (asset: TNonFundAsset) => {
  switch (asset.asset_type) {
    case AssetType.Fund:
      return asset.asset_name;

    case AssetType.PreqinFund:
      return asset.asset_name;

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return asset.asset_name;

    case AssetType.CoInvestment:
      return asset.sponsor_description;

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return asset.account_name || asset.asset_name;

    case AssetType.DirectRealEstateInvestment:
      return realEstatePropertyTypeList[ asset.real_estate_property_type ]?.label;

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return asset.asset_description;
  }
};

export const getAssetType = (asset: TNonFundAsset) => {
  return assetTypes.find((type) => type.value === asset.asset_type)?.title;
};
