import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import {
  IChangedAssetValue,
  IFundSearchItem,
  ILRAccount,
  ILRSharing,
  ILiquidityRequest,
  TAssetType,
} from "domains/liquidity/shared/types";
import liquidityService from "domains/liquidity/shared/liquidity.api.service";
import {
  formatAssetBodyForRequest,
  formatAssetToUpdate,
  formatAssetsForReducer,
  formatClientBodyForRequest,
  formatDocuemtGridDataForRequest,
  formatLiquidityRequestBodyForRequest,
} from "domains/liquidity/shared/utils/apiFormatter";
import { apiService, router } from "common/shared";
import { TAppState } from "store/root.store";
import clientsService from "domains/clients/shared/clients.api.service";
import documentsService from "domains/documents/shared/document.api.service";
import { DocumentGridStatus, IDocumentGridData } from "domains/documents/shared/types";
import { AssetType, LAPages } from "domains/liquidity/shared/constants";
import { IUserClient, RelationshipTypes, UserType } from "domains/clients/shared/types";
import { ITask } from "domains/myApplications/shared/types";
import myApplicationsApiService from "domains/myApplications/shared/myApplications.api.service";
import { URLs } from "common/lib/constants";
import { trustbenSessionService } from "domains/trustben";
import { myApplicationsApiReducer } from "domains/myApplications/store/myApplications.api.reducer";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { setCurrentLiquidityRequestData, setSelectedAssetsForLiquidity } from "./liquidity.reducer";

export const liquidityApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "lr-accounts", "lr-sharings", "liquidityRequest" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchLiquidityRequests: build.query<ILiquidityRequest[], void>({
        queryFn: async (_, { getState, dispatch }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const response = await liquidityService.fetchLiquidityRequests(isAdvisor, user);
            const liquidityRequests = response.items
              .filter((lr: ILiquidityRequest) => lr.is_active)
              .map(
                (lr: ILiquidityRequest) =>
                  ({
                    ...lr,
                    client: lr.accounts?.find((account) => account.relationship_type === RelationshipTypes.Client),
                  }) as ILiquidityRequest,
              );

            liquidityRequests.map((lr) => {
              dispatch(liquidityApiReducer.endpoints.fetchLRCurrentTask.initiate(lr.id, { forceRefetch: true }));
            });

            return { data: liquidityRequests };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchLRCurrentTask: build.query<ITask, string>({
        queryFn: async (LRId, { dispatch }) => {
          try {
            const tasks: ITask[] = await myApplicationsApiService.fetchCurrentTask(LRId);
            const currentTask = tasks[ 0 ];

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLiquidityRequests", undefined, (draft) => {
                return draft.map((lr) =>
                  lr.id === LRId
                    ? {
                        ...lr,
                        currentTask,
                      }
                    : lr,
                );
              }),
            );

            return { data: currentTask };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateLRCurrentTask: build.mutation<ITask, string>({
        queryFn: async (LRId, { dispatch }) => {
          try {
            const tasks = await myApplicationsApiService.fetchCurrentTask(LRId);
            const currentTask = tasks[ 0 ];

            dispatch(myApplicationsApiReducer.util.invalidateTags([ { type: "allWorkflowTasks", id: "LIST" } ]));
            dispatch(liquidityApiReducer.util.upsertQueryData("fetchLRCurrentTask", LRId, currentTask));
            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLiquidityRequests", undefined, (draft) => {
                return draft.map((lr) =>
                  lr.id === LRId
                    ? {
                        ...lr,
                        currentTask,
                      }
                    : lr,
                );
              }),
            );

            return { data: currentTask };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      createLiquidityRequest: build.mutation<string, { isLA: boolean; client?: IUserClient, overrideData?:any }>({
        queryFn: async ({ isLA, client, overrideData }, { getState, dispatch }) => {
          try {
            const liquidityRequestType = isLA ? "LiquidityRequest" : "AltQuote";
            const requestBody = formatLiquidityRequestBodyForRequest(
              liquidityRequestType,
              (getState() as TAppState).clientsReducer.user!,
              client,
              overrideData,
            );

            const response = await liquidityService.createLiquidityRequest(requestBody);
            
            const LRId = response.id;
            window.location.pathname.includes(LAPages.SelectAssets) &&
              router.navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.SelectAssets, { replace: true });
            dispatch(setCurrentLiquidityRequestData(response))
            dispatch(liquidityApiReducer.endpoints.updateLRListAfterCreation.initiate(LRId));
            return { data: LRId };
          } catch (error) {
            router.navigate(-1);
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateLRListAfterCreation: build.mutation<null, string>({
        queryFn: async (LRId, { getState, dispatch }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;

            const response = await liquidityService.fetchLiquidityRequests(isAdvisor, user);
            const newlyCreatedLR = response.items.find((lr) => lr.id === LRId)!;
            const newLR = {
              ...newlyCreatedLR,
              client: newlyCreatedLR.accounts?.find(
                (account) => account.relationship_type === RelationshipTypes.Client,
              ),
            } as ILiquidityRequest;

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLiquidityRequests", undefined, (draft) => {
                draft.unshift(newLR);
              }),
            );
            dispatch(liquidityApiReducer.endpoints.fetchLRCurrentTask.initiate(LRId));

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateLiquidityRequest: build.mutation<string, { LRId: string; changes: Partial<ILiquidityRequest> }>({
        queryFn: async ({ LRId, changes }, { dispatch }) => {
          try {
            const isTrustbenAltQuote = trustbenSessionService.getIsTrustbenAltQuote();

            await liquidityService.updateLiquidityRequest(LRId, changes);

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLiquidityRequests", undefined, (draft) => {
                const updatedLR = {
                  ...draft?.find((lr) => lr.id === LRId),
                  ...changes,
                };
                const otherLRs = draft?.filter((lr) => lr.id !== LRId) || [];

                return [ updatedLR, ...otherLRs ] as ILiquidityRequest[];
              }),
            );

            if (!isTrustbenAltQuote) {
              dispatch(liquidityApiReducer.endpoints.updateLRCurrentTask.initiate(LRId));
              dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId));
            }

            return { data: LRId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, { LRId }) => [ { type: "liquidityRequest", id: LRId } ],
      }),

      fetchLRAssets: build.query<TAssetType[], string>({
        queryFn: async (LRId) => {
          try {
            const response = await liquidityService.fetchLRAssets(LRId);
            const assets: TAssetType[] = formatAssetsForReducer(response) as TAssetType[];

            return { data: assets };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchAllAssets: build.query<TAssetType[], void>({
        queryFn: async (_arg, { getState }) => {
          try {
            const liquidityRequests = liquidityApiReducer.endpoints.fetchLiquidityRequests
              // @ts-ignore
              .select()(getState() as TAppState).data as ILiquidityRequest[];
            const liquidityRequestsIds = liquidityRequests
              .filter((lr: ILiquidityRequest) => lr.is_active)
              .map((lR: ILiquidityRequest) => lR.id);

            const assets = await Promise.all(
              liquidityRequestsIds.map((id: string) => liquidityService.fetchLRAssets(id)),
            ).then((values) => values.reduce((arr, item) => [ ...arr, ...item ]));

            return { data: assets };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      fetchLiquidityRequest: build.query<ILiquidityRequest, string>({
        queryFn: async (LRId) => {
          try {
            const data = await liquidityService.fetchLiquidityRequest(LRId);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, LRId) => [ { type: "liquidityRequest", id: LRId } ],
      }),

      fetchLRAccounts: build.query<ILRAccount[], string>({
        queryFn: async (LRId) => {
          try {
            const data = await liquidityService.fetchLRAccounts(LRId);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, LRId) => [ { type: "lr-accounts", id: LRId } ],
      }),

      fetchLRSharings: build.query<ILRSharing[], string>({
        queryFn: async (LRId) => {
          try {
            const response = await liquidityService.fetchLRSharings(LRId);

            return { data: response.items };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, LRId) => [ { type: "lr-sharings", id: LRId } ],
      }),

      shareLRAccess: build.mutation<{ id: string }, { LRId: string; clientId: string }>({
        queryFn: async ({ LRId, clientId }) => {
          try {
            const response = await clientsService.fetchAccountLevelDetailsById(clientId);
            const clientData = formatClientBodyForRequest(response);

            const data = await liquidityService.shareLRAccess(LRId, clientData);

            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, { LRId }) => [ { type: "lr-sharings", id: LRId } ],
      }),

      createLRAsset: build.mutation<any, string>({
        queryFn: async (LRId, { getState, dispatch }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
            const accountId = isAdvisor ? currentClient?.account_id : user?.account_id;
            const currentAsset = (getState() as TAppState).liquidityReducer.currentAsset as TAssetType;
            const body = formatAssetBodyForRequest(currentAsset, user, LRId.slice(-6));
            const response = await liquidityService.createAsset(body);
            
            // if (currentAsset.asset_type === AssetType.PreqinFund || currentAsset.asset_type === AssetType.Fund) {
            //   const assetWithId = { ...currentAsset, asset_id: response.id };
            //   const bodyForRequest = formatDocuemtGridDataForRequest(
            //     assetWithId,
            //     user!,
            //     LRId,
            //     DocumentGridStatus.NotReceived,
            //   );
            //   await documentsService.setDocumentGridData(accountId, bodyForRequest);
            // }

            const assetWithId = { ...currentAsset, asset_id: response.id };

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLRAssets", LRId, (draft) => {
                draft.push(assetWithId);
              }),
            );
            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchAllAssets", undefined, (draft) => {
                draft.unshift({ asset: assetWithId } as unknown as TAssetType);
              }),
            );
            dispatch(altCustodyApiReducer.util.invalidateTags([ { type: "investments", id: accountId } ]));

            return { data: assetWithId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateAsset: build.mutation<any, any>({
        queryFn: async ({ assetId, changes }, { getState, dispatch }) => {
          try {
            const data = await liquidityService.updateAsset(assetId, changes);
            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      updateLRAsset: build.mutation<TAssetType, string>({
        queryFn: async (LRId, { getState, dispatch }) => {
          try {
            const currentAsset = (getState() as TAppState).liquidityReducer.currentAsset as TAssetType;
            const stateAsset = liquidityApiReducer.endpoints.fetchLRAssets
              // @ts-ignore
              .select(LRId)(getState() as TAppState)
              .data?.find((assetFromState: Partial<TAssetType>) => assetFromState.asset_id === currentAsset.asset_id);
            const updatedValues: IChangedAssetValue[] = formatAssetToUpdate(currentAsset, stateAsset);

            await liquidityService.updateLRAsset(currentAsset.asset_id, updatedValues);

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLRAssets", LRId, (draft) =>
                draft.map((asset) =>
                  currentAsset.asset_id === asset.asset_id
                    ? {
                        ...currentAsset,
                      }
                    : asset,
                ),
              ),
            );
            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchAllAssets", undefined, (draft) =>
                draft.map((asset) =>
                  currentAsset.asset_id === asset.asset_id
                    ? {
                        ...currentAsset,
                      }
                    : asset,
                ),
              ),
            );

            return { data: currentAsset };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      deleteLRAsset: build.mutation<string, { assetId: string; LRId: string }>({
        queryFn: async ({ assetId, LRId }) => {
          try {
            const documetsGridData = await documentsService.fetchLiquidityRequestDocumentGridData(LRId);
            const fundAssets = documetsGridData.filter((asset: IDocumentGridData) => asset.asset_id !== assetId);
            fundAssets.forEach((asset: IDocumentGridData) => (asset.is_active = true));

            await liquidityService.deleteLRAsset(assetId, LRId);
            await documentsService.setDocumentGridData(LRId, { is_append_row: false, fund_assets: fundAssets });

            return { data: assetId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        onQueryStarted: async ({ assetId, LRId }, { dispatch, queryFulfilled }) => {
          try {
            await queryFulfilled;

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLRAssets", LRId, (draft) =>
                draft.filter((asset) => !assetId.includes(asset.asset_id)),
              ),
            );
            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchAllAssets", undefined, (draft) =>
                draft.filter((asset) => !assetId.includes(asset.asset_id)),
              ),
            );
          } catch {
            /* empty */
          }
        },
      }),

      searchByFundName: build.mutation<IFundSearchItem[], string>({
        queryFn: async (fundName) => {
          try {
            let foundFunds = [] as IFundSearchItem[];

            if (fundName.trim()) {
              const response = await liquidityService.getFunds(fundName);
              foundFunds = response;
            }

            return { data: foundFunds };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      createAltQuoteTrustben: build.mutation<string, void>({
        queryFn: async (_, { getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const requestBody = formatLiquidityRequestBodyForRequest("AltQuote", user);

            const response = await liquidityService.createLiquidityRequest(requestBody);

            return { data: response.id };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),

      createAssetTrustben: build.mutation<
        { assetId: string; dummyAssetId: string },
        { LRId: string; asset: TAssetType }
      >({
        queryFn: async ({ LRId, asset }, { getState, dispatch }) => {
          try {
            const dummyAssetId = asset.asset_id;
            const user = (getState() as TAppState).clientsReducer.user!;
            const body = formatAssetBodyForRequest(asset, user, LRId.slice(-6));

            const response = await liquidityService.createAsset(body);

            if (asset.asset_type === AssetType.PreqinFund || asset.asset_type === AssetType.Fund) {
              const assetWithId = { ...asset, asset_id: response.id };
              const bodyForRequest = formatDocuemtGridDataForRequest(
                assetWithId,
                user,
                LRId,
                DocumentGridStatus.NotReceived,
              );
              await documentsService.setDocumentGridData(LRId, bodyForRequest);
            }

            dispatch(
              liquidityApiReducer.util.updateQueryData("fetchLRAssets", LRId, (draft) => {
                draft.push({ ...asset, asset_id: response.id });
              }),
            );

            return { data: { assetId: response.id, dummyAssetId } };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
