import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { AssetActions } from "v2/features/assets";
import { Spinner } from "common/lib/components";
import { AssetList } from "v2/features/assets/assetList";

import './myAssetsPage.scss'

const MyAssetsPage = () => {
    const { investments, loading } = useAssetsHoldingsData();

    const calculateTotalInUSD = () => {
        const total = investments?.reduce((sum, asset) => {
          // Sumar solo los valores no nulos
          if (asset.current_net_asset_value !== null) {
            return sum + asset.current_net_asset_value;
          }
          return sum;
        }, 0);
      
        // Formatear el total como USD
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(total || 0);
    };

    return (
        <div className="my-assets-page ben-main-form-wrapper">
            {/* My Assets ({ investments?.length }: { calculateTotalInUSD() }) */}
            <AssetActions />
            <Spinner spinning={ loading }>
              <AssetList assetList={ investments }/>
            </Spinner>
        </div>
    )
}

export default MyAssetsPage;
