import { useEffect, useState } from "react";

import { Input } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  searchInputValue: string;
  handleSearch: (value: string) => void;
}

const SearchBar = ({ searchInputValue, handleSearch }: IProps) => {
  const { t } = useTranslation("");

  const [ searchValue, setSearchValue ] = useState(searchInputValue);

  useEffect(() => {
    setSearchValue(searchInputValue);
  }, [ searchInputValue ]);

  return (
    <Input.Search
      placeholder="Search data across funds"
      className="search-input"
      onChange={ (e) => setSearchValue(e.target.value) }
      value={ searchValue }
      enterButton={ t("Search") }
      onSearch={ handleSearch }
    />
  );
};

export default SearchBar;
