// @ts-nocheck
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";

import { List, Modal, Input, InputRef, Space } from "antd";
import { useTranslation } from "react-i18next";
import { flushSync } from "react-dom";
import { CheckOutlined } from "@ant-design/icons";

import { Button, DeleteOutlined, EditOutlined, Spinner } from "common/lib/components";
import { useSnapshots } from "domains/altCustody/shared-hooks/useSnapshots";
import { ISnapshot } from "domains/altCustody/shared/types";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";

import "./SnapshotModal.scss";

interface IProps {
  currentSnapshotData: ISnapshot["data"];
  isOpen: boolean;
  handleCancel: () => void;
}

const SnapshotModal: FC<IProps> = ({ currentSnapshotData, isOpen, handleCancel }) => {
  const { t } = useTranslation("");

  const [ snapshotId, setSnapshotId ] = useState<"new" | string>("");
  const [ isFetching, setIsFetching ] = useState(false);
  const [ snapshotName, setSnapshotName ] = useState("");
  const inputRef = useRef<InputRef>(null);
  const { snapshots = [], isFetchingSnapshots } = useAltCustodySelector()
  const { createSnapshot, updateSnapshot, deleteSnapshot } = useSnapshots();

  const handleChangeSnapshotName = (e: ChangeEvent<HTMLInputElement>) => {
    setSnapshotName(e.target.value);
  };

  const handleNewSnapshotClick = () => {
    setSnapshotName("");
    flushSync(() => {
      setSnapshotId("new");
    });
    inputRef.current?.focus();
  };

  const handleEditSnapshot = (snapshot: ISnapshot) => {
    setSnapshotName(snapshot.name);
    flushSync(() => {
      setSnapshotId(snapshot.id);
    });
    inputRef.current?.focus();
  };

  const handleUpdateSnapshot = async (snapshotId: string) => {
    setIsFetching(true)
    await updateSnapshot(snapshotId, snapshotName);
    setSnapshotId("");
  };

  const handleSaveSnapshot = async (snapshotName: string) => {
    setIsFetching(true)
    setSnapshotId("");
    await createSnapshot(snapshotName, currentSnapshotData);
  };

  const handleDeleteSnapshot = async (snapshotId: string) => {
    setIsFetching(true)
    await deleteSnapshot(snapshotId);
    setSnapshotId("");
  };

  useEffect(() => {
    setIsFetching( isFetchingSnapshots )
  }, [ isFetchingSnapshots ])

  return (
    
    <Modal
      destroyOnClose
      title={ t("Filter and Graph Snapshots") }
      open={ isOpen }
      centered={ true }
      footer={ null }
      onCancel={ handleCancel }
      className="snapshots-modal"
    >
      <Spinner spinning={ isFetching }>
        <List
          header={
            <div className="list-header-container">
              <span className="list-title">{t("Snapshot Name")}</span>{" "}
              <Button type="primary" onClick={ handleNewSnapshotClick }>
                {t("New Snapshot")}
              </Button>
            </div>
          }
          dataSource={ snapshots.length ? snapshots.filter(({ is_active }) => !!is_active) : [] }
          renderItem={ (item) => (
            <List.Item
              actions={ [
                //  @ts-ignore
                snapshotId === item.id ? (
                  <Button
                    key="update"
                    htmlType="button"
                    type="text"
                    onClick={ () => handleUpdateSnapshot(item.id) }
                    icon={ <CheckOutlined /> }
                  />
                ) : (
                  <Button
                    key="edit"
                    htmlType="button"
                    type="text"
                    onClick={ () => handleEditSnapshot(item) }
                    icon={ <EditOutlined onClick={ () => {} } /> }
                  />
                ),
                <Button
                  key="delete"
                  htmlType="button"
                  type="text"
                  onClick={ () => handleDeleteSnapshot(item.id) }
                  icon={ <DeleteOutlined onClick={ () => {} } /> }
                />,
              ] }
            >
              {snapshotId === item.id ? (
                <Input size="small" ref={ inputRef } value={ snapshotName } onChange={ handleChangeSnapshotName } />
              ) : (
                <span className="list-item-value">{item.name}</span>
              )}
            </List.Item>
          ) }
        />

        {snapshotId === "new" && (
          <Space.Compact block>
            <Input size="small" ref={ inputRef } value={ snapshotName } onChange={ handleChangeSnapshotName } />
            <Button type="primary" onClick={ () => handleSaveSnapshot(snapshotName) }>
              {t("Save Snapshot")}
            </Button>
          </Space.Compact>
        )}
      </Spinner>
    </Modal>
  );
};

export default SnapshotModal;
