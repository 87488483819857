import { FC } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";

import "./AddChartButton.scss";

interface IProps {
  onClick: () => void;
}

const AddChartButton: FC<IProps> = ({ onClick }) => {
  const { t } = useTranslation("");
  return (
    <ConfigProvider wave={ { disabled: true } }>
      <div className="add-chart-container">
        <p className="add-chart-title">{t("Add New Custom Chart")}</p>
        <Button onClick={ onClick } className="add-chart-button" type="primary" shape="circle" icon={ <PlusOutlined /> } />
      </div>
    </ConfigProvider>
  );
};

export default AddChartButton;
