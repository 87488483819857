import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ICustodyAccount } from "domains/custodyAccounts/shared/types";

interface IInitialState {
  currentCustodyAccount: ICustodyAccount | null;
  currentFooterCustodyAccount: ICustodyAccount | null;
  custodyAccounts: ICustodyAccount[] | null;
}

const initialState: IInitialState = {
  currentCustodyAccount: null,
  currentFooterCustodyAccount: null,
  custodyAccounts: null,
};

const custodyAccountsReducer = createSlice({
  name: "[CustodyAccounts]",
  initialState,
  reducers: {
    setCustodyAccounts: (state, action: PayloadAction<ICustodyAccount[] | null>) => ({
      ...state,
      custodyAccounts: action.payload,
    }),
    setCurrentCustodyAccount: (state, action: PayloadAction<ICustodyAccount | null>) => ({
      ...state,
      currentCustodyAccount: action.payload,
    }),

    setCurrentFooterCustodyAccount: (state, action: PayloadAction<ICustodyAccount | null>) => ({
      ...state,
      currentFooterCustodyAccount: action.payload,
    }),

    resetCustodyAccountsReducer: () => initialState,
  },
});

export const {
  setCurrentCustodyAccount,
  setCurrentFooterCustodyAccount,
  setCustodyAccounts,
  resetCustodyAccountsReducer,
} = custodyAccountsReducer.actions;

export default custodyAccountsReducer.reducer;
