/* eslint-disable max-len */
import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import { apiService, router } from "common/shared";
import { altCustodyApiService } from "domains/altCustody/shared/altCustody.api.service";
import { TAppState } from "store/root.store";
import {
  formatInvestmentForRequest,
  formatInvestmentsForReducer,
  formatSnapshotsRequest,
  prepareTransactionBodyForRequest,
} from "domains/altCustody/shared/utils/apiFormatter";
import { LiquidityRequestStatus, TAssetType } from "domains/liquidity/shared/types";
import { IUserClient, IUserDetails, UserType } from "domains/clients/shared/types";
import {
  formatAssetBodyForRequest,
  formatDocuemtGridDataForRequest,
  formatLiquidityRequestBodyForRequest,
} from "domains/liquidity/shared/utils/apiFormatter";
import liquidityApiService from "domains/liquidity/shared/liquidity.api.service";
import { DocumentGridStatus } from "domains/documents/shared/types";
import documentsApiService from "domains/documents/shared/document.api.service";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity";
import { AssetType } from "domains/liquidity/shared/constants";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { IDeleteInvestmentParams, IUpdateInvestmentParams } from "domains/altCustody/shared/types";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { resetCurrentAssetData, setAssetsPendingCustodyAccount, setCurrentAssetData, setSelectedAssetsForLiquidity } from "domains/liquidity/store/liquidity.reducer";
import { setChartData, setSnapshots, setIsFetchingSnapshots, setIsChartDataFetching } from "./altCustody.reducer";

export const altCustodyApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "investments", "snapshots" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchSnapshots: build.query<any[], string>({
        queryFn: async (accountId, { dispatch, getState }) => {
          dispatch(setIsFetchingSnapshots(true));

          try {
            const snapshots = await altCustodyApiService.fetchSnapshots(accountId);
            dispatch(setSnapshots(snapshots));
            dispatch(setIsFetchingSnapshots(false));
            return { data: snapshots };
          } catch (error) {
            dispatch(setIsFetchingSnapshots(false));
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, accountId) => [ { type: "snapshots", id: accountId } ],
      }),
      createSnapshot: build.mutation<any, any>({
        queryFn: async (data, { dispatch, getState }) => {
          const user = (getState() as TAppState).clientsReducer.user!;
            const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const accountId = isAdvisor ? currentClient?.account_id : user?.account_id;
            const accountDisplayId = isAdvisor ? currentClient?.account_display_id : user?.account_display_id;
          try {
            const requestBody = formatSnapshotsRequest( { data } )
            requestBody.account_id = accountId
            requestBody.account_display_id = accountDisplayId
            const response:any = await altCustodyApiService.createSnapshot(requestBody);
            dispatch(altCustodyApiReducer.util.invalidateTags( [ { type: "snapshots", id: accountId } ]));
            return { data: response };
          } catch (error) {
            console.log({ error })
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      updateSnapshot: build.mutation<any, any>({
        queryFn: async (data, { dispatch, getState }) => {
          const user = (getState() as TAppState).clientsReducer.user!;
          const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
          const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
          const accountId = isAdvisor ? currentClient?.account_id : user?.account_id;

          const { snapshotId, changes: { name } } = data
          const requestBody = [
            {
                "op": "replace",
                "path": "/name",
                "value": name,
            },
          ]
          try {
            const response:any = await altCustodyApiService.updateSnapshot(requestBody, snapshotId);
            dispatch(altCustodyApiReducer.util.invalidateTags( [ { type: "snapshots", id: accountId } ]));
            return { data: { } };
          } catch (error) {
            console.log({ error })
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      removeSnapshot: build.mutation<any, any>({
        queryFn: async (data, { dispatch, getState }) => {
          const user = (getState() as TAppState).clientsReducer.user!;
          const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
          const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
          const accountId = isAdvisor ? currentClient?.account_id : user?.account_id;

          const { snapshotId } = data
          const requestBody = [
            {
                "op": "replace",
                "path": "/is_active",
                "value": false,
            },
          ]

          try {
            const response:any = await altCustodyApiService.updateSnapshot(requestBody, snapshotId);
            dispatch(altCustodyApiReducer.util.invalidateTags( [ { type: "snapshots", id: accountId } ]));
            return { data: response }
          } catch (error) {
            console.log({ error })
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      fetchInvestments: build.query<TAssetType[], string>({
        queryFn: async (accountId) => {
          try {
            const response = await altCustodyApiService.fetchInvestments(accountId);
            const investments = formatInvestmentsForReducer(response);
            return { data: investments };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, accountId) => [ { type: "investments", id: accountId } ],
      }),
      fetchChartData: build.query<TAssetType[], string>({
        queryFn: async (accountId, { dispatch }) => {
          dispatch(setIsChartDataFetching(true));
          try {
            
            const response = await altCustodyApiService.fetchChartData(accountId);
            const formattedResponse = response.graph_data
          
            
            dispatch(setChartData(formattedResponse || 'nodata'));
            dispatch(setIsChartDataFetching(false));
            return { data: formattedResponse };
          } catch (error) {
            dispatch(setIsChartDataFetching(false));
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, accountId) => [ { type: "investments", id: accountId } ],
      }),
      fetchChartDataSnapshots: build.query<TAssetType[], string>({
        queryFn: async (accountId, { dispatch }) => {
          try {
            
            const response = await altCustodyApiService.fetchChartData(accountId);
            
            const formattedResponse = JSON.parse(JSON.parse(response.payload))
          
            
        //    dispatch(setChartData(formattedResponse));
            return { data: formattedResponse };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: (_result, _error, accountId) => [ { type: "investments", id: accountId } ],
      }),
      fetchInvestment: build.query<TAssetType, string>({
        queryFn: async (investmentId: string) => {
          try {
            const investment = await altCustodyApiService.fetchInvestment(investmentId);

            return { data: investment };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      createInvestment: build.mutation<TAssetType, void>({
        queryFn: async (_, { dispatch, getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const currentClient = (getState() as TAppState).clientsReducer.currentClient as IUserClient;
            const accountId = isAdvisor ? currentClient?.account_id : user?.account_id;
            const isLiqudityRequestFlow = location.pathname.includes('my-applications');
            const selectedAssetsForLiquidity = (getState() as TAppState).liquidityReducer.selectedAssetsForLiquidity;
            const currentAsset = (getState() as TAppState).liquidityReducer.currentAsset as TAssetType;
            const custodyAccounts = (getState() as TAppState).custodyAccountsReducer.custodyAccounts;
            const hasCustodyAccounts = custodyAccounts && !!custodyAccounts?.length 
            const currentUserData = isAdvisor
              ? ({ ...currentClient, user_type: currentClient?.account_type } as unknown as IUserDetails)
              : ({
                  ...user,
                  user_type: user.user_type === UserType.Principal ? "individual" : "institution",
                } as unknown as IUserDetails);

            const requestBody = formatInvestmentForRequest(
              currentAsset,
              currentUserData,
              isAdvisor,
              hasCustodyAccounts,
              currentClient,
              custodyAccounts,
            );

            const response:any = await altCustodyApiService.createInvestment(requestBody);
            const investmentWithId = {
              ...currentAsset,
              id: response.id,
              asset_id: response.asset_id,
            } as unknown as TAssetType;

            dispatch(
              altCustodyApiReducer.util.updateQueryData("fetchInvestments", accountId, (draft) => {
                draft.push(investmentWithId);
              }),
            );

            dispatch(altCustodyApiReducer.util.invalidateTags([ { type: "investments", id: accountId } ]));
            dispatch(setCurrentAssetData(investmentWithId));
            const assetWithId = { ...currentAsset, asset_id: response.asset_id, original_asset_id: response.id };
            
            if (isLiqudityRequestFlow) {
              dispatch(setSelectedAssetsForLiquidity([ ...selectedAssetsForLiquidity, response.id ]))
            }
            const isFundAsset = assetWithId.asset_type === AssetType.Fund ||  assetWithId.asset_type === AssetType.PreqinFund
            if (isFundAsset) {
              const bodyForRequest = formatDocuemtGridDataForRequest(
                assetWithId,
                user!,
                '',
                DocumentGridStatus.NotReceived,
              );
              await documentsApiService.setDocumentGridDataA(accountId, bodyForRequest);
            }
            dispatch(resetCurrentAssetData())
            return { data: investmentWithId };
          } catch (error) {
            console.log({ error })
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      updateInvestment: build.mutation<TAssetType, IUpdateInvestmentParams>({
        queryFn: async ({ investmentId, changes }) => {
          try {
            const response = await altCustodyApiService.updateInvestment(investmentId, changes);
            return { data: response };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        invalidatesTags: (_result, _error, { accountId }) => [ { type: "investments", id: accountId } ],
      }),
      deleteInvestment: build.mutation<string, IDeleteInvestmentParams>({
        queryFn: async ({ investmentId, accountId }, { dispatch }) => {
          try {
            await altCustodyApiService.deleteInvestment(investmentId);

            dispatch(
              altCustodyApiReducer.util.updateQueryData("fetchInvestments", accountId, (draft) =>
                draft.filter((investment) => !investmentId.includes(investment.id!)),
              ),
            );

            return { data: investmentId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      createProductWithAssets: build.mutation<any, { assets: TAssetType[]; client?: IUserClient }>({
        queryFn: async ({ assets, client }, { dispatch, getState }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
            const body = formatLiquidityRequestBodyForRequest("LiquidityRequest", user, client);
            const data = {
              ...body,
              status: LiquidityRequestStatus.PendingSubmission,
            };

            const { id: LRId } = await liquidityApiService.createLiquidityRequest(data);

            const formattedAssetsToCreate = assets.map((asset: TAssetType) =>
              formatAssetBodyForRequest(asset, user, LRId.slice(-6)),
            );

            const response = await Promise.all(
              formattedAssetsToCreate.map((body: any) => liquidityApiService.createAsset(body)),
            );

            const assetsToSetDocGrid = assets.filter(
              (asset) => asset.asset_type === AssetType.PreqinFund || asset.asset_type === AssetType.Fund,
            );

            if (assetsToSetDocGrid.length) {
              const formattedAssetsDocGrid = assetsToSetDocGrid.map((asset: TAssetType, index: number) => {
                const assetWithId = { ...asset, asset_id: response[ index ].id };
                return formatDocuemtGridDataForRequest(assetWithId, user, LRId, DocumentGridStatus.NotReceived);
              });
              await Promise.all(
                formattedAssetsDocGrid.map((body: any) => documentsApiService.setDocumentGridData(LRId, body)),
              );
            }

            dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.fetchLRAccounts.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.updateLRListAfterCreation.initiate(LRId));
            isAdvisor &&
              client &&
              dispatch(clientsApiReducer.util.invalidateTags([ { type: "clientAssets", id: client.account_id } ]));

            router.navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ReviewAssets);

            return { data: response };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
      createTransactionWithAssets: build.mutation<string, TAssetType[]>({
        queryFn: async (assets, { getState, dispatch }) => {
          try {
            const user = (getState() as TAppState).clientsReducer.user!;
            const requestBody = prepareTransactionBodyForRequest(user);

            const { id: LRId } = await altCustodyApiService.createTransaction(requestBody);

            // Add assets to the created transaction
            const formattedAssetsToCreate = assets.map((asset: TAssetType) =>
              formatAssetBodyForRequest(asset, user, LRId.slice(-6)),
            );
            const response = await Promise.all(
              formattedAssetsToCreate.map((body: any) => liquidityApiService.createAsset(body)),
            );

            // Set initial document grid status for assets
            const assetsToSetDocGrid = assets.filter(
              (asset) => asset.asset_type === AssetType.PreqinFund || asset.asset_type === AssetType.Fund,
            );

            if (assetsToSetDocGrid.length) {
              const formattedAssetsDocGrid = assetsToSetDocGrid.map((asset: TAssetType, index: number) => {
                const assetWithId = { ...asset, asset_id: response[ index ].id };
                return formatDocuemtGridDataForRequest(assetWithId, user, LRId, DocumentGridStatus.NotReceived);
              });
              await Promise.all(
                formattedAssetsDocGrid.map((body: any) => documentsApiService.setDocumentGridData(LRId, body)),
              );
            }

            // Update cached or redux storage
            dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.fetchLRAccounts.initiate(LRId));
            dispatch(liquidityApiReducer.endpoints.updateLRListAfterCreation.initiate(LRId));

            router.navigate(
              URLs.PROTECTED.DASHBOARD + URLs.PROTECTED.ALT_CUSTODY + "/" + LRId + AltCustodyAssetPages.ReviewAssets,
            );

            return { data: LRId };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
