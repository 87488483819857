import { useEffect, useState } from "react";

import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";

import { useQueryParams } from "common/shared-hooks";
import { SelectFund } from "./SelectFund";
import SelectOtherAssetForm from "./SelectOtherAsset";
import { FormHeader, Tooltip } from "common/lib/components";
import { TypesAssetsConsidered } from "domains/dashboard/components";
import { useAppDispatch } from "store/store.hooks";
import { resetCustodyAccountsReducer, setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";

const AddAsset = () => {
    const navigate = useNavigate();
    const [ isFundAssetsTab, setIsFundAssetsTab ] = useState(true);
    const params = useQueryParams();
    const tab = params.get("tab");
    const dispatch = useAppDispatch()
    
    const sections = [
      {
        key: "0",
        label: "Select a Fund",
        children: <SelectFund />,
      },
      {
        key: "1",
        label: "Select Other Assets",
        children: <SelectOtherAssetForm />,
      },
    ];

    useEffect(() => {
      dispatch(setCurrentCustodyAccount(null))
    }, [ dispatch, setCurrentCustodyAccount ])
    
    return (
      <div className="ben-main-form-wrapper">
        <FormHeader subtitle={ isFundAssetsTab ? "Select Funds" : "Select Non Fund Title" } />
  
        <div className="ben-form-container">
          <Tabs
            defaultActiveKey={ tab ?? "0" }
            type="card"
            onChange={ (activeKey) => {
              setIsFundAssetsTab(activeKey === "0");
              navigate(`${ location.pathname }?tab=${ activeKey }`, { replace: true });
            } }
            destroyInactiveTabPane
            tabBarExtraContent={ <Tooltip content={ <TypesAssetsConsidered /> } /> }
            items={ sections }
          />
        </div>
      </div>
    );
}

export default AddAsset;
