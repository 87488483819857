import { ChangeEvent, memo, useState } from "react";

import { Modal, Table } from "antd";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Form, Input } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { MyClientsPages } from "domains/clients/shared/constants";
import { LAPages } from "domains/liquidity";
import { CircleX } from "common/lib/components/Icons";
import { IUserClient } from "domains/clients/shared/types";
import { setCurrentClient } from "domains/clients/store/clients.reducer";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { useAltQuotesSelector } from "domains/altQuotes";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";

import "./ChooseClientModal.scss";

interface IProps {
  isVisible: boolean;
  handleCancel: () => void;
  isLA?: boolean;
  isLRSharing?: boolean;
  isStandaloneAssetCreation?:boolean;
  isConvertingAdvisorAltQuoteWithoutClient?: boolean;
  openRequestFormalProposalModal?: (altQuote: ILiquidityRequest) => void;
}

const ChooseClientModal = memo(function ChooseClientModal({
  isVisible,
  handleCancel,
  isLA,
  isStandaloneAssetCreation,
  isLRSharing,
  isConvertingAdvisorAltQuoteWithoutClient = false,
  openRequestFormalProposalModal,
}: IProps) {
  const { t } = useTranslation([ "dashboard", "common" ]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { LRId } = useParams();

  const [ createLiquidityRequest ] = liquidityApiReducer.useCreateLiquidityRequestMutation({
    fixedCacheKey: "create-liquidity-request",
  });
  const [ shareLRAccess ] = liquidityApiReducer.useShareLRAccessMutation({
    fixedCacheKey: "share-lr-access",
  });
  const { user, isAdvisor } = useClientsSelector();
  const { data: clients } = clientsApiReducer.useFetchUserClientsQuery(
    user && isAdvisor ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { currentAltQuote } = useAltQuotesSelector();

  const [ inputValue, setInputValue ] = useState("");
  const [ chosenClientId, setChosenClientId ] = useState<string | null>(null);

  const chosenClient = clients?.items?.find((client) => client.account_id === chosenClientId);
  const clientsList = inputValue
    ? clients?.items.filter((client) => {
        return client.account_name.toLowerCase().includes(inputValue.toLowerCase());
      })
    : clients?.items;

  const clientsColumns = [
    {
      title: t("Client Names"),
      dataIndex: "clientName",
      key: "clientName",
      align: "left",
      className: "ben-pl-1 ben-border-bottom",
    } as const,
  ];

  const sharingLRAccessWithClientColumns = [
    {
      title: t("Client Names"),
      dataIndex: "clientName",
      key: "clientName",
      align: "left",
      className: "ben-pl-1 ben-border-bottom",
    } as const,
    {
      dataIndex: "update_client",
      key: "update_client",
      align: "right",
      className: "ben-pl-1 ben-border-bottom",
    } as const,
  ];

  const editClient = (client: IUserClient) => {
    navigate(URLs.PROTECTED.MY_CLIENTS + `${ MyClientsPages.EDIT_CLIENT }/${ client.account_id }`, {
      state: { isEditClient: true },
    });
  };

  const clientsDataSource = clientsList?.map((client) => ({
    key: client.account_id,
    clientName: client.account_name,
    update_client:
      Number.parseInt(client.user_id.slice(4)) === 0 ? (
        <Button onClick={ () => editClient(client) } className="ben-update-client-profile-button" type="text">
          {t("Update to share access")}
        </Button>
      ) : null,
  }));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCleanInput = () => {
    setInputValue("");
  };

  const onContinueClick = () => {
    const client = clients?.items?.find((client) => client.account_id === chosenClientId);

    if (chosenClientId && isLRSharing && LRId) {
      shareLRAccess({ LRId, clientId: chosenClientId });
      handleCancel();
      return;
    }

    if (
      chosenClientId &&
      isConvertingAdvisorAltQuoteWithoutClient &&
      openRequestFormalProposalModal &&
      currentAltQuote
    ) {
      client && dispatch(setCurrentClient(client));
      openRequestFormalProposalModal(currentAltQuote);
      handleCancel();
      return;
    }

    if (chosenClientId && isLA !== undefined) {
      client && dispatch(setCurrentClient(client));
      if (isLA) {
        navigate(URLs.PROTECTED.MY_APPLICATIONS + 'new-liquidity-request');
      } else {
        navigate(URLs.PROTECTED.MY_APPLICATIONS + 'new-altquote');
      }
    }

    if (chosenClientId && isStandaloneAssetCreation) {
      client && dispatch(setCurrentClient(client));
      navigate(`${ URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS }${ AltCustodyAssetPages.SelectAssets }`)
    }
  };

  const handleAddNewClient = () => {
    navigate(URLs.PROTECTED.MY_CLIENTS + MyClientsPages.ADD_CLIENT, {
      state: {
        isCompleteFullClientCheckboxChecked: true,
        isStandaloneAssetCreation,
        isLRCreation: !isStandaloneAssetCreation,
        isLA,
      },
    });
  };

  return (
    <Modal
      destroyOnClose
      className="ben-choose-client-modal"
      width={ 650 }
      onCancel={ handleCancel }
      open={ isVisible }
      footer={ [
        <Button onClick={ handleCancel } key="back">
          {t("Cancel", { ns: "common" })}
        </Button>,
        <Button disabled={ !chosenClientId } onClick={ onContinueClick } type="primary" key="continue">
          {t("Continue", { ns: "common" })}
        </Button>,
      ] }
    >
      <div className="ben-choose-client-modal-container">
        <div className="ben-choose-client-modal-title">
          <h1>{t("CHOOSE A CLIENT")}</h1>
        </div>
        <div className="ben-choose-client-modal-content">
          <Formik initialValues={ {} } onSubmit={ console.log }>
            <Form className="ben-choose-client-modal-form">
              <Form.Item className="ben-modal-form-item" name="client_name" label={ t("Search") }>
                <Input
                  maxLength={ maxLengthText }
                  value={ inputValue }
                  name="client_name"
                  className="ben-small ben-search"
                  onChange={ handleChange }
                  suffix={ inputValue ? <CircleX onClick={ handleCleanInput } /> : <span /> }
                />
              </Form.Item>
            </Form>
          </Formik>
          {clientsDataSource && clientsDataSource.length ? (
            <Table
              sticky
              rowClassName={ (record) => (record.key === chosenClientId ? "ben-table-row-active" : "") }
              onRow={ (record) => ({
                onClick: () =>
                  record.update_client !== null && isLRSharing
                    ? setChosenClientId(null)
                    : setChosenClientId(record.key),
              }) }
              className="ben-table"
              columns={ isLRSharing ? sharingLRAccessWithClientColumns : clientsColumns }
              dataSource={ clientsDataSource }
              pagination={ false }
            >
            </Table>
          ) : (
            <div className="ben-ml-5">{"You don't have clients yet"}</div>
          )}
        </div>
        <div className="ben-choose-client-modal-button-container ben-border-top ben-border-bottom">
          <Button onClick={ handleAddNewClient } type="primary">
            {t("Add a New Client")}
          </Button>
        </div>
      </div>
    </Modal>
  );
});

export default ChooseClientModal;
