import { SelectProps } from "antd";

import { ChartDataKey } from "domains/altCustody/shared/constants";

export const chartDataOptions: SelectProps["options"] = [
  {
    label: "Instrument Type",
    value: ChartDataKey.INSTRUMENT_TYPE,
  },
  {
    label: "Investor NAV",
    value: ChartDataKey.FUND_INVESTMENT_NAV,
  },
  {
    label: "Public or Private",
    value: ChartDataKey.PUBLIC_OR_PRIVATE,
  },
  {
    label: "Country",
    value: ChartDataKey.COUNTRY,
  },
  {
    label: "Ben Investment Asset Class",
    value: ChartDataKey.ASSET_CLASS,
  },
  {
    label: "Ben Investment Sub Asset Class",
    value: ChartDataKey.SUB_ASSET_CLASS,
  },
  {
    label: "GICS Level 1 (Sector)",
    value: ChartDataKey.SECTOR,
  },
  {
    label: "GICS Level 2 (Industry Group)",
    value: ChartDataKey.INDUSTRY_GROUP,
  },
  {
    label: "GICS Level 3 (Industry)",
    value: ChartDataKey.INDUSTRY,
  },
  {
    label: "GICS Level 4 (Sub Industry)",
    value: ChartDataKey.SUB_INDUSTRY,
  },
  {
    label: "NAV As Of Date",
    value: ChartDataKey.NAV_AS_OF_DATE,
  },
];
