import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { URLs } from "common/lib/constants";
import { CardErrorSection } from "common/lib/components";
import image from "common/assets/images/404-error.svg";

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const handleRedirectTo = () => {
    navigate(URLs.AUTH.SIGN_IN, { replace: true });
  };

  return (
    <CardErrorSection
      title={ "Page not found." }
      message={ "If you came upon this page by mistake, try going back to the previous page or check the URL. You may also want to return to your dashboard. If you are unable to access the information, please contact your Originations Representative. You can also email us at InvestOps@beneficient.com or call 214-445-4700." }
      loading={ false }
      handleOnClick={ handleRedirectTo }
      isBtnDisabled={ false }
      btnText={ t("Not Found Page.Button Text") }
      image={ image }
      noPanel={ true }
    />
  );
};

export default NotFoundPage;
