/* eslint-disable max-len */
import { AxiosError } from "axios";
import { skipToken } from "@reduxjs/toolkit/query";

import { rootApiReducer } from "store/root.api.reducer";
import { ICustodyAccount, ICustodyAccountAPI } from "domains/custodyAccounts/shared/types";
import clientsService from "domains/clients/shared/clients.api.service";
import { apiService } from "common/shared";
import { TAppState } from "store/root.store";
import custodyAccountsApiService from "domains/custodyAccounts/shared/custodyAccounts.api.service";
import { setCurrentCustodyAccount, setCustodyAccounts } from "./custodyAccounts.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { resetAssetsPendingCustodyAccount } from "domains/liquidity/store/liquidity.reducer";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { UserType } from "domains/clients/shared/types";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";

export const custodyAccountsApiReducer = rootApiReducer
  .enhanceEndpoints({
    addTagTypes: [ "custodyAccounts" ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      fetchCustodyAccounts: build.query<ICustodyAccountAPI[], { accountId: string; userId: string }>({
        queryFn: async ({ accountId, userId }, { getState, dispatch }) => {
          try {
            const response = await clientsService.fetchUserLevelDetails(accountId, userId);
            dispatch(
              setCustodyAccounts(response.account_users as any),
            );
            return { data: response.account_users ?? [] };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        providesTags: [ { type: "custodyAccounts", id: "LIST" } ],
      }),
      createCustodyAccount: build.mutation<
        ICustodyAccount,
        { accountDetails: ICustodyAccount; }
      >({
        queryFn: async ({ accountDetails }, { getState, dispatch }) => {
          try {
            const user:any = (getState() as TAppState).clientsReducer.user;
            const userType = (getState() as TAppState).clientsReducer.user?.user_type!;
            const custodyAccounts = (getState() as TAppState).custodyAccountsReducer.custodyAccounts;
            const isAdvisor =
              user?.user_type === UserType.Advisor || user?.user_type === UserType.BenOriginations;
            const investmentsResponse = await dispatch(
              altCustodyApiReducer.endpoints.fetchInvestments.initiate(
                (isAdvisor ? user.currentClient?.account_id : user?.account_id) || skipToken,
              ),
            ).unwrap(); 
            const assetsToUpdate = [ ...investmentsResponse ].filter(({ custody_account_id }: any) => custody_account_id === accountDetails.account_id)
            // const response = {}
            const response = await custodyAccountsApiService.createCustodyAccount(
              accountDetails,
              userType,
              accountDetails.owner_type,
            );

            const data = { ...accountDetails, ...response } as ICustodyAccount & ICustodyAccountAPI;

            if (assetsToUpdate.length) {
              await dispatch(
                custodyAccountsApiReducer.endpoints.connectCustodyAccountWithAsset.initiate({
                  custodyAccountId: `${ data.account_id }`,
                  isDefault: !custodyAccounts?.length,
                  assetsToUpdate: assetsToUpdate.map(({ asset_id }: any) => asset_id),
                }),
              );

            } else {
              await dispatch(
                custodyAccountsApiReducer.endpoints.connectCustodyAccountWithAsset.initiate({
                  custodyAccountId: `${ data.account_id }`,
                  isDefault: !custodyAccounts?.length,
                }),
              );
            }
            await dispatch(altCustodyApiReducer.endpoints.fetchInvestments.initiate(user.account_id, { forceRefetch: true }))
            return { data };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
        onQueryStarted: async ({ accountDetails }, { dispatch, queryFulfilled }) => {
          try {
            dispatch(setCurrentCustodyAccount(accountDetails));

            const { data } = await queryFulfilled;

            dispatch(setCurrentCustodyAccount(data));
          } catch {
            dispatch(
              setCurrentCustodyAccount({
                ...accountDetails,
                custody_agreement: false,
              }),
            );
          }
        },
        invalidatesTags: [ { type: "custodyAccounts", id: "LIST" } ],
      }),
      connectCustodyAccountWithAsset: build.mutation<
        string,
        { custodyAccountId: string; isDefault: boolean, assetsToUpdate?:any }
      >({
        queryFn: async ({ custodyAccountId, isDefault, assetsToUpdate }, { getState, dispatch }) => {
          const userAccountId = (getState() as TAppState).clientsReducer.user?.account_id!;
          const currentAssets = (getState() as TAppState).liquidityReducer.assetsPendingCustodyAccount;
          const custodyAccounts = (getState() as TAppState).custodyAccountsReducer.custodyAccounts;
          const AssetIds = assetsToUpdate || currentAssets;

          if (AssetIds && AssetIds.length > 0 && custodyAccounts) {
            try {
              // Realiza la conexión para cada asset_id
              for (const AssetId of AssetIds) {

                await custodyAccountsApiService.connectCustodyAccountWithAsset(
                  custodyAccountId,
                  AssetId,
                  userAccountId,
                  isDefault,
                );
                // dispatch(
                //   altCustodyApiReducer.endpoints.fetchInvestments.initiate(AssetId),
                // );
              }

              dispatch(
                resetAssetsPendingCustodyAccount(),
              );

              return { data: 'ok' };
            } catch (error) {
              resetAssetsPendingCustodyAccount()
              return apiService.formatResponseError(error as AxiosError);
            }
          }

          return { data: '' };
        },
      }),
      connectCustodyAccountWithLR: build.mutation<
        null,
        { custodyAccount: ICustodyAccount | ICustodyAccountAPI; LRId: string }
      >({
        queryFn: async ({ custodyAccount, LRId }, { getState, dispatch }) => {
          try {
            const userType = (getState() as TAppState).clientsReducer.user?.user_type!;

            await custodyAccountsApiService.connectCustodyAccountWithLR(
              userType,
              custodyAccount as ICustodyAccount & ICustodyAccountAPI,
              LRId,
            );

            dispatch(liquidityApiReducer.util.invalidateTags([ { type: "lr-accounts", id: LRId } ]));

            return { data: null };
          } catch (error) {
            return apiService.formatResponseError(error as AxiosError);
          }
        },
      }),
    }),
  });
