import { FC, ReactNode } from "react";

import { Spinner } from "common/lib/components";

import "./CollapseTitle.scss";

interface IProps {
  title: string;
  count?: number | string;
  price?: string;
  button?: ReactNode;
}

const CollapseTitle: FC<IProps> = ({ title, count, price, button }) => {
  return (
    <div className="ben-collapse-title-container">
      <div>
        <span>{ title }</span>
        <span className="ben-collapse-title-count"> ({ count ?? <Spinner size="small" /> }) </span>
        <span className="ben-collapse-title-price ben-pl-3 info">{ price }</span>
      </div>
      { button }
    </div>
  );
};

export default CollapseTitle;
