/* eslint-disable no-restricted-imports */
import { useCallback, useEffect, useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/query";

import { useClientsSelector } from "domains/clients";
import { altCustodyApiReducer } from "../store/altCustody.api.reducer";
import idUtils from "common/shared/utils/id/id.utils";
import { useIdentitySelector } from "domains/identity";
import { ISnapshot } from "domains/altCustody/shared/types";
import { useAppDispatch } from "store/store.hooks";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { AltCustodyMutationCacheKey } from "../shared/constants";

const SNAPSHOTS_STORAGE_KEY = "ALTACCESS_ALTCUSTODY_SNAPSHOTS";

type TSnapshotsStore = Record<string, ISnapshot[]>;

export const getInitialSnapshots = (): TSnapshotsStore | undefined => {
  const snapshotsFromStore = localStorage.getItem(SNAPSHOTS_STORAGE_KEY);

  return snapshotsFromStore ? JSON.parse(snapshotsFromStore) : undefined;
};

export const useSnapshots = () => {
  const [ create ] = altCustodyApiReducer.useCreateSnapshotMutation();
  const [ update ] = altCustodyApiReducer.useUpdateSnapshotMutation();
  const [ remove ] = altCustodyApiReducer.useRemoveSnapshotMutation();
  
  const createSnapshot = async (name: string, data: ISnapshot["data"] = {} as ISnapshot["data"]) => {
    await create({ data, name })
  }

  const updateSnapshot = async (id: string, name: string) => {
    await update({ snapshotId: id, changes: { name } })
  }

  const deleteSnapshot = async (id: string) => {
    await remove({ snapshotId: id })
  }

  return {
    createSnapshot,
    updateSnapshot,
    deleteSnapshot,
  };
};

