import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useParams } from "react-router-dom";

import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { FormFooter, FormHeader, Spinner, Button } from "common/lib/components";
import FundAssetsTable from "domains/liquidity/components/FundAssetsTable";
import NonFundAssetsTable from "domains/liquidity/components/NonFundAssetsTable";
import { useHandlers } from "./useHandlers";
import ReasonForSeekingLiquidityForm from "./ReasonForSeekingLiquidityForm";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useFilteredAssets } from "common/shared-hooks";

import "./ReviewAssetsPage.scss";

const ReviewAssetsPage = () => {
  const { LRId } = useParams();

  const [ _, { isLoading: isUpdatingLR } ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });
  const { isLA } = useLiquiditySelector();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const { t } = useTranslation([ "liquidity", "common" ]);

  const { addFund, onDelete } = useHandlers();
  const { fundAssets, nonFundAssets } = useFilteredAssets(assets);

  return (
    <Spinner spinning={ isUpdatingLR } wrapperClassName="ben-review-assets-page-spinner">
      <FormHeader
        subtitle={ t("Confirm Assets for Liquidity") }
        title={ t("Assets to Exchange") }
        hint={ t("Review Assets Info") }
      />

      <div className="ben-form-container">
        {!assets.length && (
          <div className="ben-no-assets">
            <span>
              {t("Please, add assets to your")} {isLA ? t("Liquidity Request") : t("AltQuote")}
            </span>

            <Button onClick={ addFund }>+ {t("Add Asset")}</Button>
          </div>
        )}

        <FundAssetsTable
          title={ `${ t("Funds In This Application") }: ${ fundAssets.length }` }
          fundAssets={ fundAssets }
          onDelete={ onDelete }
        />

        <NonFundAssetsTable
          title={ `${ t("Other Non-Fund Assets In This Application") }: ${ nonFundAssets.length }` }
          nonFundAssets={ nonFundAssets }
          onDelete={ onDelete }
        />

        <br />
        <p>If you have one fund owned by multiple entities, please list the total value.</p>

        <ReasonForSeekingLiquidityForm isLA={ isLA } addFund={ addFund } />
      </div>

      {isLA && <FormFooter>{t("Review Assets Footer")}</FormFooter>}
    </Spinner>
  );
};

export default ReviewAssetsPage;
