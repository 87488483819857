import { Tabs, TabsProps } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Tooltip } from "common/lib/components";
import SelectFund from "./SelectFund";
import SelectOtherAssetForm from "./SelectOtherAsset";
import { TypesAssetsConsidered } from "domains/dashboard/components";
import { useQueryParams } from "common/shared-hooks";

import "./SelectAssetsTabs.scss";

interface IProps extends TabsProps {
  onTabChange?: (activeKey: string) => void;
}

const SelectAssetsTabs = ({ onTabChange, ...tabsProps }: IProps) => {
  const { t } = useTranslation("liquidity");
  const navigate = useNavigate();
  const params = useQueryParams();

  const tab = params.get("tab");
  const sections = [
    {
      key: "0",
      label: t("Select a Fund"),
      children: <SelectFund />,
    },
    {
      key: "1",
      label: t("Select Other Assets"),
      children: <SelectOtherAssetForm />,
    },
  ];

  return (
    <div className="ben-form-container">
      <Tabs
        { ...tabsProps }
        defaultActiveKey={ tab ?? "0" }
        type="card"
        onChange={ (activeKey) => {
          navigate(`${ location.pathname }?tab=${ activeKey }`, { replace: true });
          onTabChange?.(activeKey);
        } }
        destroyInactiveTabPane
        tabBarExtraContent={ <Tooltip content={ <TypesAssetsConsidered /> } /> }
        items={ sections }
      />
    </div>
  );
};

export default SelectAssetsTabs;
