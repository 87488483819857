/* eslint-disable max-len */
import { FC, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { Table } from "antd";

import { useScreen } from "common/shared-hooks";
import { useAltQuotesSelector } from "domains/altQuotes";
import { currencyFormatter } from "domains/liquidity/shared/utils/inputValueFormatter";
import { assetTypes } from "v2/features/assets/addAsset/SelectOtherAsset/constants";

export const mobileFundColumns = [
    {
      title: "Fund Manager & Fund Name",
      dataIndex: "fund",
      key: "fund",
      className: "ben-w-70",
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      align: "right",
      className: "ben-w-30",
    } as const,
];
  
export const desktopFundColumns = [
  {
    title: "Fund Manager",
    dataIndex: "fundManager",
    key: "fundManager",
    className: "ben-w-30",
  },
  {
    title: "Fund Name",
    dataIndex: "fundName",
    key: "fundName",
    className: "ben-w-30",
  },
  {
    title: "NAV",
    dataIndex: "nav",
    key: "nav",
    className: "ben-w-20",
  },
  {
    title: "",
    key: "action",
    dataIndex: "action",
    align: "right",
    className: "ben-w-20",
  } as const,
];

export const altQuoteDesktopFundColumns = [
  {
    title: "Fund Manager",
    dataIndex: "fundManager",
    key: "fundManager",
    className: "ben-w-30",
  },
  {
    title: "Fund Name",
    dataIndex: "fundName",
    key: "fundName",
    className: "ben-w-35",
  },
  {
    title: "NAV",
    dataIndex: "nav",
    key: "nav",
    className: "ben-w-25",
  },
  {
    title: "Unfunded Commitments",
    dataIndex: "unfundedCommitments",
    key: "unfundedCommitments",
    className: "ben-w-25 ben-white-space-break-spaces",
  },
];

const SelectAssetsForLiquidityRequest: FC<any> = ({ selectedAssets, setSelectedAssets, investments }) => {
    const { mobile } = useScreen();
    const { isAltQuoteTab } = useAltQuotesSelector();
    const { search } = useLocation()  
    
    const queryParams = new URLSearchParams(search);
    const confirmAssets = queryParams.get('confirmAssets') === "true"

    const desktopColumns = isAltQuoteTab ? altQuoteDesktopFundColumns : desktopFundColumns;
    const mobileColumns = isAltQuoteTab
      ? mobileFundColumns.filter((column) => column.key !== "action")
      : mobileFundColumns;
    const columns = mobile ? mobileColumns : desktopColumns;

    const rowSelection:any  = {
        selectedRowKeys: selectedAssets,
        onChange:( a:any ) => setSelectedAssets(a),
    };

     const getAssetTypeName = (asset:any) => {
        if (asset.asset_type === 5) {
          const description = asset.asset_description.length > 80 ? asset.asset_description.substring(0, 80) : asset.asset_description;
          return description
        }
        
        if (asset.asset_type === 2) {
          const description = asset.sponsor_description.length > 80 ? asset.sponsor_description.substring(0, 80) : asset.sponsor_description;
          return description
        }
        
        return assetTypes[ asset.asset_type ].title
      }
    
      const getSponsorName = (asset:any) => {
        if (asset.asset_type === 2) {
          const description = asset.sponsor && asset.sponsor.length > 80 ? asset.sponsor.substring(0, 80) : asset.sponsor;
          return description
        }
      }
  
    const fundDataSource = [ ...investments ]?.reverse().map((asset: any) => ({
        key: asset.id,
        fund: (
            <div>
                <b>
                    <span>{asset.asset_name || asset.fund_name || getAssetTypeName(asset)}</span>
                </b>
                <br />
                <span>{asset.fund_manager || asset.sponsor || getSponsorName(asset)}</span>
                <br />
                <span className="ben-fund-assets-text">NAV: </span>
                {currencyFormatter(asset.current_net_asset_value)}
                {isAltQuoteTab && (
                    <>
                        <br />
                        <span className="ben-fund-assets-text">Unfunded Commitments: </span>
                        {currencyFormatter(asset.unfunded_commitment_currency)}
                    </>
                )}
            </div>
        ),
        fundManager: asset.fund_manager || asset.sponsor ,
        fundName: asset.asset_name || asset.fund_name,
        nav: currencyFormatter(asset.current_net_asset_value),
        unfundedCommitments: currencyFormatter(asset.unfunded_commitment_currency),
    }));

    useEffect(() => {
      if(!confirmAssets) {
        setSelectedAssets(investments.map(({ id }: any) => id))
      }
    }, [])
  
    if (!investments?.length) return null;       
  
    return (
      <Table
        style={ {
          maxHeight: mobile ? "calc(100vh - 400px)" : `calc(100vh - 520px)`,
          minHeight: "170px",
          overflow: "hidden",
          overflowY: "auto",
        } }
        pagination={ false }
        columns={ columns }
        dataSource={ fundDataSource }
        rowSelection={ rowSelection }
        className="ben-fund-assets-table"
      />
    );
}

export default SelectAssetsForLiquidityRequest;
