export const pick = (obj: any, ...props: string[]) => {
  if (!obj || typeof obj !== "object") return;

  return props.reduce(
    function (result, prop) {
      if (Object.hasOwn(obj, prop)) {
        result[ prop ] = obj[ prop ];
      }

      return result;
    },
    {} as { [key: string]: string },
  );
};

export const omit = (obj: any, ...props: string[]) => {
  const result = { ...obj };

  props.forEach(function (prop) {
    delete result[ prop ];
  });

  return result;
};

export const flattenObj = (obj: Record<string, any>) => {
  if (Object.keys(obj).length === 0) {
    return obj;
  }

  return Object.keys(obj).reduce(
    (acc, cur) => {
      const isArray = Array.isArray(obj[ cur ]);
      const isObject = typeof obj[ cur ] === "object";
      const isNull = obj[ cur ] === null;

      if (isArray) {
        acc = { ...acc, ...flattenObj(obj[ cur ]) };
      }

      if (isObject && !isNull && !isArray) {
        acc = { ...acc, ...flattenObj(obj[ cur ]) };
      }

      if (isNull) {
        acc[ cur ] = obj[ cur ];
      }

      if (!isArray && !isObject) {
        acc[ cur ] = obj[ cur ];
      }

      return acc;
    },
    {} as Record<string, any>,
  );
};

export const shallowCompare = (obj1: Record<string, unknown>, obj2: Record<string, unknown>) => {
  return Object.entries(obj1).sort().toString() === Object.entries(obj2).sort().toString();
};

export const isEmptyObject = (object: Record<string, unknown>) => {
  return Object.keys(object).length === 0;
};

export const removeUndefinedValuesFromObject = <T extends {}>(obj: T): T => {
  const newObject = structuredClone(obj);

  Object.keys(newObject).forEach(
    (key) =>
      newObject[ key as keyof typeof newObject ] === undefined &&
      delete newObject[ key as keyof typeof newObject ],
  );

  return newObject;
};
