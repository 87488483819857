import { useState, useCallback } from "react";
export const useModalProps = () => {
  const [ isVisible, setIsVisible ] = useState(false);
  const handleShow = useCallback(() => {
    setIsVisible(true)
  }, []);
  const handleHide = useCallback(() => setIsVisible(false), []);

  return {
    isVisible,
    handleShow,
    handleHide,
  };
};
