/* eslint-disable */
import { UploadFile } from "antd/lib/upload/interface";

import { apiEnvs, apiService } from "common/shared";
import {
  IDocument,
  IDocumentGridData,
  IDocumentPassword,
  IDocumentUrlData,
  IGetDocumentsByAccountIdResponse,
  IPDFDocument,
} from "domains/documents/shared/types";
import { DocumentType } from "./constants";

export class DocumentsApiService {
  async fetchLRDocuments(accountId: string, LRId: string) {
    // TODO: add paging
    const res = await apiService.get(
      `${
        apiEnvs.documentsAPI
      }/v1/accounts/${accountId}/documents?filter[0][field]=liquidity_request_id&filter[0][operator]=equal&filter[0][value]=${LRId.slice(
        4
      )}&page.size=1000`
    );
    const documents: IDocument[] = res.items;
    const filteredDocuments = documents.filter((doc) => doc.document_type === DocumentType.Other);

    return filteredDocuments;
  }

  async fetchDocumentsById(accountId: string): Promise<IGetDocumentsByAccountIdResponse> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents?page.size=1000`);
  }

  async fetchLiquidityRequestDocumentGridData(LRId: string) {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/document_grid/liquidity_requests/${LRId}`);
 
  }

  async fetchAccountDocumentGridData(account_id:string) {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/document_grid/accounts/${account_id}`);
  }

  async fetchDocumentURLData(accountId: string, documentId: string): Promise<IDocumentUrlData> {
    return apiService.get(
      `${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}/download_url/latest`
    );
  }

  updateAssetNames(obj: { is_append_row: boolean; fund_assets: IDocumentGridData[] }) {
    if (obj && Array.isArray(obj.fund_assets)) {
      obj.fund_assets.forEach(asset => {
        if (asset.asset_name === null) {
          asset.asset_name = '';
        }
      });
    }
    return obj;
  }

  async setDocumentGridData(LRId: string, data: { is_append_row: boolean; fund_assets: IDocumentGridData[] }) {
    const formattedDate = { ...data }
    this.updateAssetNames(formattedDate)
    return apiService.post(`${apiEnvs.documentsAPI}/v1/document_grid/${LRId}`, formattedDate);
  }

  async setDocumentGridDataA(AccountId: string, data: { liquidity_request_id:string, is_append_row: boolean; fund_assets: IDocumentGridData[] }) {
    const formattedDate = { ...data }
    return apiService.post(`${apiEnvs.documentsAPI}/v1/document_grid/${AccountId}`, formattedDate);
  }

  async uploadDocument(
    account_id: string,
    document: IDocument | IPDFDocument,
    file: (File & UploadFile) | (Blob & UploadFile) | Blob,
  ) {
    const binary = new Blob([file], { type: document.mimeType });
    const { document_id, version_id, upload_url } = await apiService.post(
      `${apiEnvs.documentsAPI}/v1/accounts/${account_id}/documents`,
      {
        ...document,
        ...("document_name" in document
          ? {
              document_name: document.document_name
                ? document.document_name + Math.random().toString(36).substring(0, 5)
                : null,
            }
          : {}),
      }
    );

    const cancelSource = apiService.getCancelToken()
    await apiService.put(upload_url, binary, {
      headers: {
        "Content-Type": document.mimeType,
      },
      cancelToken: cancelSource.token,
    });

    try {
      await apiService.post(`${apiEnvs.documentsAPI}/v1/accounts/${account_id}/upload_document_confirmation`, {
        document_id,
        version_id,
        ...("uid" in file ? { file_id: file.uid } : {}),
      });
      
    } catch (error) {
       cancelSource.cancel("Cancelling PUT due to POST failure.")
    }
    

    return document_id;
  }

  async deleteDocument(accountId: string, documentId: string) {
    return apiService.patch(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}`, [
      {
        op: "replace",
        path: "/is_deleted",
        value: true,
      },
    ]);
  }

  async fetchDocumentPassword(account_id: string, document_id: string): Promise<void | IDocumentPassword[]> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${account_id}/documents/${document_id}/form_data`);
  }

  async setDocumentPassword(accountId: string, documentId: string, password: string) {
    return apiService.patch(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}`, [
      {
        op: "replace",
        path: "/document_password",
        value: password,
      },
    ]);
  }

  async getDownloadUrl(accountId: string, documentId: string): Promise<IDocumentUrlData> {
    return apiService.get(`${apiEnvs.documentsAPI}/v1/accounts/${accountId}/documents/${documentId}/download_url/v1`);
  }
}

const documentsApiService = new DocumentsApiService();
export default documentsApiService;
