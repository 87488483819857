import { useTranslation } from "react-i18next";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse, CollapseProps } from "antd";

interface IProps {
  isCollapseActiveByDefault: boolean;
}

const RequiredDocumentsList = ({ isCollapseActiveByDefault }: IProps) => {
  const { t } = useTranslation("liquidity");

  const documentsList = [
    t("Documents List.0"),
    t("Documents List.1"),
    t("Documents List.2"),
    t("Documents List.3"),
    t("Documents List.4"),
  ];

  const items: CollapseProps["items"] = [
    {
      key: 1,
      label: t("Documents Required for Each Fund"),
      children: (
        <ul>
          {documentsList.map((item) => (
            <li key={ item }>{item}</li>
          ))}
        </ul>
      ),
    },
  ];

  return (
    <Collapse
      expandIcon={ ({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />) }
      defaultActiveKey={ isCollapseActiveByDefault ? [ ...items.map((item) => String(item.key)) ] : [] }
      items={ items }
    />
  );
};

export default RequiredDocumentsList;
