export const MyAssetsTabsPages = {
  ASSET_HOLDINGS: "asset_holdings",
  INVESTMENT_ANALYTICS: "investment_analytics",
  FINANCIAL_AND_TAX_REPORTS: "financial_and_tax_reports",
};

export const AltCustodyAssetPages = {
  SelectAssets: "/select_assets",
  AddManually: "/add_manually",
  FundDetails: "/fund_details",
  InvestmentDetails: "/investment_details",
  ReviewAssets: "/review_assets",
  CustodyAccount: "/custody_account",
  CustodyAccountAgreement: "/custody_account_agreement",
  CustodyAccountId: "/custody_account_id",
  AssetDocuments: "/asset_documents",
  ThankYou: "/thank_you",
  PortfolioManagement: "/portfolio_management",
};

export enum AssetStatus {
  LOCKED_BY_BIDS = "locked_by_bids",
  NEEDS_REVIEW_BY_BIDS = "needs_review_by_bids",
}

export enum ChartType {
  BAR = "bar",
  PIE = "pie",
}

export enum ChartDataKey {
  INSTRUMENT_TYPE = "instrument_type",
  FUND_INVESTMENT_NAV = "fund_investment_nav",
  PUBLIC_OR_PRIVATE = "investment_status",
  COUNTRY = "country",
  ASSET_CLASS = "asset_class",
  SUB_ASSET_CLASS = "sub_asset_class",
  SECTOR = "sector",
  INDUSTRY_GROUP = "industry_group",
  INDUSTRY = "industry",
  SUB_INDUSTRY = "sub_industry",
  NAV_AS_OF_DATE = "nav_as_of_date",
}

export enum ChartTitle {
  SECTOR = "Industry Sector",
  COUNTRY = "Country",
  ASSET_CLASS = "Asset Class",
  INDUSTRY_GROUP = "Industry Group",
  INDUSTRY = "Industry",
  GEOGRAPHY = "Geography",
  INVESTMENT_STRATEGY = "Investment Strategy",
  INSTRUMENT_TYPE = "Instrument Type",
  FUND_INVESTMENT_NAV = "Fund Investment NAV",
  PUBLIC_OR_PRIVATE = "investment_status",
  SUB_ASSET_CLASS = "Sub Asset Class",
  SUB_INDUSTRY = "Sub Industry",
  NAV_AS_OF_DATE = "NAV As Of Date",
}

export enum AltCustodyMutationCacheKey {
  CREATE_INVESTMENT = "create-investment",
  UPDATE_INVESTMENT = "update-investment",
  DELETE_INVESTMENT = "delete-investment",
  CREATE_PRODUCT_WITH_ASSETS = "create-product-with-assets",
  CREATE_TRANSACTION_WITH_ASSETS = "create-transaction-with-assets",
}
