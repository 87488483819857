/* eslint-disable no-restricted-imports */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */

import { useNavigate } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { EditOutlined, Spinner } from "common/lib/components";
import "./assetList";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { useAppDispatch } from "store/store.hooks";
import { assetTypes } from "../addAsset/SelectOtherAsset/constants";

interface IProps {
  data?: any; 
}

const AltCustodyAssetsListItem = ({ data }: IProps) => { 
  // const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onEditAsset = () => {

    dispatch(setCurrentCustodyAccount(null))
    navigate(`${ URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS }/${ data.asset_id }/edit`)
  }
  
  function capitalizeFirstLetter(string:string = '') {
    let tag = string

    if (string === 'portfolio_analytics') {
      tag = "portfolio_analytics_available"
    }
    return tag
      .replace(/_/g, ' ') // Reemplaza los guiones bajos con espacios
      .replace(/\b\w/g, char => char.toUpperCase()); // Capitaliza cada palabra
  }

  const currencyFormatter = (value: any) => (value ? `$ ${ Number(value).toFixed(2) }`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : value);

  const getAssetTypeName = (asset:any) => {
    if (asset.asset_type === 5) {
      const description = asset.asset_description.length > 80 ? asset.asset_description.substring(0, 80) : asset.asset_description;
      return description
    }
    
    if (asset.asset_type === 2) {
      const description = asset.sponsor_description.length > 80 ? asset.sponsor_description.substring(0, 80) : asset.sponsor_description;
      return description
    }
    
    return assetTypes[ asset.asset_type ].title
  }

  const getSponsorName = (asset:any) => {
    if (asset.asset_type === 2) {
      const description = asset.sponsor && asset.sponsor.length > 80 ? asset.sponsor.substring(0, 80) : asset.sponsor;
      return description
    }
  }
 
  if (data.asset_id) {
    return (
      <Spinner spinning={ !data } key={ data.asset_id }>
        <div className="altcustody-asset-list-item" onClick={ onEditAsset }>
          <div className="title-row">
            <div className="icon-conainer">
              <EditOutlined onClick={ () => null }/>
            </div>
            <p className="cell-text">{ data.fund_manager || data.sponsor || getSponsorName(data) }</p>
          </div>
          <div className="data-cell" style={ { flex: 2 } }>
            <p className="cell-text">{ data.fund_name || data.asset_name || getAssetTypeName(data) }</p>
          </div>
          <div className="data-cell" >
            {
              !data.alt_custody_asset_status?.includes('portfolio_analytics')
                ? (
                  <>
                    <p className="cell-text" style={ { width: '100%', textAlign: 'center' } }>Not Verified</p>
                  </>
                ) : (
                  <>
                    <p className="cell-text" style={ { width: '100%', textAlign: 'right' } }>{ currencyFormatter(data.current_net_asset_value)}</p>
                  </>
                )
            }
          </div>
          <div className="data-cell">
            <p className="cell-text"  style={ { width: '100%', textAlign: 'center' } }>{ data.alt_custody_asset_status ? capitalizeFirstLetter(data.alt_custody_asset_status) : "Incomplete"}</p>
          </div>
        </div>
      </Spinner>
    );
  }
  if (data.title) {
    return (
      <div className="altcustody-asset-list-item headers">
        <div className="data-cell" style={ { flex: 2 } }>
          <p className="cell-text" >Fund Manager</p>
        </div>
        <div className="data-cell" style={ { flex: 2 } }>
          <p className="cell-text" >Fund Name</p>
        </div>
        <div className="data-cell">
          <p className="cell-text"  style={ { width: '100%', textAlign: 'center' } }>NAV</p>
        </div>
        <div className="data-cell">
          <p className="cell-text"  style={ { width: '100%', textAlign: 'center' } }>Status</p>
        </div>
      </div>
    )
  }
  return <Spinner />
  
};

export default AltCustodyAssetsListItem;
