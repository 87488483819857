import { FC } from "react";

import AssetListItem from "./assetListItem";

import "./assetList.scss";

interface IProps {
  hideAvailable?: boolean;
  assetList?: any[];
}

const AssetList: FC<IProps> = ({ assetList = [] }) => {
  if (!assetList.length) return <></>

  const displayedAssets = [ ...assetList ].reverse() 

  return (
    <div className="altcustody-asset-list-container">
    
      <AssetListItem data={ { title: true } }/>
      { displayedAssets.map((asset) => (
        <AssetListItem data={ asset } key={ asset.id } />
      ))}

    </div>
  );
};

export default AssetList;
