import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { URLs } from "common/lib/constants";
import { Spinner } from "common/lib/components";
import { useClientsSelector } from "domains/clients";
import { MyClientsPages } from "domains/clients/shared/constants";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";

import "./MyClientsList.scss";

const MyClientsList = () => {
  const { t } = useTranslation("dashboard");

  const { user, isAdvisor } = useClientsSelector();
  const { data: clients, isFetching: isFetchingClients } = clientsApiReducer.useFetchUserClientsQuery(
    user && isAdvisor ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const items = clients?.items;

  if (!clients || isFetchingClients) {
    return <Spinner />;
  }

  if (!items?.length) {
    return (
      <div className="ben-d-flex">
        <span className="ben-d-block ben-mr-5 text">{t("My clients accordion default text")}</span>
      </div>
    );
  }

  return (
    <>
      {items.map(({ account_id, account_name }, index) => (
        <Link
          to={ `${ URLs.PROTECTED.MY_CLIENTS + MyClientsPages.DASHBOARD }/${ account_id }` }
          className="ben-client-link"
          key={ index }
        >
          {account_name}
        </Link>
      ))}
    </>
  );
};

export default MyClientsList;
