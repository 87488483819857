import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { FormHeader } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import SelectFundTable from "./SelectFundTable";
import SelectFundForm from "./SelectFundForm";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

const SelectFund: FC = () => {
  const { t } = useTranslation("liquidity");

  const { currentAsset } = useLiquiditySelector();
  const [ searchByFundName ] = liquidityApiReducer.useSearchByFundNameMutation({ fixedCacheKey: "search-by-fund-name" });

  const [ inputValue, setInputValue ] = useState(currentAsset?.asset_name);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    searchByFundName(e.target.value);
  }, []);

  const handleCleanInput = useCallback(() => {
    setInputValue("");
    searchByFundName("");
  }, []);

  useEffect(() => {
    if (currentAsset?.asset_name) {
      searchByFundName(currentAsset.asset_name);
    }
  }, []);

  return (
    <>
      <FormHeader hint={ t("Select Fund Info") } />

      <div className="ben-form-container">
        <SelectFundForm inputValue={ inputValue || "" } handleChange={ handleChange } handleCleanInput={ handleCleanInput } />
        <SelectFundTable inputValue={ inputValue || "" } />
      </div>
    </>
  );
};

export default SelectFund;
