import { FC, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { resetCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { FormHeader } from "common/lib/components";
import SelectAssetsTabs from "domains/liquidity/components/SelectAssetsTabs/SelectAssetsTabs";

const SelectAssetsPage: FC = () => {
  const { t } = useTranslation("liquidity");
  const dispatch = useDispatch();

  const [ isFundAssetsTab, setIsFundAssetsTab ] = useState(true);

  useEffect(() => {
    dispatch(resetCurrentAssetData());
  }, []);

  return (
    <>
      <FormHeader
        title={ t("Assets to Exchange") }
        subtitle={ isFundAssetsTab ? t("Select Fund Title") : t("Select Non Fund Title") }
      />

      <SelectAssetsTabs onTabChange={ (activeKey) => setIsFundAssetsTab(activeKey === "0") } />
    </>
  );
};

export default SelectAssetsPage;
