/* eslint-disable no-restricted-imports */
import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { setCurrentAssetData, setIsAddManually } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { getFundItemWithBIDSFundIdIfExists } from "domains/liquidity/shared/helpers/fundSearch";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { useInvestments } from "../../hooks/useInvestments";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";

export const useHandlers = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = useLocation();
  const { createInvestment } = useInvestments();
  const { investments } = useAssetsHoldingsData();

  const [ searchByFundName ] = liquidityApiReducer.useSearchByFundNameMutation({ fixedCacheKey: "search-by-fund-name" });


  const goToInvestmentDetails = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/');
    pathParts.pop();
    const newPath = `${ pathParts.join('/') }${ AltCustodyAssetPages.InvestmentDetails }`;
    navigate(newPath);

    // navigate(newPath);
  };

  const onSelect = async (asset_name: string, fund_manager: string, key: string) => {
    const alreadyAdded = investments?.find(({ fund_id }:any) => fund_id === key)
    const currentAssetData = await getFundItemWithBIDSFundIdIfExists(asset_name, fund_manager, key);
    if (alreadyAdded) {
      alert('already added')
    }
    dispatch(setIsAddManually(false));
    dispatch(setCurrentAssetData(currentAssetData));
    searchByFundName("");
    goToInvestmentDetails()
  }

  return { onSelect };
};
