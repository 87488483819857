import AssetDocumentsTable from "domains/liquidity/components/AssetDocumentsTable";
import RequiredDocumentsList from "domains/documents/components/RequiredDocumentsList";
import { useUploadLRDocuments } from "domains/documents/shared-hooks/useUploadLRDocuments";

import "./UploadDocumentsForm.scss";

interface IProps {
  isCollapseActiveByDefault?: boolean;
}

const UploadDocumentsForm = ({ isCollapseActiveByDefault = false }: IProps) => {
  const { uploadProps } = useUploadLRDocuments();

  return (
    <div className="ben-upload-documents-form">
      <RequiredDocumentsList isCollapseActiveByDefault={ isCollapseActiveByDefault } />

      <AssetDocumentsTable uploadProps={ uploadProps } />
    </div>
  );
};

export default UploadDocumentsForm;
