import { FC, useCallback, useState } from "react";

import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import { Button } from "common/lib/components";
import AddAnonymousClientNameModal from "domains/dashboard/components/AddAnonymousClientNameModal";
import ChooseTypeOfClientModal from "domains/dashboard/components/ChooseTypeOfClientModal";
import ChooseClientModal from "domains/dashboard/components/ChooseClientModal";
import { ILiquidityRequest } from "domains/liquidity/shared/types";

import "./ApplyForLiquidityModal.scss";

interface IProps {
  handleCancel: () => void;
  isVisible: boolean;
  isLA: boolean;
  isConvertingAdvisorAltQuoteWithoutClient?: boolean;
  openRequestFormalProposalModal?: (altQuote: ILiquidityRequest) => void;
}

const ApplyForLiquidityModal: FC<IProps> = ({
  isVisible,
  handleCancel,
  isLA,
  isConvertingAdvisorAltQuoteWithoutClient = false,
  openRequestFormalProposalModal,
}) => {
  const { t } = useTranslation([ "dashboard", "common" ]);
  const [ isChooseTypeOfClientModalVisible, setIsChooseTypeOfClientModalVisible ] = useState(false);
  const [ isAddAnonymousClientNameModalVisible, setIsAddAnonymousClientNameModalVisible ] = useState(false);
  const [ isChooseClientModalVisible, setIsChooseClientModalVisible ] = useState(false);

  const handleChooseTypeOfClientModalCancel = useCallback(() => {
    setIsChooseTypeOfClientModalVisible(false);
  }, []);

  const handleAddAnonymousClientNameModalCancel = useCallback(() => {
    setIsAddAnonymousClientNameModalVisible(false);
  }, []);

  const handleChooseClientModalCancel = useCallback(() => {
    setIsChooseClientModalVisible(false);
  }, []);

  return (
    <>
      <Modal destroyOnClose footer={ null } width={ 650 } onCancel={ handleCancel } open={ isVisible }>
        <div className="ben-apply-for-liquidity-modal-container">
          <div className="ben-apply-for-liquidity-modal-title">
            <h1>{t("Have you previously completed a Liquidity Application for this client before?")}</h1>
          </div>
          <div className="ben-apply-for-liquidity-modal-buttons">
            <Button
              onClick={ () => {
                setIsChooseClientModalVisible(true);
                handleCancel();
              } }
              type="primary"
            >
              {t("Yes", { ns: "common" })}
            </Button>
            <Button
              onClick={ () => {
                setIsChooseTypeOfClientModalVisible(true);
                handleCancel();
              } }
              type="primary"
            >
              {t("No, I'd like to add this client now")}
            </Button>
            <Button
              onClick={ () => {
                setIsAddAnonymousClientNameModalVisible(true);
                handleCancel();
              } }
              type="primary"
            >
              {t("No, I'd like to keep my client confidential for now")}
            </Button>
          </div>
        </div>
      </Modal>

      <ChooseTypeOfClientModal
        isVisible={ isChooseTypeOfClientModalVisible }
        handleCancel={ handleChooseTypeOfClientModalCancel }
        isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
        openRequestFormalProposalModal={ openRequestFormalProposalModal }
        isLA={ isLA }
      />

      {isAddAnonymousClientNameModalVisible && (
        <AddAnonymousClientNameModal
          isVisible={ isAddAnonymousClientNameModalVisible }
          handleCancel={ handleAddAnonymousClientNameModalCancel }
          isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
          openRequestFormalProposalModal={ openRequestFormalProposalModal }
          // isLA={ isLA }
        />
      )}

      {isChooseClientModalVisible && (
        <ChooseClientModal
          isVisible={ isChooseClientModalVisible }
          handleCancel={ handleChooseClientModalCancel }
          isLA={ isLA }
          isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
          openRequestFormalProposalModal={ openRequestFormalProposalModal }
        />
      )}
    </>
  );
};

export default ApplyForLiquidityModal;
