import { object, string, number } from "yup";

import { useValidationErrorMessages } from "common/shared/constants/validationErrorMessages";
import { LOCATION_IDS } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/constants";
import { AssetType } from "domains/liquidity/shared/constants";
import { noSpacesInStartAndEndRegExp } from "common/shared/constants/validationRegExps";
import { useValidationSchemas } from "common/shared/constants/validationSchemas";
import { getSelectOptions } from "./forms/NonTradedREITorBDCForm/helpers";

export const useSelectOtherAssetFormValidationSchema = () => {
  const { maxNumberOfCharacters, noSpacesInStartAndEnd, required } = useValidationErrorMessages();
  const { accountNameValidationSchema, onlyDigitsSchema } = useValidationSchemas();

  const validationSchema = object().shape({
    asset_type: number().required(required),

    sponsor: string().max(200, maxNumberOfCharacters(200)).matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd),
    sponsor_description: string().when("asset_type", {
      is: AssetType.CoInvestment,
      then: (schema) =>
        schema
          .required(required)
          .max(200, maxNumberOfCharacters(200))
          .matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd),
    }),

    real_estate_property_type: number().when("asset_type", {
      is: AssetType.DirectRealEstateInvestment,
      then: (schema) => schema.required(required),
    }),
    real_estate_location: number().when("asset_type", {
      is: AssetType.DirectRealEstateInvestment,
      then: (schema) => schema.required(required),
    }),
    other_region: number().when("real_estate_location", {
      is: LOCATION_IDS.OTHER_REGION,
      then: (schema) => schema.required(required),
    }),
    state: string() /* state_or_province */,

    account_name: string().when("asset_type", {
      is: AssetType.PrivateSharesInSinglePrivatelyHeldCompany,
      then: () => accountNameValidationSchema.required(required),
    }),
    amount_invested: number().when("asset_type", {
      is: AssetType.PrivateSharesInSinglePrivatelyHeldCompany,
      then: () => onlyDigitsSchema("<=", 10),
    }),
    company_description: string().when("asset_type", {
      is: AssetType.PrivateSharesInSinglePrivatelyHeldCompany,
      then: (schema) =>
        schema.max(200, maxNumberOfCharacters(200)).matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd),
    }),

    asset_name: string().when("asset_type", ([ asset_type ]) => {
      return asset_type === AssetType.NonTradedREIT || asset_type === AssetType.NonTradedBDC
        ? string().required(required)
        : string().notRequired();
    }),
    asset_description: string().when([ "asset_type", "asset_name" ], ([ asset_type, asset_name ]) => {
      if (asset_type === AssetType.DirectInterestsInNaturalResourcesAsset) {
        return string()
          .required(required)
          .max(200, maxNumberOfCharacters(200))
          .matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd);
      }

      if (
        (asset_type === AssetType.NonTradedREIT || asset_type === AssetType.NonTradedBDC) &&
        asset_name === getSelectOptions(asset_type).at(-1)?.value
      ) {
        return string()
          .required(required)
          .max(100, maxNumberOfCharacters(100))
          .matches(noSpacesInStartAndEndRegExp, noSpacesInStartAndEnd);
      }

      return string().notRequired();
    }),
  });

  return { validationSchema };
};
