import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "common/lib/components";

import "./NavigationButtons.scss";

interface IProps {
  onBack?: () => void;
  onNext?:  (x:any) => void;
  onSkip?: () => void;
  disabled?: boolean;
  backButtonText?: string;
  submitButtonText?: string;
  isSubmitButtonLoading?: boolean;
  skipButtonText?: string;
  hideBack?: boolean;
  hideSkip?: boolean;
}

const NavigationButtons: FC<IProps> = (props) => {
  const navigate = useNavigate();
  const {
    onBack = () => {
      navigate(-1);
    },
    onNext,
    onSkip,
    disabled = false,
    backButtonText = "Back",
    submitButtonText = "Continue",
    isSubmitButtonLoading = false,
    skipButtonText = "Skip",
    hideBack = false,
    hideSkip = true,
  } = props;

  return (
    <div className="ben-form-nav-buttons-wrapper">
      <div className={ `ben-form-nav-buttons ${ hideBack ? "submit-only" : "" }` }>
        {!hideBack && <Button onClick={ onBack }>{backButtonText}</Button>}

        <Button
          loading={ isSubmitButtonLoading }
          onClick={ onNext }
          type="primary"
          htmlType="submit"
          disabled={ disabled }
        >
          {submitButtonText}
        </Button>

        {!hideSkip && <Button onClick={ onSkip }>{skipButtonText}</Button>}
      </div>
    </div>
  );
};

export default NavigationButtons;
