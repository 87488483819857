/* eslint-disable no-extra-boolean-cast */
/* eslint-disable max-len */
import { useEffect, useState } from "react";

import { skipToken } from "@reduxjs/toolkit/query"; 
import { useNavigate } from "react-router-dom";
import { RcFile } from "antd/es/upload";
import { message, Table, Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";

import { AddFileFilled, CloseWindowOutlined, DeleteOutlined, DownloadDocumentIcon, Spinner } from "common/lib/components";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { allowedMimeTypes } from "domains/documents/shared/constants";
import { getIconForFile, truncateFileName } from "domains/documents/shared/utils";
import { apiService } from "common/shared";
import { useClientsSelector } from "domains/clients";
import { useAppDispatch } from "store/store.hooks";
import { IDocument } from "domains/documents/shared/types";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { useScreen } from "common/shared-hooks";
import { DocumentPasswordForm } from "./DocumentPasswordForm";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { AccountType } from "common/lib/constants";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";

import "./documentsUpload.scss";

const DocumentsUpload = ({ documentProps, onFirstDocumentUploaded }:any) => {
  const { isAdvisor } = useClientsSelector();
  const [ isDeleting, setIsDeleting ] = useState(false)
  const { liquidity_request_id, currentLiquidityRequest } = useLiquiditySelector();
  const { user } = useClientsSelector();
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
 const activeCustodyAccounts = custodyAccounts?.filter(
    (custodyAccount) =>
      custodyAccount.account_status !== CustodyAccountStatus.Closed &&
      custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
      custodyAccount.account_status !== CustodyAccountStatus.Duplicate &&
      custodyAccount.account_type !== AccountType.CustodialAdvisor,
  );
  const { t } = useTranslation([ "liquidity", "common" ]);
  const { mobile } = useScreen();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [ uploadDocument ] = documentsApiReducer.useUploadDocumentMutation();
  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation()
  const [ deleteDocument ] = documentsApiReducer.useDeleteDocumentMutation({
    fixedCacheKey: "delete-document",
  });

  const uploadProps: UploadProps = {
    accept: allowedMimeTypes.join(","),
    multiple: true,
    showUploadList: false,
    onDrop: (event: any) => {
      const invalid = Array.from(event.dataTransfer!.files).some((file: any) => !allowedMimeTypes.includes(file.type));
      if (invalid) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
      }
    },
    beforeUpload: (file: RcFile): boolean => {
   
      if (!allowedMimeTypes.includes(file.type)) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
        return false;
      }

      if ((file.size ?? 0) > 500 * 1_000_000) {
        message.error(t("validation.fileSize", { ns: "common", value: 500 }));
        return false;
      }
      return true;
    },
    customRequest: async (options) => {
      const file = options.file as RcFile;
      const doc: any = {
        file_name: truncateFileName(file.name.replace(/[^- _a-zA-Z0-9!.'()]/g, "-")),
        document_type: documentProps.document_type,
        reference_type: documentProps.reference_type,
        file_size: file.size,
        id: file.uid,
        uid: file.uid,
        cancelToken: apiService.getCancelToken(),
        mimeType: file.type,
      };
      if (documentProps.asset_id) {
        doc.asset_id = documentProps.asset_id;
      }
      if (documentProps.document_name) {
        doc.document_name = documentProps.document_name;
      }
      if (documentProps.liquidity_request_id) {
        doc.liquidity_request_id = documentProps.liquidity_request_id;
      }

      documentProps.setDocumentList((prevList: any) => [ ...prevList, { doc, file, uploadStatus: 'pending' } ]);
    },
  };
  
  const uploadCurrentDocument = async (document:any) => {
    if (document) {
      const { doc, file } = document
      const response:any = await uploadDocument({
        accountId: user?.account_id as string,
        document: doc as any,
        file,
      });
      const didFail = response.error
      if (!!didFail) {
        updateDocumentStatusById(doc.id, 'failed')
      } else {
        updateDocumentStatusById(doc.id, 'uploading')
      }
 
      if (!response.length && liquidity_request_id) {
          const changes: any = {
              status: 'in_document_review',
          }  
          await updateLiquidityRequest({ LRId: liquidity_request_id ?? "" ?? '', changes })
      }
      
    }

  }

  const upload = async () => {
    
    if ( documentProps.documentList.length ) {
      const skip = documentProps.documentList.find(({ uploadStatus }: any) => uploadStatus === 'uploading');
      const pendingDocument = documentProps.documentList.find(({ uploadStatus }: any) => uploadStatus === 'pending');
      if (pendingDocument && !skip) {
        if (!activeCustodyAccounts?.length && !isAdvisor) {
          const currentPath = location.pathname;
          const pathParts = currentPath.split('/');
          const newPath = `${ pathParts.join('/') }${ AltCustodyAssetPages.CustodyAccountAgreement }`;
          navigate(newPath, { state: { fromUpload: true } });
          return false;
        }
        await uploadCurrentDocument(pendingDocument);  
      }
    } 
  }
  
  useEffect(() => {
    upload()
  }, [ documentProps.documentList ])
  
  const removeDocumentById = (id: string) => {
    documentProps.setDocumentList((prevList: any) => prevList.filter((document: any) => document && document.doc.id !== id));
  };

  const updateDocumentStatusById = (id: string, uploadStatus:string) => {
    documentProps.setDocumentList((prevList: any) =>
      prevList.map((document: any) =>
        document && document.doc.id === id
          ? { ...document, doc: { ...document.doc }, uploadStatus }
          : document,
      ),
    );
  };

  const renderTable = () => {
    const columns: any = [
      {
        title: "Document",
        key: "assetName",
        width: "25%",
        render: () => (
          <Upload.Dragger { ...uploadProps }>
            <p>{t("Drag and drop files here")}</p>
          </Upload.Dragger>
        ),
        onCell: (_: any, rowIndex: any) => ({
          rowSpan: rowIndex === 0 ? documentProps.documentList?.length : 0,
        }),
        className: "ben-drug-n-drop-section",
      },
      {
        key: "file_name",
        width: "25%",
        title: t("File Name"),
        dataIndex: "file_name",
        render: (file_name: string, document: any) => (
          <div key={ document.doc.uid }>
            {getIconForFile(document.doc.file_name)}
            <p>{document.doc.file_name}</p>
          </div>
        ),
        className: "ben-file-name-section",
      },
      {
        key: "date_provided",
        width: "20%",
        title: t("Date Provided"),
        dataIndex: "date_provided",
        render: (date_provided: string, doc: any) => {
          return <p>{doc.doc.percent ? `${ t("Uploading...") } ${ doc.doc.percent }%` : `${ getDateFromISO(doc.doc.date_provided) }`}</p>;
        },
        className: "ben-date-provided-section",
      },
      {
        key: "document_password",
        width: "30%",
        title: t("Document Password, If Applicable"),
        dataIndex: "document_password",
        render: (document_password: string, document: any) => {
          if (user) {
            return <DocumentPasswordForm document={ document.doc } accountId={ user.account_id } /> 
          }
        },
        className: "ben-document-password-section",
      },
      {
        key: "action",
        width: "7%",
        render: (document: any) => (
          user 
            ? document.uploadStatus && document.uploadStatus === 'uploaded' ? <DownloadDocumentIcon documentId={ document.doc.id } accountId={ user.account_id } iconClassName="ben-download-file-icon" /> : <></>
            : <></>
        ),
        className: "ben-action-section",
      },
      {
        key: "action",
        width: "5%",
        render: (document: any) => {
          if (document.uploadStatus) {
            if (document.uploadStatus === 'pending') {
              return <Spinner spinning={ true }></Spinner>
            }
            if (document.uploadStatus === 'uploaded') {
              return (
                <DeleteOutlined
                  onClick={ async () => {
                    setIsDeleting(true)
                      await deleteDocument({
                        accountId: user?.account_id ?? '',
                        documentId: document.doc.id,
                      });
                    setIsDeleting(false)
                  } }
                />
              );
            }
          }
          if (document.doc.cancelToken) {
            return (
              <CloseWindowOutlined
                onClick={ () => {
                  removeDocumentById(document.doc.id); 
                  apiService.cancel(document.doc.cancelToken)
                } }
              />
            )
          }
        },
        className: "ben-action-section",
      },
    ];

    return (
      <>
        <Table
          className="ben-asset-documents-table"
          locale={ {
            emptyText: (
              <Upload.Dragger { ...uploadProps } openFileDialogOnClick={ true }>
                <p>{t("Drag and drop files here")}</p>
              </Upload.Dragger>
            ),
          } }
          dataSource={ documentProps.documentList.map((doc: any) => (doc)) }
          rowKey={ (doc) =>  doc.doc.uid }
          columns={ columns }
          pagination={ false }
          scroll={ { y: 400 } }
        />
      </>
    );
  };

  const renderMobileView = () => {
    return (
      <div className="ben-asset-documents-list-mobile">
        <h4>Document</h4>
        <Upload { ...uploadProps } openFileDialogOnClick={ true }>
          <div>
            <AddFileFilled className="ben-icon" width={ 30 } height={ 30 } />
            <p>{t("Add File")}</p>
          </div>
        </Upload>

        {documentProps.documentList?.map(({ doc: document }: any) => {
          return (
            <div className="ben-files-list-item" key={ document.file_name }>
              {getIconForFile(document.file_name)}
              <p>{document.file_name}</p>
              <p className="ben-pl-2">
                {document.percent
                  ? `${ t("Uploading...") } ${ document.percent }%`
                  : `${ getDateFromISO(document.date_provided) }`}
              </p>

              {user && <DownloadDocumentIcon
                documentId={ document.id }
                accountId={ user.account_id }
                spinnerClassName="ben-download-file-icon"
                iconClassName="ben-download-file-icon"
              />}

              {document.cancelToken ? (
                <CloseWindowOutlined
                  className="ben-delete-file-btn"
                  onClick={ () => apiService.cancel(document.cancelToken) }
                />
              ) : (
                <DeleteOutlined
                  className="ben-delete-file-btn"
                  onClick={ async () => {
                    setIsDeleting(true)
                      await deleteDocument({
                        accountId: user?.account_id ?? '',
                        documentId: document.id,
                      });
                    setIsDeleting(false)
                  } }
                /> 
              )}
            </div>
          );
        })}
        {documentProps.documentList?.map(({ doc: document }: any) => {
        return (
          <div className="ben-files-list-item" key={ document.file_name }>
            {getIconForFile(document.file_name)}
            <p className="ben-w-45">{document.file_name}</p>
            <p className="ben-w-50">
              { user && <DocumentPasswordForm key={ document.id } document={ document } accountId={ user.account_id } /> }
            </p>
          </div>
        );
      })}
      </div>
    );
  };

  if (mobile) {
    return (
      <Spinner spinning={ isDeleting }>
        { renderMobileView() }
      </Spinner>
    )
  }
  return (
    <Spinner spinning={ isDeleting }>
      { renderTable() }
    </Spinner>
  )

  
};

export default DocumentsUpload;
