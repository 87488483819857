const getChangedFormValues = (formValues: any, initialValues: any, formatToString = true) => {
  return Object.entries(formValues).reduce((acc, [ key, value ]) => {
    const hasChanged = String(initialValues[ key ]) !== String(value);
    if (hasChanged) {
      // @ts-ignore
      acc[ key ] = formatToString ? String(value) : value;
    }

    return acc;
  }, {});
};

export default getChangedFormValues;
