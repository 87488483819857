import { useMemo } from "react";

import { AssetType } from "domains/liquidity/shared/constants";
import { TAssetType } from "domains/liquidity/shared/types";

const useFilteredAssets = (assets: TAssetType[]) => {
  const fundAssets = useMemo(
    () =>
      assets.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          (asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets ],
  );

  const nonFundAssets = useMemo(
    () =>
      assets.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          !(asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets ],
  );

  return {
    fundAssets,
    nonFundAssets,
  };
};

export default useFilteredAssets;
