import { apiEnvs, apiService } from "common/shared";
import { formatValuesForUpdate } from "common/shared/helpers/formatter/apiFormatter";
import { TAssetType } from "domains/liquidity/shared/types";

export class AltCustodyApiService {
  async fetchChartData(accountId: string): Promise<any> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/graph_data/${ accountId }`);
  }

  async fetchSnapshots(accountId: string): Promise<any> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/investments/snap_shot/${ accountId }`);
  }

  async createSnapshot(data: any): Promise<any> {
    return await apiService.post(`${ apiEnvs.holdingsAPI }/v1/investments/snap_shot`, data);
  }

  async updateSnapshot(requestBody: any, snapshotId: string): Promise<any> {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/snap_shot/${ snapshotId }`, requestBody);
  }

  async fetchInvestments(accountId: string): Promise<TAssetType[]> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/investments_client/${ accountId }`);
  }

  async fetchInvestment(investmentId: string): Promise<TAssetType> {
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`);
  }

  async createInvestment(data: Partial<TAssetType>): Promise<Pick<TAssetType, "id">> {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/investments`, data);
  }

  async updateInvestment(investmentId: string, changes: Partial<TAssetType>): Promise<TAssetType> {
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`, formatValuesForUpdate(changes));
  }

  async deleteInvestment(investmentId: string) {
    return apiService.delete(`${ apiEnvs.holdingsAPI }/v1/investments/${ investmentId }`);
  }

  async createTransaction(transaction: any) {
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests`, transaction);
  }
}

export const altCustodyApiService = new AltCustodyApiService();
