/* eslint-disable max-len */
import { FC, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Form } from "antd";

import SelectAssetsForAltQuote from "./selectAssetsForLiquidtyRequest";
import { setAssetsPendingCustodyAccount, setCurrentAssetData, setSelectedAssetsForLiquidity } from "domains/liquidity/store/liquidity.reducer";
import { Button, FormHeader, NavigationButtons, Spinner } from "common/lib/components";
import { formatLiquidityRequestBodyForRequest } from "domains/liquidity/shared/utils/apiFormatter";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useClientsSelector } from "domains/clients";
import { useAppDispatch } from "store/store.hooks";
import { UserType } from "domains/clients/shared/types";
import { Select } from "common/lib/formik-antd";

import "./newLiquidtyRequest.scss";

const NewLiquidtyRequest: FC = () => {    
    const dispatch = useAppDispatch();
    const navigate = useNavigate()
    
    const { user, isAdvisor, currentClient } = useClientsSelector();

    const { pathname, search } = useLocation()
    const queryParams = new URLSearchParams(search);
    const { investments: assets } = useAssetsHoldingsData()
    const { investments, loading } = useAssetsHoldingsData()
    const { selectedAssetsForLiquidity } = useLiquiditySelector()
    const { data: documents = [] } = documentsApiReducer.useFetchDocumentsByIdQuery(user ? user.account_id : '', {
       skip: !user?.account_id,
    });
    const [ creatingLiquidityRequest, setCreatingLiquidityRequest ] = useState(false)
    const [ reasonForSeekingLiquidity, setReasonForSeekingLiquidity ] = useState(queryParams.get('reason') || "")
    const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation()
    const [ createLRAsset ] = liquidityApiReducer.useCreateLRAssetMutation({ fixedCacheKey: "create-lr-asset" })
    const [ createLiquidityRequest ] = liquidityApiReducer.useCreateLiquidityRequestMutation({ fixedCacheKey: "create-liquidity-request" })
    const [ confirmAssets, setConfirmAssets ] = useState(queryParams.get('confirmAssets') === "true")

    const isAltQuote = pathname.includes('altquote')
    const reasonsForSeekingLiquidity = [
        { label: "Life Circumstance" },
        { label: "Business Need" },
        { label: "Portfolio Rebalancing" },
        { label: "Tax Motivation" },
        { label: "Regulatory/Compliance" },
        { label: "GP Restructuring" },
        { label: "Other" },
    ];

    const connectAssets = async (assetsToCreate: any, LRId: string) => {
        const isAltquote = pathname.includes('altquote');
        const changes: any = { }
        if (isAltquote) {
            changes.reason_for_seeking_liquidity = reasonForSeekingLiquidity
            changes.status = 'alt_quote_submitted'
        } else {
            changes.reason_for_seeking_liquidity = reasonForSeekingLiquidity
            changes.status = documents.length ? 'in_document_review' : 'pending_asset_statements'
        }
        for (const asset of assetsToCreate) {
            try {
                const assetValues = { ...asset };
                dispatch(setCurrentAssetData(assetValues)); 
                dispatch(setAssetsPendingCustodyAccount(assetValues.asset_id))
                await createLRAsset(LRId)
                await updateLiquidityRequest({ LRId, changes })
            } catch (error) {
                console.error("Error al crear asset:", error);
            }
        }
    };

    const handleSubmit = async () => {
        const isAltquote = pathname.includes('altquote');
        setCreatingLiquidityRequest(true)
        const applicationType = isAltquote ? 'alt_quote' : 'liquidity_request'
        if (user) {
            formatLiquidityRequestBodyForRequest(applicationType, user)
            const assetsToCreate: any = assets?.filter( ({ id }) => id 
                && selectedAssetsForLiquidity?.some((astId:any) => astId === id))
            const overrideData = !isAltquote 
                ? {
                    status: LiquidityRequestStatus.PendingSubmission,
                } : undefined 
            let LRId
            if (isAdvisor) {
                LRId = await createLiquidityRequest({ isLA: !isAltquote, overrideData, client: currentClient ?? undefined });
            } else {
                LRId = await createLiquidityRequest({ isLA: !isAltquote, overrideData });
            }
            if (LRId.data) {
                await connectAssets(assetsToCreate, LRId.data)
                if (!isAltquote) {
                    navigate(`.${ AltCustodyAssetPages.AssetDocuments  }`)
                } else {
                    navigate(`/my-applications/${ LRId.data }/altquote`)
                }
            }
        }
        setCreatingLiquidityRequest(false)
    }

    const handleSelect = (value: any) => {
        setReasonForSeekingLiquidity(value)
    }

    useEffect(() => {
        if (!loading && !assets?.length) {
            navigate('./select_assets')
        }
    }, [ loading ])

    useEffect(() => {
        if (!selectedAssetsForLiquidity.length && confirmAssets) {
            setConfirmAssets(false)
        }
    }, [ selectedAssetsForLiquidity ])

    useEffect(() => {
        if (reasonForSeekingLiquidity) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set("reason", reasonForSeekingLiquidity)
            navigate(`${ location.pathname }?${ searchParams.toString() }`, { replace: true });
        }
    }, [ reasonForSeekingLiquidity ])

    return (
        <Spinner spinning={ creatingLiquidityRequest } >
            <div className="ben-main-form-wrapper">
                <FormHeader
                    subtitle={ `Confirm Assets for ${ isAltQuote ? "AltQuote" : "Liquidity Request" }` }
                    title="Assets to Exchange"
                    hint="Review Assets Info"
                />
                <div className="ben-form-container" style={ { gap: 32 } }>
                    {!assets?.length
                        ? (
                        <div className="ben-no-assets">
                            <span>
                                Please, add assets to your { isAltQuote ? "AltQuote" : "Liquidity Request" }
                            </span>

                            <Button onClick={ () => navigate('./select_assets') }>+ New Asset</Button>
                        </div>
                        ) : (
                            <SelectAssetsForAltQuote
                                selectedAssets={ selectedAssetsForLiquidity }
                                setSelectedAssets={ (string:string) => {
                                    dispatch(setSelectedAssetsForLiquidity(string))
                                } }
                                investments={ investments }
                            /> 
                        )
                    }

                    <Formik
                        initialValues={ { reason_for_seeking_liquidity: '' } }
                        validateOnMount
                        validateOnChange
                        onSubmit={ handleSubmit }
                    >
                        <Form layout="vertical">
                            <Form.Item
                                className="ben-input"
                                label="Select Reason for Seeking Liquidity"
                                name="reason_for_seeking_liquidity"
                            >

                                <Select
                                    onSelect={ handleSelect }
                                    initialValue={ reasonForSeekingLiquidity }
                                    placeholder="Select Reason for Seeking Liquidity"
                                    name="reason_for_seeking_liquidity"
                                >
                                    {reasonsForSeekingLiquidity.map((reason) => (
                                        <Select.Option key={ reason.label } value={ reason.label }>
                                        {reason.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <NavigationButtons
                                disabled={ !selectedAssetsForLiquidity?.length || !reasonForSeekingLiquidity }
                                submitButtonText="Submit"
                                backButtonText={ `+ Add Another Asset` }
                                onNext={ handleSubmit }
                                onBack={ () => navigate('./select_assets') }
                            />
                        </Form>
                    </Formik>
                </div>
            </div>
        </Spinner>
    );
}

export default NewLiquidtyRequest;
