/* eslint-disable no-restricted-imports */
import { useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { FormHeader } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import InvestmentDetailsForm from "./InvestmentDetailsForm";

const InvestmentDetailsPage = () => {
  const { t } = useTranslation("liquidity");
  const { ASTId } = useParams()
  const { currentAsset, isAddManually } = useLiquiditySelector();
  const subtitle = t(`Investment Details Page.${ isAddManually ? "SubTitle With Steps" : "SubTitle" }`);
  const renderContent = () => {
    return (
      <>
        <FormHeader subtitle={ subtitle } hint={ t("Investment Details Page.Hint") } assetName={ currentAsset.asset_name } />
        <div className="ben-form-container" >
          <InvestmentDetailsForm />
        </div>
      </>
    )
  }

  useEffect(() => {
  },[])

  if (ASTId) {
    return (
      <div className="ben-main-form-wrapper">
        { renderContent() }
      </div>
    )
  }
  return (
    <>
      { renderContent() }
    </>
  );
};

export default InvestmentDetailsPage;
