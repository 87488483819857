import { MouseEvent } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CompactToDo from "domains/dashboard/components/CompactToDos/CompactToDo";
import { URLs } from "common/lib/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { ITask } from "domains/myApplications/shared/types";
import { LAPages } from "domains/liquidity";
import { Spinner } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";

interface IProps {
  LRId: string;
  toDo: ITask;
  createdAt: string;
}

enum TodoActionButtons {
  AssetButton = "asset_button",
  CustodyAgreementButton = "custody_agreement_button",
  AssetOwnershipButton = "asset_ownership_button",
  CustomerProfileButton = "customer_profile_button",
  ProposalsButton = "proposals_button",
  BTSButton = "bts_button",
}

const StandardToDo = ({ LRId, toDo, createdAt }: IProps) => {
  const { t } = useTranslation([ "dashboard", "myApplications" ]);
  const navigate = useNavigate();
  let onClick: ((e: MouseEvent) => void) | null = null;
  let buttonText: string | null = null;
  let { currentLiquidityRequest } = useLiquiditySelector();

  const { isFetching } = liquidityApiReducer.useFetchLRCurrentTaskQuery(LRId);

  if (!toDo && !isFetching) {
    return null;
  }

  if (!toDo && isFetching) {
    return <Spinner fullWidth />;
  }

  switch (toDo.ui_component) {
    case TodoActionButtons.AssetButton:
      buttonText = t("Assets", { ns: "myApplications" });
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (currentLiquidityRequest?.status === LiquidityRequestStatus.PendingSubmission) {
          navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ReviewAssets);
        } else {
          navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.ASSETS_INCLUDED);
        }
      };
      break;
    case TodoActionButtons.CustodyAgreementButton:
      buttonText = t("Custody Agreement");
      onClick = (e) => {
        e.stopPropagation();
        navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
      };
      break;
    case TodoActionButtons.AssetOwnershipButton:
      buttonText = t("Provide Asset Ownership");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.MY_APPLICATION_DOCS);
      };
      break;
    case TodoActionButtons.CustomerProfileButton:
      buttonText = t("Customer Profile");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.MY_APPLICATION_DOCS);
      };
      break;
    case TodoActionButtons.ProposalsButton:
      buttonText = t("View Full Proposal");
      onClick = (e: MouseEvent) => {
        e.stopPropagation();
        navigate(URLs.PROTECTED.MY_APPLICATIONS + LRId + "/" + MyApplicationsPages.PROPOSALS);
      };
      break;
    case TodoActionButtons.BTSButton:
      buttonText = t("Download PDF");
      break;
  }

  return (
    <CompactToDo
      toDoText={ (toDo.custom_to_do_text
        ? toDo.custom_to_do_text.split(".")[ 0 ]
        : toDo.default_to_do_text.split(".")[ 0 ]
      ).replace("(date)", createdAt) }
      buttonText={ buttonText }
      onClick={ onClick }
    />
  );
};

export default StandardToDo;
