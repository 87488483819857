/* eslint-disable no-restricted-imports */
/* eslint-disable @typescript-eslint/naming-convention */
import { useEffect, useState } from "react";

import { Modal } from "antd";
import { Formik } from "formik";

import { Caret, NavigationButtons } from "common/lib/components";
import ChartContainer from "domains/altCustody/components/ChartContainer";
import AddChartButton from "domains/altCustody/components/AddChartButton";
import EditChartModal from "domains/altCustody/components/EditChartModal";
import { IChart } from "domains/altCustody/shared/types";
import { formatChartTitleToHumanReadable, prepareChartData } from "domains/altCustody/shared/utils/helpers";
import { tableDataToDataSource } from "./InvestmentTable";
import "../portfolioManager.scss";
import { Form, Select } from "common/lib/formik-antd";
import { ChartType } from "domains/altCustody/shared/constants";
import { chartDataOptions } from "domains/altCustody/components/EditChartModal/constants";

interface InvestmentChartsProps {
    onChangeActiveChart: any; // Asumiendo que es una función que recibe un key
    chartData: any[]; // Tipo de datos, asumiendo que es un array de objetos genéricos. Cambia según sea necesario.
  }

const chartTypeOptions = Object.values(ChartType).map((type) => ({
    label: type[ 0 ].toUpperCase() + type.slice(1),
    value: type,
}));

const InvestmentCharts = ({ onChangeActiveChart, chartData, charts, onChange, handleSliceFilter }: any) => {
    const [ selectedChart, setSelectedChart ] = useState<any>({ });
    const [ activeChartInfo, setActiveChartInfo ] = useState<{ chart?: IChart; activeIndex?: number }>({});
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    
    const handleOpenModal = () => {
        setIsModalOpen(true);
    }

    const handeCloseModal = () => {
      setIsModalOpen(false);
      setSelectedChart({ })
    }

    const onChartFragmentClick = (activeChartInfo:any) => {
      setActiveChartInfo(activeChartInfo);
      if (activeChartInfo) {
        handleSliceFilter(activeChartInfo.entry.name)
      } else {
        handleSliceFilter('')
      }
    }

    const  handleRemoveClick = () => {
      if (Object.keys(selectedChart).length) {
        const updatedCharts = [ ...charts ].filter(({ key }) => {
          return selectedChart.key !== key
        })
        onChange(updatedCharts)
      }
      handeCloseModal()
    }

    const  onEditChart = (params: any) => {
      setSelectedChart(params)
      handleOpenModal()
    }


    const handleSaveClick = (params: IChart) => {
        const { type, dataKey } = params
        let newChartData
        if (Object.keys(selectedChart).length) {
          const newCharts = [ ...charts ]

          // Buscamos la gráfica que coincida con el `key` de `selectedChart` y la actualizamos
          const updatedCharts = newCharts.map(chart => {
              if (chart.key === selectedChart.key) {
                  // Si encontramos la gráfica que se está editando, la actualizamos
                  return {
                      ...chart,
                      title: formatChartTitleToHumanReadable(dataKey),
                      type,
                      dataKey,
                      data: prepareChartData({
                          data: tableDataToDataSource(chartData),
                          dataKey,
                      }),
                  };
              }
              return chart; // Si no es la gráfica que estamos editando, la dejamos igual
          });
  
          // Establecemos el nuevo estado de las gráficas con la gráfica editada
          onChange(updatedCharts);
        } else {
          newChartData = prepareChartData({
            data: tableDataToDataSource(chartData),
            dataKey,
          });
          onChange([ ...charts, {
              key: Math.random(),
              title: formatChartTitleToHumanReadable(dataKey),
              type,
              dataKey,
              data: newChartData,
          } ])
        }
        handeCloseModal(); 
    };

    useEffect(() => {
      onChangeActiveChart(activeChartInfo)
    }, [ activeChartInfo ])
    
    useEffect(() => {
        const updatedCharts = [ ...charts ].map(chart => {
            const formattedChartData = tableDataToDataSource(chartData)
            return ({
                ...chart,
                data: Object.values(
                    formattedChartData.reduce((acc: any, chart_: any) => {
                      const value = chart_[ chart.dataKey ];
                      if (acc[ value ]) {
                        acc[ value ].count += 1;
                      } else {
                        acc[ value ] = {
                          name: value,
                          count: 1,
                        };
                      }
                  
                      return acc;
                    }, {}),
                  ).map((item: any) => ({
                    name: item.name,
                    value: item.count,
                  })),
            })
        });
        onChange(updatedCharts)
    }, [ chartData ])

    return (
        <>
          <div id="charts-list-container" className="ben-charts-list-container">
            {charts.map((chart: any) => (
              <ChartContainer
                onEdit={ () => onEditChart(chart) }
                key={ chart.key }
                chart={ chart }
                activeIndex={ activeChartInfo?.chart?.key === chart.key ? 0 : undefined }
                tableDataSource={ chartData }
                onChartFragmentClick={ onChartFragmentClick }
              />
            ))}
    
            {charts.length <= 10 && <AddChartButton onClick={ handleOpenModal } />}
          </div>
    
          {isModalOpen && (
            <Modal centered footer={ null }  open={ isModalOpen } onCancel={ handeCloseModal }>
                <div className="ben-edit-chart-modal-content">
                    <h1>Create/Update Chart</h1>
                    <div className="ben-form-container">
                        <Formik
                        initialValues={ selectedChart }
                        onSubmit={ handleSaveClick }
                        enableReinitialize
                        validateOnMount
                        >
                        {({ isValid, dirty }) => {
                            return (
                            <Form layout="vertical" style={ { margin: "0 auto 0 0" } } className="ben-custody-account-id-form">
                                <Form.Item name="type" label={ "Chart Type" } className="ben-input">
                                <Select
                                    showSearch
                                    name="type"
                                    optionFilterProp="label"
                                    suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                                    options={ chartTypeOptions }
                                    // @ts-ignore
                                    autoComplete="nope"
                                />
                                </Form.Item>
            
                                <Form.Item name="dataKey" label={ "Chart Data" } className="ben-input">
                                <Select
                                    showSearch
                                    name="dataKey"
                                    optionFilterProp="label"
                                    suffixIcon={ <Caret style={ { transform: "rotate(90deg)" } } /> }
                                    options={ chartDataOptions }
                                    // @ts-ignore
                                    autoComplete="nope"
                                />
                                </Form.Item>
            
                                <NavigationButtons
                                    disabled={ !isValid || !dirty }
                                    backButtonText="Cancel"
                                    submitButtonText="Save"
                                    skipButtonText="Remove"
                                    hideSkip={ !Object.keys(selectedChart).length }
                                    onSkip={ handleRemoveClick }
                                    onBack={ handeCloseModal }
                                />
                            </Form>
                            );
                        }}
                        </Formik>
                    </div>
                </div>
            </Modal>
          )}
        </>
      );
}

export default InvestmentCharts
