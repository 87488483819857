/* eslint-disable no-restricted-imports */
import { useCallback } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { URLs } from "common/lib/constants";
import { TNonFundAsset } from "domains/liquidity/shared/types";
import { getAssetValuesByType } from "./helpers";
import { useAppDispatch } from "store/store.hooks";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { navigateToMyAssets } from "domains/altCustody/shared/utils/helpers";
import { useClientsSelector } from "domains/clients";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useInvestments } from "../hooks/useInvestments";

export const useHandlers = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { LRId } = useParams();
  // const { isAdvisor } = useClientsSelector();
  // const { currentAsset } = useLiquiditySelector();
  // const { isInvestmentEditing } = useAltCustodySelector();
  const { createInvestment } = useInvestments();
  const [ createLRAsset ] = liquidityApiReducer.useCreateLRAssetMutation({
    fixedCacheKey: "create-lr-asset",
  });

  const onSubmit = useCallback(
    (formValues: Partial<TNonFundAsset>) => {
      const assetValues = getAssetValuesByType(formValues);
      dispatch(setCurrentAssetData({ ...assetValues }));

      // if (isInvestmentEditing) {
      //   !updateInvestment({ ...currentAsset, ...formValues }) && navigateToMyAssets(isAdvisor);
      //   return;
      // }

      createInvestment();
    },
    [ createInvestment ],
    // [ isInvestmentEditing,  createInvestment, isAdvisor, currentAsset ],
  );

  const onSelect = useCallback((values: Partial<TNonFundAsset>, setFieldValue: any, setFieldTouched: any) => {
    Object.keys(values).forEach((key) => {
      if (key !== "asset_type") {
        setFieldValue(key, undefined);
        setFieldTouched(key, false);
      }
    });
  }, []);

  return { onSubmit, onSelect };
};
