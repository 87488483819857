import { UploadFile } from "antd/lib/upload/interface";

import { AssetType, LocationRegion, RealEstatePropertyType } from "./constants";
import { ITask } from "domains/myApplications/shared/types";

export interface IFundSearchItem {
  fundName: string;
  fundManager: string;
  synonyms: string;
  id: string;
}

export interface IBidsFundSearchItem extends Omit<IFundSearchItem, "synonyms" | "fundManager"> {}

export type TAssetType = IFundAsset & TNonFundAsset;

export interface IBasicAssetInfo {
  asset_type: AssetType;
  asset_name: string;
  asset_id: string;
  display_id: string
  id?: string;
  audit_logs_id?: string;
}

export interface IAsset {
  asset_description: string | null;
  asset_name: string;
  asset_type: number;
  bids_id: string | null;
  created_by: string;
  created_date: string;
  currency: number | null;
  display_id: string;
  fund_id: number;
  fund_name: string | null;
  fund_size: number | null;
  fund_strategy: string | null;
  geographic_focus: string | null;
  id: string;
  is_test_data: boolean | null;
  parent_type: string | null;
  real_estate_location: string | null;
  real_estate_property_type: string | null;
  salesforce_id: string | null;
  sponsor: string;
  sponsor_description: string | null;
  stated_fund_life: string | null;
  sub_type: string | null;
  updated_by: string | null;
  updated_date: string | null;
  vintage: string | null;
  vintage_year: number | null;
}

export interface IFundAsset extends IBasicAssetInfo {
  asset: IAsset;
  fund_id: number | string;
  fund_manager: string;
  sponsor: string;
  vintage: string;
  asset_status?: string;
  fund_strategy: string;
  created_date: string;
  geographic_focus: string;
  current_net_asset_value: number;
  capital_commitment: number;
  unfunded_commitment_currency?: number;
  unfunded_commitment_percentage?: number;
  unfunded_commitment_type?: string;
  liquidity_request_id: number;
  investment_id?: string;
}

export type TNonFundAsset = INonTradedREITorBDC &
  ICoInvestment &
  IDirectRealEstateInvestment &
  IEquityInSinglePrivatelyHeldCompany &
  IDirectInterestsInNaturalResourcesAsset;

export interface INonTradedREITorBDC extends IBasicAssetInfo {
  asset_name: string;
  amount_invested: number;
  asset_description?: string;
}

export interface ICoInvestment extends IBasicAssetInfo {
  sponsor: string;
  sponsor_description: string;
}

export interface IDirectRealEstateInvestment extends IBasicAssetInfo {
  real_estate_property_type: RealEstatePropertyType;
  real_estate_location: LocationRegion;
  state?: string /* state_or_province */;
  other_region?: LocationRegion;
}

export interface IEquityInSinglePrivatelyHeldCompany extends IBasicAssetInfo {
  account_name: string;
  amount_invested: number;
  company_description: string;
}

export interface IDirectInterestsInNaturalResourcesAsset {
  asset_description: string;
}

export interface IVintage {
  label: number | string;
  key?: number;
}

export interface IFile extends UploadFile {
  dateProvided: Date;
  category?: DocumentCategories;
}

export enum DocumentCategories {
  All = "View All",
  Statements = "Statements",
  ProposalsAndTerms = "Proposals and Terms",
}

export interface IChangedAssetValue {
  op: string;
  path: string;
  value: string | number | RealEstatePropertyType | LocationRegion | AssetType | IFile[];
}

export interface ILiquidityRequest {
  account_display_id: string;
  account_id: string;
  account_name: string;
  accounts: ILRAccount[];
  alt_quote_submission_email: string;
  created_at: string;
  created_by: string;
  created_by_name: string;
  display_id: string;
  holdings: IHolding[];
  id: string;
  is_active: boolean;
  is_advisor_created: boolean;
  is_test_data: boolean;
  previous_status: string | null;
  session_id: string;
  status: string;
  liquidity_request_type: LiquidityRequestTypes;
  liquidity_request_name: string;
  currentTask: ITask;
  client: ILRAccount;
  reason_for_seeking_liquidity: string;
}

export enum LiquidityRequestTypes {
  AltQuote = "alt_quote",
  Application = "liquidity_request",
  AltCustody = "alt_custody",
}

export enum LiquidityRequestStatus {
  PendingSubmission = "pending_submission",
  PendingAssetStatements = "pending_asset_statements",
  InDocumentReview = "in_document_review",
  InProposalPending = "in_proposal_pending",
  InProposal = "in_proposal",
  PendingOwnership = "pending_ownership",
  PendingCustomerProfile = "pending_customer_profile",
  GeneratingBts = "generating_bts",
  InCounterSignatures = "in_counter_signatures",
  CounterSigned = "counter_signed",
  Closed = "closed",
  AltQuoteSubmitted = "alt_quote_submitted",
  ApplicationInReview = "application_in_review",
}

export interface IHolding {
  account_id: string;
  account_display_id: string;
  investment_id: string;
  investment_display_id: string;
  asset_id: string;
  asset_display_id: string;
  sponsor: string;
  fund_name: string;
  currency: string | null;
  status: string;
  initial_commitment_investment_amount: number;
  remaining_commitment_investment_amount: number;
  requested_percentage: number;
}

export interface ILRAccount {
  id: string;
  liquidity_request_id: string;
  account_id: number;
  account_name: string;
  account_display_id: string;
  relationship_type: string;
  kyc_status: string;
  is_active: boolean;
  created_date: string;
  created_by_user_id: string;
  created_by: string;
  updated_date: string;
  updated_by_user_id: string;
  updated_by_user: string;
  is_test_data: boolean;
}

export interface ILRSharing {
  shared_with_account_id: string;
  shared_with_user_id: string;
  shared_with_user_name: string;
  shared_with_user_email: string;
  status: string;
  created_at: Date;
}

export type TConfirmAssetToIncludeItem = {
  key: string;
  assetName: string;
  valueInMyPortfolio: string;
};

export interface IFormattedCustomerInformationValue {
  op: string;
  path: string;
  value: string | number | boolean;
}

export interface IClientData {
  shared_with_account_id: string;
  shared_with_user_id: string;
  shared_with_user_name: string;
  shared_with_user_email: string;
  status: string;
}

export enum CustodyAccountPDFTypes {
  Redacted = "redacted",
  Unredacted = "unredacted",
  Raw = "raw",
}

export type TCustodyAccountPDFTypes = `${ CustodyAccountPDFTypes }`;

interface IPageInfo {
  index: number;
  size: number;
}

export interface IFetchLiquidityRequestsResponse {
  items: ILiquidityRequest[];
  page: IPageInfo;
  total: number;
}
