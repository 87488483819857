/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
import { FC, useEffect, useState } from "react";

import { useClientsSelector } from "domains/clients";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { Spinner } from "common/lib/components";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { InvestmentCharts, InvestmentTable } from "../PortfolioAnalytics";
import InvestmentFilters from "./InvestmentFilters";
import { useAppDispatch } from "store/store.hooks";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import "../portfolioManager.scss";
 
const defaultCharts = [
  {
    key: 0.6599821023858781,
    title: "Industry Sector",
    type: "pie",
    dataKey: "sector",
    data: [ { name: "Energy" } ],
  },
  {
    key: 0.8055311663241441,
    title: "Country",
    type: "pie",
    dataKey: "country",
    data: [ { name: "United States" } ],
  },
  {
    key: 0.8200278215549447,
    title: "Asset Class",
    type: "pie",
    dataKey: "asset_class",
    data:  [ { name: "Net_Other_Assets" } ],
  },
]

const PortfolioAnalyticsTab: FC = () => {
  const { chartData, isExportPDF } = useAltCustodySelector();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const { investments } = useAssetsHoldingsData();
  const { user } = useClientsSelector()
  const [ charts, setCharts ] = useState<any>(defaultCharts)
  const [ selectedFragment, setSelectedFragment ] = useState<any>()
  const [ rowSelection, setRowSelection ] = useState<any>(chartData.map(({ investment_entity_id, fund_entity_id }:any) => investment_entity_id + fund_entity_id))
  const [ searchInputValue, setSearchInputValue ] = useState<any>('')
  const [ currentTableData, setCurrentTableData ] = useState<any>(chartData)
  const [ currentChartData, setCurrentChartData ] = useState<any>(currentTableData)
  
  const dispatch = useAppDispatch()

  const handleSelectSnapshot = (params: any) => {
    const parsedParams = JSON.parse(params.snapshot_json)
    setSearchInputValue(parsedParams.searchInputValue)
    setCharts(parsedParams.charts)
    setRowSelection(parsedParams.selectedRowKeys || [])
    dispatch(setCurrentCustodyAccount(parsedParams.currentCustodyAccount))
  }

  const onRowSelectionChange = (newSelection?:any) => {
    if (newSelection) {

      const filteredData = newSelection.filter(({
        investment_entity_id, fund_entity_id,
      }: any) => (rowSelection.includes(investment_entity_id + fund_entity_id)))
      setCurrentChartData(filteredData)
    } else {
      const filteredData = currentTableData.filter(({
        investment_entity_id, fund_entity_id,
      }: any) => (rowSelection.includes(investment_entity_id + fund_entity_id)))
      setCurrentChartData(filteredData)
    }
  }

  const handleFilterCustodyAccount = (CA: any) => {
    
  }

  const handleSearch = () => {
    const filteredData = chartData.filter((item: any) => {
      return Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchInputValue.toLowerCase());
        }
        return false;
      });
    });
    if (filteredData && currentCustodyAccount) {
      const fiteredwithCa = filteredData.filter(({ custody_account_id }:any) => currentCustodyAccount.account_id === custody_account_id)
      setCurrentTableData(fiteredwithCa);
      // const newSelection = fiteredwithCa.map(({ investment_entity_id, fund_entity_id }:any) => investment_entity_id + fund_entity_id)
      onRowSelectionChange(fiteredwithCa)
      // setRowSelection(newSelection)
    } else {
      setCurrentTableData(filteredData);
      // const newSelection = filteredData.map(({ investment_entity_id, fund_entity_id }:any) => investment_entity_id + fund_entity_id)
      onRowSelectionChange(filteredData)
      // setRowSelection(newSelection)
    }
  };

  const setDefaultValues = () => {
    setCharts(defaultCharts)
    setRowSelection(chartData.map(({ investment_entity_id, fund_entity_id }:any) => investment_entity_id + fund_entity_id))
    dispatch(setCurrentCustodyAccount(null))
  }

  const setSliceFilter = (sliceValue: string) => {
    //setSearchInputValue(sliceValue)
    const filteredData = chartData.filter((item: any) => {
      return Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(sliceValue.toLowerCase());
        }
        return false;
      });
    });
    setRowSelection(filteredData.map(({ investment_entity_id, fund_entity_id }:any) => investment_entity_id + fund_entity_id))

   // handleSearch()
  }

  useEffect(() => {
    if (rowSelection) {
      onRowSelectionChange()
    }
  }, [ rowSelection ])

  useEffect(() => {
      handleSearch()
  }, [ searchInputValue ])

  useEffect(() => {
      handleSearch()
  }, [ currentCustodyAccount ])

  useEffect(() => {
    if (user) {
      dispatch(altCustodyApiReducer.endpoints.fetchSnapshots.initiate(user.account_id));
    }
  }, [ dispatch, user ])

  return (
    <Spinner
      wrapperClassName="investment-analytics-tab-spinner"
      spinning={ isExportPDF }
      tip="Generating PDF. Please wait"
    >
      <div className="portfolio-analytics-container" style={ isExportPDF ? { overflow: 'hidden' } : {} }>
        <InvestmentFilters
          handleSearch={ setSearchInputValue }
          charts={ charts }
          selectedRowKeys={ rowSelection }
          searchInputValue={ searchInputValue }
          handleFilterCustodyAccount={ handleFilterCustodyAccount }
          handleSelectSnapshot={ handleSelectSnapshot }
          setDefaultValues={ setDefaultValues }
        />
        <InvestmentCharts
          handleSliceFilter={ setSliceFilter }
          chartData={ currentChartData }
          charts={ charts }
          onChange={ setCharts }
          onChangeActiveChart={ setSelectedFragment }
        />
        <InvestmentTable
          tableData={ currentTableData }
          onRowSelectionChange={ (props:any) => setRowSelection(props) }
          rowSelection={ rowSelection }
          selectedFragment={ selectedFragment }
        />
      </div>
    </Spinner>
  )
};


export default PortfolioAnalyticsTab;

  // useEffect(() => {
  //    console.log(chartData)
  //    setChartData(data)
  // }, [ _chartData ]);

  // const currentSnapshotData: any = useMemo(
  //   () => ({
  //     selectedRowKeys,
  //     searchInputValue,
  //    // TODO: add custodyAccountId
  //     custodyAccountId: "",
  //     charts,
  //   }),
  //   [ searchInputValue, selectedRowKeys, charts ],
  // );

  // const handleTableChange: TableProps<(typeof chartData)[ 0 ]>["onChange"] =
  //  (pagination, filters, sorter, extra) => {
  //   const { currentDataSource } = extra;
  //   setCurrentTableDataSource(currentDataSource);
  // };

    // const handleSelectSnapshot = useCallback(
  //   (snapshot: ISnapshot | null) => {
  //     setCurrentSnapshot((prev) => {
  //       if (prev && !snapshot) {
  //         setSearchInputValue("");
  //         // setSelectedRowKeys(defaultSelectedRowKeys);
  //         generateInitialCharts({
  //           dataSource: chartsData,
  //           dataKeys: [ ChartDataKey.SECTOR, ChartDataKey.COUNTRY, ChartDataKey.PUBLIC_OR_PRIVATE ],
  //           shoudUpsert: true,
  //         });
  //       }

  //       return snapshot;
  //     });
  //   },
  //   [ currentSnapshot, chartsData ],
  // );

  // useEffect(() => {
  // console.log(searchInputValue)
  // const filteredData = chartData.filter((tableRecord: any) => {
  //   return Object.values(tableRecord).some((columnData) =>
  //     (columnData as any).toLowerCase().includes(searchInputValue.toLowerCase().trim()),
  //   );
  // });

  // setChartData(filteredData)
  //   // setChartData(filteredData);
  // }, [ searchInputValue ]);
/*
  useEffect(() => {
    if (!currentSnapshot) return;

    setSelectedRowKeys(currentSnapshot.data.selectedRowKeys);
    setSearchInputValue(currentSnapshot.data.searchInputValue);
    currentSnapshot.data.charts.forEach(({ key, dataKey, title, type, data }, index) => {
      return dispatch(
        addChart({
          newChart: {
            key,
            title,
            type,
            dataKey,
            data,
          },
          shouldUpsert: index === 0,
        }),
      );
    });
  }, [ currentSnapshot ]);
*/
