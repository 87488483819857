import { FC } from "react";

import { useTranslation } from "react-i18next";

import "common/assets/styles/utilities/spacing.scss";

import "./TypesAssetsConsidered.scss";

const TypesAssetsConsidered: FC = () => {
  const { t } = useTranslation("liquidity");

  const typesOfAssetsConsidered = [
    {
      word: t("Tooltips.Types of Assets.Non-traded REIT.Title"),
      description: t("Tooltips.Types of Assets.Non-traded REIT.Description"),
    },
    {
      word: t("Tooltips.Types of Assets.Non-traded BDC.Title"),
      description: t("Tooltips.Types of Assets.Non-traded BDC.Description"),
    },
    {
      word: t("Tooltips.Types of Assets.Equity in a single privately-held company.Title"),
      description: t("Tooltips.Types of Assets.Equity in a single privately-held company.Description"),
    },
    {
      word: t("Tooltips.Types of Assets.Direct Real Estate Investment.Title"),
      description: t("Tooltips.Types of Assets.Direct Real Estate Investment.Description"),
    },
    {
      word: t("Tooltips.Types of Assets.Direct interest in a natural resources asset.Title"),
      description: t("Tooltips.Types of Assets.Direct interest in a natural resources asset.Description"),
    },
    {
      word: t("Tooltips.Types of Assets.Co-investment.Title"),
      description: t("Tooltips.Types of Assets.Co-investment.Description"),
    },
  ];

  return (
    <div className="ben-tooltip-content-container">
      <h5>{t("Tooltips.Types of Assets.Title")}</h5>

      <ul>
        {typesOfAssetsConsidered.map((type) => (
          <li key={ type.word }>
            <span className="tooltip-bold-text">{type.word} - </span>
            <span className="tooltip-text">{type.description}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TypesAssetsConsidered;
