import axios from "axios";

import { IFeature } from "domains/features/shared/types";
import { formatFeaturesForReducer } from "domains/features/shared/utils/apiFormatter";
import { featuresApiReducer } from "domains/features/store/features.api.reducer";
import { getNoCacheHeaders } from "common/shared/services/api.headers/api.headers";

export class FeatureService {
  static readonly TIMEOUT = 60000;

  constructor() {
    this.fetchFeatures().then(
      (links) => {
        this.setFeatures(links);
      },
      () => {},
    );
  }

  setFeatures(features: IFeature[]) {
    featuresApiReducer.util.updateQueryData("fetchFeatures", undefined, () => features);
  }

  async fetchFeatures() {
    const host = window.location.hostname === "localhost" ? "https://baatest.bcpbenapp-dev.bankben.org" : "";
    const response: { features: string } = await axios.get(`${ host }/features.json`, {
      headers: {
        ...getNoCacheHeaders(),
      },
    });
    const features = formatFeaturesForReducer(response.features);

    return features;
  }
}

const featureService = new FeatureService();
export default featureService;
