import { FC } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, Select } from "common/lib/formik-antd";
import { assetTypes } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/constants";
import { useSelectOtherAssetFormValidationSchema } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/validationSchema";
import { AssetType } from "domains/liquidity/shared/constants";
import { TNonFundAsset } from "domains/liquidity/shared/types";
import { FormFooter, FormHeader, NavigationButtons } from "common/lib/components";
import CoInvestmentForm from "./forms/CoInvestmentForm";
import DirectRealInvestmentForm from "./forms/DirectRealEstateInvestmentForm";
import EquityInASinglePrivatelyHeldCompanyForm from "./forms/EquityInASinglePrivtelyHeldCompanyForm";
import DirectInterestInANaturalResourcesAssetForm from "./forms/DirectInterestInANaturalResourcesAssetForm";
import { useHandlers } from "./useHandlers";
import { useTrustbenLogs, useTrustbenSelector } from "domains/trustben";
import NonTradedREITorBDCForm from "./forms/NonTradedREITorBDCForm";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

const SelectOtherAssetForm: FC = () => {
  const { t } = useTranslation([ "liquidity" ]);

  const { validationSchema } = useSelectOtherAssetFormValidationSchema();
  const { onSubmit, onSelect } = useHandlers();
  const { sendLogs } = useTrustbenLogs();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { currentAsset } = useLiquiditySelector();

  return (
    <>
      {!isTrustbenAltQuote && <FormHeader hint={ t("Select Other Asset Info") } />}

      <div className="ben-form-container">
        <Formik
          initialValues={ {} as Partial<TNonFundAsset> }
          validateOnMount
          onSubmit={ onSubmit }
          validationSchema={ validationSchema }
        >
          {({ isValid, values, setFieldValue, setFieldTouched }) => (
            <Form layout="vertical" requiredMark="optional">
              <Form.Item name="asset_type" label={ t("Asset Type") } required>
                <Select
                  name="asset_type"
                  placeholder={ t("Select One", { ns: "common" }) }
                  onSelect={ () => onSelect(values, setFieldValue, setFieldTouched) }
                  onChange={ (value) => {
                    isTrustbenAltQuote &&
                      sendLogs({
                        what: "asset_type",
                        details: `asset_type:${ value }:${ currentAsset.audit_logs_id }`,
                      });
                  } }
                >
                  {assetTypes.map((type, i) => (
                    <Select.Option key={ i } value={ type.value }>
                      {type.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {(values.asset_type === AssetType.NonTradedREIT || values.asset_type === AssetType.NonTradedBDC) && (
                <NonTradedREITorBDCForm assetType={ values.asset_type } />
              )}

              {values.asset_type === AssetType.CoInvestment && <CoInvestmentForm />}

              {values.asset_type === AssetType.DirectRealEstateInvestment && (
                <DirectRealInvestmentForm { ...values } setFieldValue={ setFieldValue } />
              )}

              {values.asset_type === AssetType.PrivateSharesInSinglePrivatelyHeldCompany && (
                <EquityInASinglePrivatelyHeldCompanyForm />
              )}

              {values.asset_type === AssetType.DirectInterestsInNaturalResourcesAsset && (
                <DirectInterestInANaturalResourcesAssetForm />
              )}

              <NavigationButtons disabled={ !isValid } hideBack />
            </Form>
          )}
        </Formik>
      </div>

      {!isTrustbenAltQuote && <FormFooter>{t("Select Other Asset Footer")}</FormFooter>}
    </>
  );
};

export default SelectOtherAssetForm;
