import { Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";

import { CloseWindowOutlined, DownloadDocumentIcon, DeleteDocumentIcon } from "common/lib/components";
import { IDocument } from "domains/documents/shared/types";
import { apiService } from "common/shared";
import { getIconForFile } from "domains/documents/shared/utils";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { DocumentPasswordForm } from "./forms/DocumentPasswordForm";
import { useCustodialAgreement } from "common/shared-hooks";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

interface IProps {
  uploadProps: UploadProps;
  allDocuments: IDocument[];
}

export const useColumns = ({ uploadProps, allDocuments }: IProps) => {
  const { t } = useTranslation("liquidity");

  const { isCustodialAgreementActive, checkCustodialAgreement } = useCustodialAgreement();
  const { currentLiquidityRequest } = useLiquiditySelector();

  const columns = [
    {
      title: "Document",
      key: "assetName",
      width: "25%",
      render: () => (
        <div onClick={ checkCustodialAgreement } onKeyDown={ () => {} }>
          <Upload.Dragger { ...uploadProps } openFileDialogOnClick={ isCustodialAgreementActive }>
            <p>{t("Drag and drop files here")}</p>
          </Upload.Dragger>
        </div>
      ),
      onCell: (_: any, rowIndex: any) => ({
        rowSpan: rowIndex === 0 ? allDocuments?.length : 0,
      }),
      className: "ben-drug-n-drop-section",
    } as const,
    {
      key: "file_name",
      width: "25%",
      title: t("File Name"),
      dataIndex: "file_name",
      render: (file_name: string, document: IDocument) => (
        <div>
          {getIconForFile(document.file_name)}
          <p>{file_name}</p>
        </div>
      ),
      className: "ben-file-name-section",
    } as const,
    {
      key: "date_provided",
      width: "20%",
      title: t("Date Provided"),
      dataIndex: "date_provided",
      render: (date_provided: string, doc: IDocument) => {
        return <p>{doc.percent ? `${ t("Uploading...") } ${ doc.percent }%` : `${ getDateFromISO(date_provided) }`}</p>;
      },
      className: "ben-date-provided-section",
    } as const,
    {
      key: "document_password",
      width: "30%",
      title: t("Document Password, If Applicable"),
      dataIndex: "document_password",
      render: (document_password: string, document: IDocument) => {
        return <DocumentPasswordForm document={ document } />;
      },
      className: "ben-document-password-section",
    } as const,
    {
      key: "action",
      width: "7%",
      render: (document: IDocument) => (
        <DownloadDocumentIcon
          documentId={ document.id }
          accountId={ currentLiquidityRequest?.account_id ?? "" }
          iconClassName="ben-download-file-icon"
        />
      ),
      className: "ben-action-section",
    } as const,
    {
      key: "action",
      width: "5%",
      render: (document: IDocument) =>
        document.cancelToken ? (
          <CloseWindowOutlined onClick={ () => apiService.cancel(document.cancelToken) } />
        ) : (
          <DeleteDocumentIcon type="asset" documentId={ document.id } />
        ),
      className: "ben-action-section",
    } as const,
  ];

  return { columns };
};
