import { Flex, Skeleton } from "antd";

import "./MainMenuSkeleton.scss";

const MainMenuSkeleton = () => {
  return (
    <Flex className="main-menu-skeleton" gap="middle">
      {Array.from({ length: 6 }, (_, index) => (
        <Skeleton.Button key={ index } block size="small" className="button" active />
      ))}
    </Flex>
  );
};

export default MainMenuSkeleton;
