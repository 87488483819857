/* eslint-disable no-restricted-imports */
import { FC } from "react";

import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, Select } from "common/lib/formik-antd";
import { assetTypes } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/constants";
import { useSelectOtherAssetFormValidationSchema } from "domains/liquidity/components/SelectAssetsTabs/SelectOtherAsset/validationSchema";
import { AssetType } from "domains/liquidity/shared/constants";
import { FormHeader, NavigationButtons } from "common/lib/components";
import CoInvestmentForm from "./forms/CoInvestmentForm";
import DirectRealInvestmentForm from "./forms/DirectRealEstateInvestmentForm";
import EquityInASinglePrivatelyHeldCompanyForm from "./forms/EquityInASinglePrivtelyHeldCompanyForm";
import DirectInterestInANaturalResourcesAssetForm from "./forms/DirectInterestInANaturalResourcesAssetForm";
import { useHandlers } from "./useHandlers";
import NonTradedREITorBDCForm from "./forms/NonTradedREITorBDCForm";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { useAppDispatch } from "store/store.hooks";
import { setAssetsPendingCreation, setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { idUtils } from "common/shared";
import { URLs } from "common/lib/constants";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";

const SelectOtherAssetForm: FC = () => {
  const { t } = useTranslation([ "liquidity" ]);

  const { currentAsset } = useLiquiditySelector();
  const { isInvestmentEditing } = useAltCustodySelector();
  const { validationSchema } = useSelectOtherAssetFormValidationSchema();
  const { onSelect } = useHandlers();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const onSubmit = (formValues:any) => {
    dispatch(setCurrentAssetData(formValues))
    // dispatch(setAssetsPendingCreation({ ...formValues, asset_id: idUtils.uuidv4() }));
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/');
    pathParts.pop();
    const newPath = `${ pathParts.join('/') }${ AltCustodyAssetPages.InvestmentDetails }`;
    navigate(newPath);
  }

  return (
    <>
      <FormHeader hint={ t("Select Other Asset Info") } />

      <div className="ben-form-container">
        <Formik
          initialValues={ currentAsset }
          validateOnMount
          onSubmit={ onSubmit }
          validationSchema={ validationSchema }
        >
          {({ isValid, values, setFieldValue, setFieldTouched }) => (
            <Form layout="vertical" requiredMark="optional">
              <Form.Item name="asset_type" label={ t("Asset Type") } required>
                <Select
                  name="asset_type"
                  placeholder={ t("Select One", { ns: "common" }) }
                  onSelect={ () => onSelect(values, setFieldValue, setFieldTouched) }
                  disabled={ isInvestmentEditing }
                >
                  {assetTypes.map((type, i) => (
                    <Select.Option key={ i } value={ type.value }>
                      {type.title}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {(values.asset_type === AssetType.NonTradedREIT || values.asset_type === AssetType.NonTradedBDC) && (
                <NonTradedREITorBDCForm assetType={ values.asset_type } />
              )}

              {values.asset_type === AssetType.CoInvestment && <CoInvestmentForm />}

              {values.asset_type === AssetType.DirectRealEstateInvestment && (
                <DirectRealInvestmentForm { ...values } setFieldValue={ setFieldValue } />
              )}

              {values.asset_type === AssetType.PrivateSharesInSinglePrivatelyHeldCompany && (
                <EquityInASinglePrivatelyHeldCompanyForm />
              )}

              {values.asset_type === AssetType.DirectInterestsInNaturalResourcesAsset && (
                <DirectInterestInANaturalResourcesAssetForm />
              )}
              <NavigationButtons disabled={ !isValid } hideBack />
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default SelectOtherAssetForm;
