/* eslint-disable array-bracket-spacing */
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { FormHeader, NavigationButtons, Spinner } from "common/lib/components";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import RequiredDocumentsList from "domains/documents/components/RequiredDocumentsList";
import { useClientsSelector } from "domains/clients";
import { IDocument } from "domains/documents/shared/types";
import { useScreen } from "common/shared-hooks";
import { DocumentsUpload } from "v2/features/documents";
import { URLs } from "common/lib/constants";

import "./assetDocumentsUpload.scss";

const AssetPortfolioDocumentsUpload = () => {
  const { user } = useClientsSelector();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const [ isLoading, setIsLoading ] = useState(false); 
  const [ documentList, setDocumentList ] = useState<any>([]);
  const { data: documents = [] } = documentsApiReducer.useFetchDocumentsByIdQuery(user ? user.account_id : '', {
    skip: !user?.account_id,
  });

  const documentProps = {
    document_type: "person_account",
    reference_type: "account",
    documentList,
    account_id: user?.account_id,
    setDocumentList,
  }

  const onSkip = () => {
    navigate(URLs.PROTECTED.DASHBOARD)
  }

  useEffect(() => {
    if(pathname.includes('altquote')) {
      onSkip()
    }
  }, [ ]);

  useEffect(() => {
    const formattedDocuments = [...documents].map((doc) => ({
      doc,
      file: {},
      uploadStatus: "uploaded",
    }));
    const pendingList = documentList.filter(
      ({ uploadStatus }:any) => uploadStatus === "pending",
    );
    const documentMap = new Map();
    const generateKey = (doc:any) => `${ doc.file_name }`;
    formattedDocuments.forEach((doc) => {
      documentMap.set(generateKey(doc.doc), doc);
    });
    pendingList.forEach((pendingDoc:any) => {
      const key = generateKey(pendingDoc.doc);
      if (!documentMap.has(key)) {
        documentMap.set(key, pendingDoc);
      }
    });
    const mergedList = Array.from(documentMap.values());
    setDocumentList(mergedList);
    setIsLoading(false);
  }, [documents]);
  
  
  if (isLoading) {
    return <Spinner />;
  }
  
  return (
    <>
        <div className="ben-upload-documents-form">
          <RequiredDocumentsList isCollapseActiveByDefault={ false } />
          <DocumentsUpload documentProps={ documentProps } />
        </div>
    </>
  );
};

export default AssetPortfolioDocumentsUpload;
