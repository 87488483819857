import { FC, useState, useMemo, useLayoutEffect, ChangeEvent, useEffect } from "react";

import { Modal, Table, Checkbox, Input } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { maxLengthText } from "common/shared/constants/validationConstants";
import { formatCurrencyWithoutFractional, formatId } from "common/shared/helpers";
import { CircleX } from "common/lib/components/Icons";
import { URLs } from "common/lib/constants";
import { Button, Spinner } from "common/lib/components";
import { AssetType } from "domains/liquidity/shared/constants";
import { TAssetType, TConfirmAssetToIncludeItem } from "domains/liquidity/shared/types";
import { setAssetsPendingCustodyAccount, setCurrentAssetData, setIsLA, setSelectedAssetsForLiquidity } from "domains/liquidity/store/liquidity.reducer";
import { useClientsSelector } from "domains/clients";
import { RelationshipTypes } from "domains/clients/shared/types";
import { useTrustbenSelector } from "domains/trustben";
import { useAppDispatch } from "store/store.hooks";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { clientsApiReducer } from "domains/clients/store/clients.api.reducer";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { altQuotesApiReducer } from "domains/altQuotes/store/altQuotes.api.reducer";
import { authApiReducer } from "domains/authentication/store/auth.api.reducer";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { formatLiquidityRequestBodyForRequest } from "domains/liquidity/shared/utils/apiFormatter";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";

import "./RequestFormalProposalModal.scss";

interface IProps {
  isVisible: boolean;
  handleCancel: any;
}

const RequestFormalProposalModal: FC<IProps> = ({ isVisible, handleCancel }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const { t } = useTranslation([ "altQuotes", "common" ]);
  const dispatch = useAppDispatch();
  const { LRId: id } = useParams();
  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation()
  const LRId = sessionStorage.getItem("LIQUIDITY_REQUEST_ID") || id;
  const { investments } = useAssetsHoldingsData();
  const [ createLRAsset ] = liquidityApiReducer.useCreateLRAssetMutation({ fixedCacheKey: "create-lr-asset" })
  const [ createLiquidityRequest ] = liquidityApiReducer.useCreateLiquidityRequestMutation({ fixedCacheKey: "create-liquidity-request" })

  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const { isAdvisor, currentClient, user } = useClientsSelector();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { data: documents = [] } = documentsApiReducer.useFetchDocumentsByIdQuery(user ? user.account_id : '', {
    skip: !user?.account_id,
  });
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { data: clients } = clientsApiReducer.useFetchUserClientsQuery(
    user && isAdvisor ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );
  const { data: assets = [], isFetching: isFetchingLRAssets } = liquidityApiReducer.useFetchLRAssetsQuery(
    LRId ?? skipToken,
  );
  const [
    convertAltQuoteToLiquidityApplication,
    { data: convertedAltQuoteResponse, isLoading: isConvertingAltQuote, isSuccess: isAltQuoteConverted, reset },
  ] = altQuotesApiReducer.useConvertAltQuoteToLiquidityApplicationMutation({
    fixedCacheKey: "convert-altquote-to-liquidity-application",
  });
  const [ logout ] = authApiReducer.useLogoutMutation();

  const [ isCheckboxChecked, setIsCheckboxChecked ] = useState(false);
  const [ inputValue, setInputValue ] = useState("");
  const [ isLoading, setIsLoading ] = useState(false);

  const liquidityRequest = liquidityRequests?.find((lr) => lr.id === LRId);
  const clientId = liquidityRequest?.accounts?.find(
    (account) => account.relationship_type === RelationshipTypes.Client,
  )?.account_id;
  const client =
    clients?.items?.find((client) => client.account_id === formatId("ACC-", clientId ?? "")) || currentClient;
  const activeCustodyAccounts = useMemo(
    () =>
      custodyAccounts?.filter(
        (custodyAccount) =>
          custodyAccount.account_status !== CustodyAccountStatus.Closed &&
          custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
          custodyAccount.account_status !== CustodyAccountStatus.Duplicate,
      ),
    [ custodyAccounts ],
  );
  const funds = useMemo(
    () =>
      assets?.filter(
        (asset) =>
          asset.asset_type !== undefined &&
          (asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ),
    [ assets?.length ],
  );
  const fundAssets = inputValue
    ? funds.filter((fund) => {
        return fund.asset_name.toLowerCase().includes(inputValue.toLowerCase());
      })
    : funds;
  const [ chosenAssets, setChosenAssets ] = useState<TAssetType[]>([]);
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<string[]>([]);

  useLayoutEffect(() => {
    setSelectedRowKeys(Object.keys(funds));
    setChosenAssets(Object.values(funds));
  }, [ funds ]);

  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onSelect: (record: TConfirmAssetToIncludeItem) => {
      const selectedRowKeysCopy = [ ...selectedRowKeys ];
      if (selectedRowKeysCopy.indexOf(record.key) >= 0) {
        selectedRowKeysCopy.splice(selectedRowKeysCopy.indexOf(record.key), 1);
      } else {
        selectedRowKeysCopy.push(record.key);
      }

      const chosenAssets = fundAssets.filter((_, index) => selectedRowKeysCopy.some((key: string) => +key === index));
      setChosenAssets(chosenAssets);
      setSelectedRowKeys(selectedRowKeysCopy);
    },
    onSelectAll: (selected: boolean) => {
      if (selected) {
        setSelectedRowKeys(Object.keys(fundAssets));
        setChosenAssets(Object.values(fundAssets));
      } else {
        setSelectedRowKeys([]);
        setChosenAssets([]);
      }
    },
  };

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsCheckboxChecked(e.target.checked);
  };

  const connectAssets = async (assetsToCreate: any, LRId: string, reasonForSeekingLiquidity: string) => {
    const changes: any = {
      reason_for_seeking_liquidity: reasonForSeekingLiquidity,
      status: documents.length ? 'in_document_review' : 'pending_asset_statements',
    }

    for (const asset of assetsToCreate) {
        try {
            const assetValues = { ...asset };
            dispatch(setCurrentAssetData(assetValues)); 
            dispatch(setAssetsPendingCustodyAccount(assetValues.asset_id))
            await createLRAsset(LRId)
            await updateLiquidityRequest({ LRId, changes })
        } catch (error) {
            console.error("Error al crear asset:", error);
        }
      }
  };

  const handleContinueModal = async () => {
    setIsLoading(true)
    if (!liquidityRequest) return;

    if (isTrustbenAltQuote) {
      logout();
      navigate(URLs.AUTH.SIGN_IN, { state: { LRId }, replace: true });
      return;
    }

    const selectedAssets = chosenAssets.map(({ asset_id }) => {
      const investment = investments?.find(({ asset_id: inv_asset_id }) => asset_id === inv_asset_id )
      return investment
    })

    dispatch(setSelectedAssetsForLiquidity(selectedAssets.map((inv) => inv?.id)))
    if (isCheckboxChecked) {
      navigate(URLs.PROTECTED.MY_APPLICATIONS + 'new-liquidity-request?confirmAssets=true&reason=' + liquidityRequest.reason_for_seeking_liquidity);
    } else {
      const applicationType = 'liquidity_request'
      if (user) {
        formatLiquidityRequestBodyForRequest(applicationType, user)
        const assetsToCreate: any = selectedAssets

        const LRId = await createLiquidityRequest({ isLA: true });
        if (LRId.data) {
          await connectAssets(assetsToCreate, LRId.data, liquidityRequest.reason_for_seeking_liquidity)
          navigate(URLs.PROTECTED.MY_APPLICATIONS + 'new-liquidity-request' + AltCustodyAssetPages.AssetDocuments);
        }

      }

    }
    setIsLoading(false)
  };

  const assetsToIncludeColumns = [
    {
      title: t("Request Formal Proposal Modal.Table Columns.0"),
      dataIndex: "assetName",
      key: "assetName",
      width: "64.5%",
      align: "left",
    } as const,
    {
      title: t("Request Formal Proposal Modal.Table Columns.1"),
      dataIndex: "valueInMyPortfolio",
      key: "valueInMyPortfolio",
      width: "30%",
      align: "right",
    } as const,
  ];

  const fundDataSource = fundAssets?.map((asset, i) => {
    const { asset_name, current_net_asset_value } = asset;
    return {
      key: String(i),
      assetName: asset_name,
      valueInMyPortfolio: formatCurrencyWithoutFractional(current_net_asset_value),
    };
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleCleanInput = () => {
    setInputValue("");
  };

  // useEffect(() => {
  //   if (!isAltQuoteConverted) return;

  //   const LRId = convertedAltQuoteResponse;

  //   // Fetch liquidity request and accounts data after successful conversion
  //   dispatch(liquidityApiReducer.endpoints.fetchLiquidityRequest.initiate(LRId!));
  //   dispatch(liquidityApiReducer.endpoints.fetchLRAccounts.initiate(LRId!));
  //   dispatch(setIsAltQuoteTab(false));
  //   dispatch(setIsLA(true));

  //   if (isCheckboxChecked) {
  //     navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.SelectAssets);
  //   } else {
  //     dispatch(setCurrentCustodyAccount(null));
  //     activeCustodyAccounts?.length
  //       ? navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccount)
  //       : navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.CustodyAccountAgreement);
  //   }

  //   sessionStorage.removeItem("LIQUIDITY_REQUEST_ID");

  //   reset();
  // }, [ isAltQuoteConverted ]);

  return (
    <Modal
        afterClose={ () => setInputValue("") }
        className="ben-modal-request-formal-proposal"
        open={ isConvertingAltQuote || isVisible }
        width={ 725 }
        onCancel={ handleCancel }
        onOk={ handleContinueModal }
        footer={ [
          <Button disabled={ isConvertingAltQuote || isLoading } onClick={ handleCancel } key="back">
            {t("Cancel", { ns: "common" })}
          </Button>,
          <Button disabled={ isConvertingAltQuote || isLoading } onClick={ handleContinueModal } type="primary" key="continue">
            {t("Continue", { ns: "common" })}
          </Button>,
        ] }
    >
      <Spinner spinning={ isLoading }>
        <div className="ben-modal-request-formal-proposal-table">
          <h1>
            {isAdvisor ? t("Request Formal Proposal Modal.Advisor Title") : t("Request Formal Proposal Modal.User Title")}
          </h1>
          {isAdvisor ? (
            <Input
              maxLength={ maxLengthText }
              value={ inputValue }
              addonBefore={ "Search" }
              name="client_name"
              className="ben-small ben-search ben-modal-request-formal-proposal-input"
              onChange={ handleChange }
              suffix={ inputValue ? <CircleX onClick={ handleCleanInput } /> : <span /> }
            />
          ) : (
            <p>
              {t("Request Formal Proposal Modal.Text.0")}
              <br />
              {t("Request Formal Proposal Modal.Text.1")}
            </p>
          )}
          <Table
            loading={ isConvertingAltQuote || isFetchingLRAssets }
            rowSelection={ { type: "checkbox", ...rowSelection } }
            columns={ assetsToIncludeColumns }
            dataSource={ isFetchingLRAssets ? [] : fundDataSource }
            pagination={ false }
            footer={ () => (
              <Checkbox className="ben-modal-request-formal-proposal-table-checkbox" onChange={ onCheckboxChange }>
                {t("Request Formal Proposal Modal.Checkbox Text")}
              </Checkbox>
            ) }
          >
          </Table>
        </div>
      </Spinner>
    </Modal>
  );
};

export default RequestFormalProposalModal;
