import { useCallback, useState, FC, useEffect, useRef } from "react";

import { flushSync } from "react-dom";
import { CheckOutlined } from "@ant-design/icons";
import { InputRef } from "antd";
import { Formik, FormikProps, FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Form, Input } from "common/lib/formik-antd";
import { EditOutlined, Spinner, Button } from "common/lib/components";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { IDocument } from "domains/documents/shared/types";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

import "./DocumentPasswordForm.scss";

interface IProps {
  document: IDocument;
  accountId?: string
}

export const DocumentPasswordForm: FC<IProps> = ({ document, accountId }) => {
  const { t } = useTranslation("authentication");

  const { data: documentPassword, isFetching: isFetchingDocumentPassword } =
    documentsApiReducer.useFetchDocumentPasswordQuery(
      accountId && !document.cancelToken
        ? { documentId: document.id, accountId }
        : skipToken,
    );
  const [ setDocumentPassword, { isLoading: isSettingDocumentPassword } ] =
    documentsApiReducer.useSetDocumentPasswordMutation();
  const { uploadingDocuments } = useDocumentSelector();

  const [ initialValues, setInitialValues ]: [any, any] = useState(null);
  const [ isDisabled, setIsDisabled ] = useState(true);

  const inputRef = useRef<InputRef>(null);

  const isDocumentUploading = !!uploadingDocuments.find((uploading_document) => uploading_document.id === document.id);
  const isLoaderDisplayed = isFetchingDocumentPassword && !isSettingDocumentPassword;

  useEffect(() => {
    setInitialValues({
      password: documentPassword,
    });
  }, [ documentPassword ]);

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      if (!accountId) return;

      setDocumentPassword({
        documentId: document.id,
        accountId,
        password: values.password,
      });
    },
    [ document.id, accountId ],
  );

  return (
    <>
      <Formik initialValues={ initialValues } onSubmit={ () => {} } enableReinitialize>
        {({ values }: FormikProps<typeof initialValues>) => {
          return (
            <Form className="ben-set-document-password-form">
              <Form.Item name="password">
                <Spinner spinning={ isLoaderDisplayed }>
                  <Input.Password
                    maxLength={ 250 }
                    ref={ inputRef }
                    name="password"
                    type="password"
                    disabled={ isDisabled }
                    placeholder={ t("Password") }
                    className="small ben-small ben-w-95"
                    suffix
                    onBlur={ (e) => {
                      e.target.type = "password";
                      if (values.password !== initialValues.password) {
                        handleSubmit(values);
                        setInitialValues(values);
                      }
                      setIsDisabled(true);
                    } }
                  />
                </Spinner>
              </Form.Item>
              {!isDisabled ? (
                <Button htmlType="button" type="text" onClick={ () => {} } icon={ <CheckOutlined /> } />
              ) : (
                <Button
                  icon={ <EditOutlined onClick={ () => {} } /> }
                  htmlType="button"
                  loading={ isSettingDocumentPassword || isDocumentUploading || isLoaderDisplayed }
                  type="text"
                  onClick={ () => {
                    flushSync(() => setIsDisabled(false));
                    inputRef.current!.focus();
                  } }
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
