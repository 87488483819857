/* eslint-disable max-len */
import { Fragment } from "react";

import { Table, Col, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { FilePdfOutlined } from "@ant-design/icons";

import { InfoFilled, Spinner } from "common/lib/components";
import { useScreen } from "common/shared-hooks";
import { DocumentGridStatus, IDocumentGridData } from "domains/documents/shared/types";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import NonFundAssetsTable from "v2/features/assets/addAsset/reviewAssets/NonFundAssetsTable/NonFundAssetsTable";

import "./DocumentsStatusTable.scss";

const DocumentsStatusTable = ({ nonFundedAssets = [], documentGridData = [], isFetchingDocumentGridData }: any) => {
  const { t } = useTranslation("myApplications");
  const { mobile } = useScreen();
  const [ fetchDocumentUrlByDocID, { data: documentUrlData, isFetching: isFetchingDocumentUrlData } ] =
    documentsApiReducer.useLazyFetchDocumentUrlByDocIDQuery();
  const fundColumnsTitles = [
    t(`Governing Documents of the Fund (typically, LPA with amendments)`),
    t("Most Recent Annual Fund Audit Report & Financial Statements"),
    t("Latest Two Fund Reports & Unaudited Financial Statements (typically quarterly)"),
    t("Latest Two Investors Capital Account Balance Statements (typically quarterly)"),
    t("Ad hoc Documents, if applicable"),
    t("Notes"),
  ];

  const contentOne = (
    <div style={ { width: "40em" } }>
      <p>
        The Limited Partnership Agreement is a legal document defining the terms of the fund or partnership. There may
        have also been subsequent amendments that impact the terms of the original LPA. The client should submit all
        amendments in addition to the original agreement. In the case of an LLC, an Operating Agreement will suffice.
      </p>
      <a
        href="https://benbcp-baapreprod-partner-documents.s3.amazonaws.com/default/Example-Fund-LPA.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <FilePdfOutlined style={ { fontSize: "40px" } } />
        <b>Example LP Capital Statment.pdf</b>
      </a>
    </div>
  );

  const contentTwo = (
    <div style={ { width: "40em" } }>
      <p>
        Unaudited Financials include a fund balance sheet, income statement and cash flow statement as well as a list of
        investments in the funds and the fair value that they were held at for the period. Fund reports provide
        commentary on the investments held within the fund.
      </p>

      <a
        href="https://benbcp-baapreprod-partner-documents.s3.amazonaws.com/default/Example-Annual-Unaudited-Financial-Statement.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <FilePdfOutlined style={ { fontSize: "40px" } } />
        <b>Example Annual Unaudited Financial Statement.pdf</b>
      </a>
    </div>
  );

  const contentThree = (
    <div style={ { width: "40em" } }>
      <p>Explicitly says “Audited” on the title page and includes a statement from the Fund’s auditor.</p>

      <a
        href="https://benbcp-baapreprod-partner-documents.s3.amazonaws.com/default/Example-Quarterly-Financial-Statement.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <FilePdfOutlined style={ { fontSize: "40px" } } />
        <b>Example Quarterly Financial Statement.pdf</b>
      </a>
    </div>
  );

  const contentFour = (
    <div style={ { width: "40em" } }>
      <p>
        Provided by the fund to the investor to show the value of the investor’s interest at a point in time. This could
        also include the commitment the investor initially made and the remaining liability to the investor (Unfunded
        Commitment).
      </p>

      <a
        href="https://benbcp-baapreprod-partner-documents.s3.amazonaws.com/default/Example-Audited-Financial-Statement.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <FilePdfOutlined style={ { fontSize: "40px" } } />
        <b>Example Audited Financial Statement.pdf</b>
      </a>
    </div>
  );

  const contentFive = (
    <div style={ { width: "40em" } }>
      <p>Any ad hoc or not regularly sent letters/reports from the last 6 months.</p>
    </div>
  );

  const fundColumns = [
    {
      title: "Fund Name",
      dataIndex: "fund_name",
      key: "fund_name",
      align: "left",
      width: "20%",
    } as const,
    {
      //title: fundColumnsTitles[ 0 ],
      title: () => (
        <Popover content={ contentOne }>
          {fundColumnsTitles[ 0 ]}
          <InfoFilled style={ { marginLeft: "5px" } } />
        </Popover>
      ),
      //dataIndex: "governing_documents_status",
      key: "governing_documents_status",
      align: "center",
      render: (value: IDocumentGridData) =>
        getRenderDesktopOutput(
          value.governing_documents_document_ids,
          value.governing_documents_status,
          value.account_id,
        ),
    } as const,
    {
      title: () => (
        <Popover content={ contentFour }>
          {fundColumnsTitles[ 1 ]}
          <InfoFilled style={ { marginLeft: "5px" } } />
        </Popover>
      ),
      // dataIndex: "annual_fund_and_financial_statement_status",
      key: "annual_fund_and_financial_statement_status",
      align: "center",
      render: (value: IDocumentGridData) =>
        getRenderDesktopOutput(
          value.annual_fund_and_financial_statement_document_ids,
          value.annual_fund_and_financial_statement_status,
          value.account_id,
        ),
    } as const,
    {
      title: () => (
        <Popover content={ contentTwo }>
          {fundColumnsTitles[ 2 ]}
          <InfoFilled style={ { marginLeft: "5px" } } />
        </Popover>
      ),
      //dataIndex: "fund_reports_and_unaudited_financial_statements_status",
      key: "fund_reports_and_unaudited_financial_statements_status",
      align: "center",
      render: (value: IDocumentGridData) =>
        getRenderDesktopOutput(
          value.fund_reports_and_unaudited_financial_statements_document_ids,
          value.fund_reports_and_unaudited_financial_statements_status,
          value.account_id,
        ),
    } as const,
    {
      title: () => (
        <Popover content={ contentThree }>
          {fundColumnsTitles[ 3 ]}
          <InfoFilled style={ { marginLeft: "5px" } } />
        </Popover>
      ),
      //dataIndex: "balance_sheet_status",
      key: "balance_sheet_status",
      align: "center",
      render: (value: IDocumentGridData) =>
        getRenderDesktopOutput(value.balance_sheet_document_ids, value.balance_sheet_status, value.account_id),
    } as const,
    {
      title: () => (
        <Popover content={ contentFive }>
          {fundColumnsTitles[ 4 ]}
          <InfoFilled style={ { marginLeft: "5px" } } />
        </Popover>
      ),
      //dataIndex: "ad_hoc_documents_status",
      key: "ad_hoc_documents_status",
      align: "center",
      render: (value: IDocumentGridData) =>
        getRenderDesktopOutput(value.ad_hoc_documents_document_ids, value.ad_hoc_documents_status, value.account_id),
    } as const,
    {
      title: fundColumnsTitles[ 5 ],
      dataIndex: "notes",
      key: "notes",
      align: "center",
    } as const,
  ];

  const onDocClick = (documentIds: string, accountId: string) => {
    fetchDocumentUrlByDocID({ accountId, documentIds });
  };

  const getRenderDesktopOutput = (docids: string, status: string, acc_id: string) => {
    let docUrlData = documentUrlData;
    const doc = (
      <div style={ { width: "20em" } }>
        {isFetchingDocumentUrlData ? (
          "Loading..."
        ) : docUrlData && docUrlData.length > 0 ? (
          docUrlData.map((doc) => (
            <Fragment key={ doc.document_id }>
              <a href={ doc.url } rel="noreferrer" style={ { color: "#055073", wordBreak: "break-all" } }>
                <FilePdfOutlined style={ { fontSize: "20px", color: "#055073" } } />
                <b>
                  {doc.file_name}
                  <br />
                </b>
              </a>
            </Fragment>
          ))
        ) : (
          <span style={ { color: "red" } }>Failed to find document </span>
        )}
      </div>
    );

    switch (status) {
      case DocumentGridStatus.Received:
        return {
          props: {
            className: "ben-document-status-table-cell-received",
          },
          children:
            (status === "received" && docids === "") || docids === null ? (
              <Col>
                <Popover content={ "No Documents Found" } trigger="click">
                  {" "}
                  <span>
                    <span style={ { width: "20em" } }> Received</span>
                    <FilePdfOutlined style={ { fontSize: "20px", color: "#055073" } } />
                  </span>
                </Popover>
              </Col>
            ) : (
              <Col>
                <Popover content={ doc } trigger="click"> 
                  {" "}
                  <span onClick={ () => onDocClick(docids, acc_id) }>
                    <span style={ { width: "20em" } }> Received</span>
                    <FilePdfOutlined style={ { fontSize: "20px", color: "#055073" } } />
                  </span>
                </Popover>
              </Col>
            ),
        };

      case DocumentGridStatus.NotNeeded:
        return {
          props: {
            className: "ben-document-status-table-cell-received",
          },
          children: "Not Needed",
        };

      case DocumentGridStatus.PendingReview:
        return {
          props: {
            className: "ben-document-status-table-cell-pending-review",
          },
          children: "Pending Review",
        };

      case DocumentGridStatus.PartiallyReceived:
        return {
          props: {
            className: "ben-document-status-table-cell-partially-received",
          },
          children: "Partially Received",
        };

      case DocumentGridStatus.NotReceived:
        return {
          props: {
            className: "ben-document-status-table-cell-not-received",
          },
          children: <span style={ { width: "20em" } }> Not Received</span>,
        };
    }
  };


  const fundDataSource: IDocumentGridData[] | any = documentGridData?.map((asset: IDocumentGridData) => ({
    key: asset.asset_id,
    account_id: asset.account_id,
    fund_name: asset.asset_name,
    governing_documents_status: asset.governing_documents_status ?? "",
    governing_documents_document_ids: asset.governing_documents_document_ids ?? "",
    annual_fund_and_financial_statement_status: asset.annual_fund_and_financial_statement_status ?? "",
    annual_fund_and_financial_statement_document_ids: asset.annual_fund_and_financial_statement_document_ids ?? "",
    fund_reports_and_unaudited_financial_statements_status:
      asset.fund_reports_and_unaudited_financial_statements_status ?? "",
    fund_reports_and_unaudited_financial_statements_document_ids:
      asset.fund_reports_and_unaudited_financial_statements_document_ids ?? "",
    balance_sheet_status: asset.balance_sheet_status ?? "",
    balance_sheet_document_ids: asset.balance_sheet_document_ids ?? "",
    ad_hoc_documents_document_ids: asset.ad_hoc_documents_document_ids ?? "",
    ad_hoc_documents_status: asset.ad_hoc_documents_status ?? "",
    notes: asset.notes,
  }));

  return (
    <>
      {!mobile && (
        <>
          <Table
            loading={ isFetchingDocumentGridData }
            columns={ fundColumns }
            dataSource={ fundDataSource }
            pagination={ false }
            className="ben-documents-status-table"
            scroll={ { y: 250 } }
          />
          <br></br>
          <br></br>
          <NonFundAssetsTable
            title={ `` }
            nonFundAssets={ nonFundedAssets as any }
          />
        </>
      )}
    </>
  );
};

export default DocumentsStatusTable;
