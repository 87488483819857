/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from "react";

import CustodyAccountPicker from "domains/altCustody/components/CustodyAccountPicker";
import ExportPDFButton from "domains/altCustody/components/ExportPDFButton";
import SearchBar from "domains/altCustody/components/SearchBar";
import SnapshotsButtonModal from "domains/altCustody/components/SnapshotsButtonModal";
import SnapshotsFilter from "domains/altCustody/components/SnapshotsFilter";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import "../portfolioManager.scss";

const InvestmentFilters = (props:any) => {
  const { handleSearch, searchInputValue, selectedRowKeys, charts,
    handleSelectSnapshot, setDefaultValues, handleFilterCustodyAccount } = props;
  const { currentCustodyAccount } = useCustodyAccountsSelector();

  const currentSnapshotData: any = {
    selectedRowKeys,
    searchInputValue,
    handleFilterCustodyAccount,
    charts,
    currentCustodyAccount,
  }

  useEffect(() => {
    handleSearch( searchInputValue )
  }, [ searchInputValue ])

  return (
    <section className="investment-filters-section">
        <div className="row">
          <p style={ { margin: 0 } }>Review, analyze and create custom Snapshots of the underlying holdings in your Alternative Asset Funds</p>
          <div className="row" style={ { minWidth: 300 } }>
            <SearchBar searchInputValue={ searchInputValue } handleSearch={ handleSearch } />
          </div>
        </div>
        <div className="row" style={ { alignItems: 'flex-end' } }>
          <div className="row">
            <CustodyAccountPicker />
          </div>
          <div className="row" style={ { flex: 'unset' } }>
            <SnapshotsButtonModal currentSnapshotData={ currentSnapshotData } />
            <SnapshotsFilter handleSelectSnapshot={ handleSelectSnapshot } reset={ setDefaultValues }/>
            <ExportPDFButton />
          </div>
        </div>
    </section>
  );
};

export default InvestmentFilters;
