import { useCallback, useState } from "react";

import { toPng } from "html-to-image";
import { Document, Page, Text, Image, View, pdf, StyleSheet } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "store/store.hooks";
import { setIsExportPDF } from "domains/altCustody/store/altCustody.reducer";
import { Button } from "common/lib/components";
import { dateFormat } from "common/shared/constants/validationConstants";

import "./ExportPDFButton.scss";

const CHARTS_LIST_CONTAINER_ID = "charts-list-container";
const INVESTMENT_ANALYTICS_TABLE_ID = "investment-analytics-table";
const ADD_CHART_CONTAINER_CLASSNAME = ".add-chart-container";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    color: "#055073",
    textAlign: "center",
    marginBottom: 20,
  },
  table: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid #ddd",
    alignItems: "stretch",
    minHeight: "20px",
    breakInside: "avoid",
  },
  tableHeader: {
    fontWeight: "bold",
    backgroundColor: "#f5f5f5",
    padding: "8px",
    textAlign: "left",
    flex: 1,
    fontSize: "10px",
  },
  tableCell: {
    padding: "8px",
    textAlign: "left",
    flex: 1,
    fontSize: "10px",
  },
  image: {
    width: "100%",
    height: "auto",
  },
});

const ExportPDFButton = () => {
  const { t } = useTranslation("");
  const dispatch = useAppDispatch();
  const [ isExporting, setIsExporting ] = useState(false);

  const isRowEmpty = (row: any) => {
    return row.every((cell: any) => !cell || cell.trim() === "");
  };

  const extractTableData = (tableNode: any) => {
    const headers = Array.from(tableNode.querySelectorAll("th"))
      .slice(1)
      .map((th: any) => th.innerText);

    const rows = Array.from(tableNode.querySelectorAll("tr"))
      .map((tr: any) =>
        Array.from(tr.querySelectorAll("td"))
          .slice(1)
          .map((td: any, index) => {
            if (index === 3) {
              const value = td.innerText.replace(/[^0-9.]/g, "");
              if (value) {
                return `$${ parseFloat(value).toFixed(2) }`;
              }
            }
            return td.innerText;
          }),
      )
      .filter((row: any) => !isRowEmpty(row));

    return { headers, rows };
  };

  const handleExportPDF = useCallback(async () => {
    const chartsNode = document.getElementById(CHARTS_LIST_CONTAINER_ID);
    const tableNode = document.getElementById(INVESTMENT_ANALYTICS_TABLE_ID);
    const addChartButton = document.querySelector(ADD_CHART_CONTAINER_CLASSNAME);

    if (!chartsNode || !tableNode) return;

    setIsExporting(true);
    dispatch(setIsExportPDF(true));

    try {
      await new Promise((resolve) => setTimeout(resolve, 200));

      addChartButton?.classList.add("add-chart-button-pdf");
      chartsNode.classList.add("charts-pdf");
      tableNode.setAttribute("data-table", "pdf");

      const chartsPng = await toPng(chartsNode, {
        filter: (domNode) => {
          const exclusionClasses = [ "add-chart-container", "edit-chart-button" ];
          return !exclusionClasses.some((classname) => domNode.classList?.contains(classname));
        },
        pixelRatio: 2,
      });

      const { headers, rows } = extractTableData(tableNode);

      addChartButton?.classList.remove("add-chart-button-pdf");
      chartsNode.classList.remove("charts-pdf");
      tableNode.removeAttribute("data-table");

      const blob = await pdf(
        <Document>
          <Page size="A4" orientation="landscape" style={ styles.page }>
            <Text style={ styles.title }>{t("Investment Analytics Report")}</Text>
            <View>
              <Image src={ chartsPng } style={ styles.image } />

              <View style={ styles.table }>
                <View style={ styles.tableRow }>
                  {headers.map((header, index) => (
                    <Text key={ index } style={ styles.tableHeader }>
                      {header}
                    </Text>
                  ))}
                </View>

                {rows.map((row, rowIndex) => (
                  <View key={ rowIndex } style={ styles.tableRow } wrap={ false }>
                    {row.map((cell, cellIndex) => (
                      <Text key={ cellIndex } style={ styles.tableCell } wrap>
                        {cell}
                      </Text>
                    ))}
                  </View>
                ))}
              </View>
            </View>
          </Page>
        </Document>,
      ).toBlob();

      saveAs(blob, `Investment Analytics ${ dayjs().format(dateFormat) }`);
    } catch (error) {
      console.error("Error exporting PDF:", error);
      alert("An error occurred while exporting the PDF. Please try again.");
    } finally {
      setIsExporting(false);
      dispatch(setIsExportPDF(false));
    }
  }, [ dispatch, t ]);

  return (
    <Button type="primary" onClick={ handleExportPDF } disabled={ isExporting }>
      {isExporting ? t("Exporting...") : t("Export PDF")}
    </Button>
  );
};

export default ExportPDFButton;
