import { Upload, UploadProps } from "antd";
import { useTranslation } from "react-i18next";

import useCustodialAgreement from "common/shared-hooks/useCustodialAgreement";

interface IProps {
  uploadProps: UploadProps;
}

const EmptyContent = ({ uploadProps }: IProps) => {
  const { t } = useTranslation("liquidity");
  const { isCustodialAgreementActive, checkCustodialAgreement } = useCustodialAgreement();
  return (
    <div onClick={ checkCustodialAgreement } onKeyDown={ () => {} }>
      <Upload.Dragger { ...uploadProps } openFileDialogOnClick={ isCustodialAgreementActive }>
        <p>{t("Drag and drop files here")}</p>
      </Upload.Dragger>
    </div>
  );
};

export default EmptyContent;
