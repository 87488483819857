import { Flex } from "antd";
import { TooltipProps } from "recharts";
// eslint-disable-next-line import/no-unresolved
import { ValueType, NameType } from "recharts/types/component/DefaultTooltipContent";

import { ChartType } from "domains/altCustody/shared/constants";

import "./CustomChartTooltip.scss";

interface IProps extends TooltipProps<ValueType, NameType> {
  type: `${ ChartType }`;
}

const CustomChartTooltip = ({ type, active, payload }: IProps) => {
  if (active && payload && payload.length) {
    const label = type === ChartType.PIE ? payload[ 0 ].name : payload[ 0 ].payload.name;
    const dataValue = payload[ 0 ].value;

    return (
      <Flex align="center" className="custom-tooltip-container">
        <p className="label">{`${ label }: ${ dataValue }`}</p>
      </Flex>
    );
  }

  return null;
};

export default CustomChartTooltip;
