import { CancelTokenSource } from "axios";

export interface IDocument {
  id: string;
  uid?: string;
  display_id: string;
  version_id: string;
  asset_id: null | string;
  liquidity_request_id: null | string;
  reference_type: string;
  document_type: string;
  document_password: undefined | string;
  document_name: string;
  file_name: string;
  date_provided: string;
  updated_date: null | string;
  updated_by: null | string;
  file_size: number;
  investment_id: null;
  do_not_have_this_document: boolean;
  percent?: number;
  cancelToken?: CancelTokenSource;
  mimeType?: string;
}
export interface IPDFDocument {
  id: string;
  uid?: string;
  asset_id: null;
  reference_type: string;
  document_type: string;
  file_name: string;
  file_size: number;
  investment_id: null;
  do_not_have_this_document: boolean;
  mimeType?: string;
}

export interface IDocumentGridData {
  account_id: string;
  ad_hoc_documents_document_ids: string;
  ad_hoc_documents_status: `${ DocumentGridStatus }`;
  annual_fund_and_financial_statement_document_ids: string;
  annual_fund_and_financial_statement_status: `${ DocumentGridStatus }`;
  asset_id: string;
  original_asset_id: string;
  asset_name: string | undefined;
  balance_sheet_document_ids: string;
  balance_sheet_status: `${ DocumentGridStatus }`;
  fund_reports_and_unaudited_financial_statements_document_ids: string;
  fund_reports_and_unaudited_financial_statements_status: `${ DocumentGridStatus }`;
  governing_documents_document_ids: string;
  governing_documents_status: `${ DocumentGridStatus }`;
  partner_id: string;
  liquidity_request_id: string;
  notes: null | string;
  is_active?: boolean;
  is_test_data?: boolean;
}

export enum DocumentGridStatus {
  Received = "received",
  NotNeeded = "not_needed",
  PendingReview = "pending_review",
  PartiallyReceived = "partially_received",
  NotReceived = "not_received",
}

export interface IDocumentUrlData {
  document_id: string;
  file_name: string;
  url: string;
  version: number;
}

export interface IDocumentPassword {
  name: string;
  value: string;
}

export interface IGetDocumentsByAccountIdResponse {
  items: IDocument[];
  page: {
    index: number;
    size: number;
  };
  total: number;
}
