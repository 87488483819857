import { Button } from "common/lib/components";
import SnapshotModal from "domains/altCustody/components/SnapshotModal";
import { ISnapshot } from "domains/altCustody/shared/types";
import { useModalProps } from "common/shared-hooks/useModalProps";

interface IProps {
  currentSnapshotData: ISnapshot["data"];
}

const SnapshotsButtonModal = ({ currentSnapshotData }: IProps) => {
  const modalProps = useModalProps();

  return (
    <>
      <Button type="primary" onClick={ modalProps.handleShow }>
        Create Snapshot
      </Button>

      <SnapshotModal
        currentSnapshotData={ currentSnapshotData }
        isOpen={ modalProps.isVisible }
        handleCancel={ modalProps.handleHide }
      />
    </>
  );
};

export default SnapshotsButtonModal;
