import { useState, useCallback, memo } from "react";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import {
  RequestFormalProposalModal,
  RequestFormalProposalButton,
  AllAltQuoteOffers,
} from "domains/altQuotes/components";
import { useInternalAltQuoteFunctionality } from "domains/altQuotes";
import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { IAltQuoteOffers } from "domains/altQuotes/shared/types";
import { useClientsSelector } from "domains/clients/store/clients.selectors";
import { ApplyForLiquidityModal } from "domains/dashboard/components";
import { setCurrentAltQuote } from "domains/altQuotes/store/altQuotes.reducer";
import { RelationshipTypes } from "domains/clients/shared/types";
import { useTrustbenSelector } from "domains/trustben";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { useModalProps } from "common/shared-hooks/useModalProps";

import "./AltQuote.scss";

interface IProps {
  altQuoteOffers: IAltQuoteOffers;
  isSingleFundView: boolean;
  LRId: string;
  liquidityRequest: ILiquidityRequest | null;
  isOnlyNonFundAssetsSubmitted: boolean;
  isMixOfAssetsSubmitted: boolean;
}

const AltQuote = memo(function AltQuote({
  altQuoteOffers,
  isSingleFundView,
  LRId,
  liquidityRequest,
  isOnlyNonFundAssetsSubmitted,
  isMixOfAssetsSubmitted,
}: IProps) {
  const { t } = useTranslation("altQuotes");
  const dispatch = useDispatch();

  const { data: accounts } = liquidityApiReducer.useFetchLRAccountsQuery(LRId);

  const { isInternalAltQuoteFunctionality, isInternalAltQuote } = useInternalAltQuoteFunctionality();
  const { isTrustbenAltQuote } = useTrustbenSelector();
  const { isAdvisor } = useClientsSelector();
  const requestFormalProposalModalProps = useModalProps();
  const applyForLiquidityModalProps = useModalProps();

  const [ isViewByOffer, setIsViewByOffer ] = useState(true);
  const [ isConvertingAdvisorAltQuoteWithoutClient, setIsAdvisorWithoutClients ] = useState(false);

  const isAltQuoteOffersReceived = !!altQuoteOffers.alt_quote_id;

  const onRequestFormalProposal = useCallback(
    (LRId: string) => {
      const client = accounts?.find(
        (account) => account.is_active && account.relationship_type === RelationshipTypes.Client,
      );
      if (isAdvisor && !client) {
        applyForLiquidityModalProps.handleShow();
        setIsAdvisorWithoutClients(true);
        dispatch(setCurrentAltQuote(liquidityRequest));
        return;
      }

      requestFormalProposalModalProps.handleShow();
      sessionStorage.setItem("LIQUIDITY_REQUEST_ID", LRId);
    },
    [ LRId, isAdvisor, liquidityRequest, accounts?.length ],
  );

  const openRequestFormalProposalModal = useCallback((liquidityRequest: ILiquidityRequest) => {
    requestFormalProposalModalProps.handleShow();
    sessionStorage.setItem("LIQUIDITY_REQUEST_ID", liquidityRequest.id);
  }, []);

  return (
    <>
      <div className="ben-altquote-page-wrapper ben-pb-3 ben-mb-3">
        <div className="ben-altquote-page-container">
          <div className={ `ben-altquote-page-title-container ${ isSingleFundView ? "ben-single-fund-view" : "" }` }>
            <h2>{t("My AltQuote")}</h2>
            {!isAltQuoteOffersReceived && !isOnlyNonFundAssetsSubmitted && (
              <p className="ben-mb-2">{t("AltQuote Title Text")}</p>
            )}
            {isOnlyNonFundAssetsSubmitted && <p className="ben-mb-1">{t("AltQuote Page.Only Non Fund Assets Text")}</p>}
            {isMixOfAssetsSubmitted && <p className="ben-mb-1">{t("AltQuote Page.Mix Of Assets Text")}</p>}
          </div>
          {!isOnlyNonFundAssetsSubmitted && (
            <div
              className={ `ben-mt-1 ben-altquote-page-offers-container ${
                isSingleFundView ? "ben-single-fund-view" : ""
              }` }
            >
              <div
                className={ `ben-altquote-page-assets-button ${ isViewByOffer ? "ben-active" : "" }` }
                onClick={ () => setIsViewByOffer(true) }
              >
                {t("View By Quote")}
              </div>
              <div
                className={ `ben-altquote-page-assets-button ${ isViewByOffer ? "" : "ben-active" }` }
                onClick={ () => setIsViewByOffer(false) }
              >
                {t("By Fund")}
              </div>
            </div>
          )}
        </div>
      </div>
      {!isOnlyNonFundAssetsSubmitted && (
        <AllAltQuoteOffers
          isViewByOffer={ isViewByOffer }
          viewByOfferDataSource={ altQuoteOffers.offers }
          viewByFundDataSource={ altQuoteOffers.fundOffers }
          liquidityRequest={ liquidityRequest }
        />
      )}
      {!isInternalAltQuoteFunctionality && !isOnlyNonFundAssetsSubmitted && (
        <p className="ben-mt-2 ben-altquote-page-bottom-info-message">{t("AltQuote Page.Bottom Info Text")}</p>
      )}
      {isTrustbenAltQuote && (
        <p className="ben-mt-2 ben-altquote-page-bottom-info-message">
          {"To request a formal proposal, you'll need to register or log in with an existing account."}
        </p>
      )}

      {!isInternalAltQuote && (
        <RequestFormalProposalButton onRequestFormalProposal={ onRequestFormalProposal } LRId={ LRId } />
      )}

      <RequestFormalProposalModal
        isVisible={ requestFormalProposalModalProps.isVisible }
        handleCancel={ requestFormalProposalModalProps.handleHide }
      />

      <ApplyForLiquidityModal
        isVisible={ applyForLiquidityModalProps.isVisible }
        handleCancel={ applyForLiquidityModalProps.handleHide }
        isLA={ true }
        isConvertingAdvisorAltQuoteWithoutClient={ isConvertingAdvisorAltQuoteWithoutClient }
        openRequestFormalProposalModal={ openRequestFormalProposalModal }
      />
    </>
  );
});

export default AltQuote;
