import { TCustodyAccountType } from "./constants";
import { apiEnvs, apiService } from "common/shared";
import {
  ICustodyAccount,
  ICustodyAccountAPI,
} from "./types";
import {
  formatCustodyAccountDataToCreateByUserType,
  formatCustodyAccountToConnectWithLRByUserType,
} from "./apiFormatter";
import { TUserType } from "domains/clients/shared/types";

export class CustodyAccountsApiService {
  async createCustodyAccount(data: any, userType: TUserType, custodyAccountType: TCustodyAccountType) {
    const custodyAccountData = formatCustodyAccountDataToCreateByUserType(data, userType, custodyAccountType);
    return apiService.post(`${ apiEnvs.accountsAPI }/v2/accounts`, custodyAccountData);
  }

  async connectCustodyAccountWithAsset(
    CustodyAccountId: string, AssetId: string, UserAccountId: string, isDefault?: boolean,
  ) {
    const requestBody = [
      {
          "op": "replace",
          "path": "/custody_account_id",
          "value": CustodyAccountId,
      },
      {
          "op": "replace",
          "path": "/account_id",
          "value": UserAccountId, 
      },
      {
          "op": "replace",
          "path": "/custody_account_status",
          "value": true,
      },
      
    ]
    if (isDefault) {
      requestBody.push({
        "op": "replace",
        "path": "/default_custody_account",
        "value": isDefault, 
      })
    }
  
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/assets/${ AssetId }`, requestBody);
  }

  async connectCustodyAccountWithLR(userType: TUserType, data: ICustodyAccount & ICustodyAccountAPI, LRId: string) {
    const requestBody = formatCustodyAccountToConnectWithLRByUserType(userType, data, LRId);
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/accounts`, requestBody);
  }
}

const custodyAccountsApiService = new CustodyAccountsApiService();
export default custodyAccountsApiService;
