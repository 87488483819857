import { Key } from "react";

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { IChart } from "domains/altCustody/shared/types";
import { getInitialSnapshots } from "domains/altCustody/shared-hooks/useSnapshots";
import { TAssetType } from "domains/liquidity/shared/types";

export interface IInitialState {
  chartData: any;
  charts: IChart[];
  snapshots: any;
  isInvestmentEditing: boolean;
  isExportPDF: boolean;
  currentAsset: Partial<TAssetType>;
  isFetchingSnapshots: boolean;
  isChartDataFetching: boolean;
}

const initialState: IInitialState = {
  charts: [],
  currentAsset: {},
  snapshots: getInitialSnapshots() ?? {},
  isInvestmentEditing: false,
  isExportPDF: false,
  isFetchingSnapshots: false,
  isChartDataFetching: true,
  chartData: [],
};

const altCustodyReducer = createSlice({
  name: "[AltCustody]",
  initialState,
  reducers: {
    setChartData: (state, action: PayloadAction<any>) => {
      return ({
      ...state,
      chartData: action.payload,
    })},
    setIsChartDataFetching: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isChartDataFetching: action.payload,
    }),
    setIsFetchingSnapshots: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isFetchingSnapshots: action.payload,
    }),
    setCurrentAssetData: (state, action: PayloadAction<Partial<TAssetType>>) => ({
      ...state,
      currentAsset: {
        ...state.currentAsset,
        ...action.payload,
      },
    }),
    resetCurrentAssetData: (state) => ({
      ...state,
      currentAsset: initialState.currentAsset,
    }),
    addChart: (
      state,
      { payload: { newChart, shouldUpsert } }: PayloadAction<{ newChart: IChart; shouldUpsert?: boolean }>,
    ) => ({
      ...state,
      charts: shouldUpsert ? [ newChart ] : [ ...state.charts, newChart ],
    }),

    updateChart: (state, action: PayloadAction<IChart>) => {
      return {
        ...state,
        charts: state.charts.map((chart) => (chart.key === action.payload.key ? action.payload : chart)),
      };
    },

    deleteChart: (state, action: PayloadAction<Key>) => ({
      ...state,
      charts: state.charts.filter((chart) => chart.key !== action.payload),
    }),

    setIsInvestmentEditing: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isInvestmentEditing: action.payload,
    }),

    setSnapshots: (state, { payload }: PayloadAction<any>) => ({
      ...state,
      snapshots: payload,
    }),

    setIsExportPDF: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isExportPDF: action.payload,
    }),

    resetAltCustodyReducer: () => initialState,
  },
});

export const {
  addChart,
  updateChart,
  deleteChart,
  setIsInvestmentEditing,
  setSnapshots,
  setIsExportPDF,
  resetAltCustodyReducer,
  setCurrentAssetData,
  resetCurrentAssetData,
  setChartData,
  setIsFetchingSnapshots,
  setIsChartDataFetching,
} = altCustodyReducer.actions;

export default altCustodyReducer.reducer;
