import { useCallback } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { LAPages } from "domains/liquidity/shared/constants";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";

export const useHandlers = () => {
  const { LRId } = useParams();
  const navigate = useNavigate();
  const { currentLiquidityRequest } = useLiquiditySelector();
  

  const onSubmit = () => null
  // const onSubmit = () => navigate(URLs.PROTECTED.LIQUIDITY + LRId + "/" + LAPages.ConfirmAssetDocuments);

  const onSkip = () => {
    const currentPath = location.pathname;
 //   navigate(newPath);
    if(!currentPath.includes('my_assets')) {
      //navigate(URLs.PROTECTED.MY_APPLICATIONS)
      navigate(
        URLs.PROTECTED.MY_APPLICATIONS 
        + currentLiquidityRequest?.id 
        + "/"
        + (currentPath.includes('altquote') ? MyApplicationsPages.ALTQUOTE : MyApplicationsPages.APPLICATION_STATUS ),
      );

    } else {
      navigate(URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS + AltCustodyAssetPages.PortfolioManagement + "?tab=PortfolioDocuments")
    }


  }
    

  return {
    onSubmit,
    onSkip,
  };
};
