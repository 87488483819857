/* eslint-disable no-restricted-imports */
import { ChangeEvent, FC } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import { Form, Input } from "common/lib/formik-antd";
import { maxLengthText } from "common/shared/constants/validationConstants";
import { CircleX } from "common/lib/components/Icons";

interface IProps {
  inputValue: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleCleanInput: () => void;
}

const SelectFundForm: FC<IProps> = ({ inputValue, handleChange, handleCleanInput }) => {
  const { t } = useTranslation("liquidity");
  return (
    <Formik initialValues={ {} } onSubmit={ console.log }>
      <Form layout="vertical">
        <Form.Item name="fund_name" label={ <>{t("Fund Search")} </> }>
          <Input
            maxLength={ maxLengthText }
            value={ inputValue }
            name="fund_name"
            className="ben-small ben-search"
            onChange={ handleChange }
            placeholder=""
            // input loses focus without <span />
            suffix={ inputValue ? <CircleX onClick={ handleCleanInput } /> : <span /> }
          />
        </Form.Item>
      </Form>
    </Formik>
  );
};

export default SelectFundForm;
