import { Table, UploadProps } from "antd";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { Spinner } from "common/lib/components";
import { useDocumentSelector } from "domains/documents/store/documents.selector";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { useColumns } from "./useColumns";
import EmptyContent from "./EmptyContent";
import useScreen from "common/shared-hooks/useScreen";
import AssetDocumentsListMobile from "./AssetDocumentsListMobile";

import "./AssetDocumentsTable.scss";

interface IProps {
  uploadProps: UploadProps;
}

const AssetDocumentsTable = ({ uploadProps }: IProps) => {
  const { mobile } = useScreen();
  const { uploadingDocuments } = useDocumentSelector();
  const { currentLiquidityRequest } = useLiquiditySelector();
  const { data: documents = [] } = documentsApiReducer.useFetchLRDocumentsQuery(skipToken);
  const allDocuments = [ ...documents, ...uploadingDocuments ];
  const { columns } = useColumns({ uploadProps, allDocuments });

  if (!currentLiquidityRequest) {
    return <Spinner />;
  }

  if (mobile) return <AssetDocumentsListMobile documents={ allDocuments } uploadProps={ uploadProps } />;

  return (
    <Table
      className="ben-asset-documents-table"
      locale={ {
        emptyText: <EmptyContent uploadProps={ uploadProps } />,
      } }
      dataSource={ allDocuments }
      columns={ columns }
      pagination={ false }
      scroll={ { y: 400 } }
    />
  );
};

export default AssetDocumentsTable;
