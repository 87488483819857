import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";

import "./AddManually.scss";

const AddManually = () => {
  const { LRId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation([ "liquidity", "common" ]);

  return (
    <div className="add-manually-container">
      <div>{t("My Fund Is Not Listed")}</div>
      <Button
        color="white"
        onClick={ () => {
          const currentPath = location.pathname;
          const pathParts = currentPath.split('/');
          pathParts.pop();
          const newPath = `${ pathParts.join('/') }${ AltCustodyAssetPages.AddManually }`;
          navigate(newPath);
      
        } }
      >
        {t("Add Manually", { ns: "common" })}
      </Button>
    </div>
  );
};

export default AddManually;
