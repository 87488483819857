/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from "react";

import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";

import { Form, InputNumber } from "common/lib/formik-antd";
import { Button, NavigationButtons, Spinner, Tooltip } from "common/lib/components";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { currencyFormatter, currencyParser } from "domains/liquidity/shared/utils/inputValueFormatter";
import { useInvestmentDetailsPageValidationSchema } from "./validationSchema";
import { useHandlers } from "./useHandlers";
import { AltCustodyAssetPages, AltCustodyMutationCacheKey } from "domains/altCustody/shared/constants";
import { AccountType, URLs } from "common/lib/constants";
import { setAssetsPendingCreation, setCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { useAppDispatch } from "store/store.hooks";
import { idUtils } from "common/shared";
import { useInvestments } from "../../../hooks/useInvestments";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { useClientsSelector } from "domains/clients";
import { getCustodyAccountStatus } from "domains/custodyAccounts/shared/helpers";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { LAPages } from "domains/liquidity";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";

import "./InvestmentDetailsForm.scss"

const InvestmentDetailsForm = () => {
  const { t } = useTranslation("liquidity");
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const isLiquidityReqyestFlow  = pathname.includes('my-applications')
  const isAlquoteFlow  = pathname.includes('new-altquote')

  const [ updateInvestment ] = altCustodyApiReducer.useUpdateInvestmentMutation({
  fixedCacheKey: AltCustodyMutationCacheKey.UPDATE_INVESTMENT,
  });

  const [ updateAsset ] = liquidityApiReducer.useUpdateAssetMutation({
    fixedCacheKey: "update-lr-asset",
  });

  const { createInvestment } = useInvestments();
  const { investments } = useAssetsHoldingsData()
  const { validationSchema } = useInvestmentDetailsPageValidationSchema();
  const translationPrefix = "Investment Details Page.Default";
  const { currentCustodyAccount } = useCustodyAccountsSelector()
  const { user } = useClientsSelector();
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );


  const activeCustodyAccounts = custodyAccounts?.filter(
      (custodyAccount) =>
        custodyAccount.account_status !== CustodyAccountStatus.Closed &&
        custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
        custodyAccount.account_status !== CustodyAccountStatus.Duplicate &&
        custodyAccount.account_type !== AccountType.CustodialAdvisor,
    );

  const { ASTId }  = useParams()
  const [ isUpdating, setIsUpdating ] = useState(false)
  const { currentAsset } = useLiquiditySelector();
  const defaultCustodyAccount:any = currentCustodyAccount 
  || ( currentAsset && (currentAsset as any).custody_account_id ) && custodyAccounts?.find(({ account_id }) => account_id === (currentAsset as any).custody_account_id )
  || ( activeCustodyAccounts && activeCustodyAccounts.length && activeCustodyAccounts[ 0 ])

  const onSubmit = async (formValues:any) => {
    if (!ASTId) {
      const updatedCurrentAsset = { ...formValues, custody_account_id: currentCustodyAccount ? currentCustodyAccount.account_id : defaultCustodyAccount.account_id, asset_id: idUtils.uuidv4() }
      if (isLiquidityReqyestFlow) {
        dispatch(setCurrentAssetData(updatedCurrentAsset))
        createInvestment()
      } else {
        const currentPath = location.pathname;
        const pathParts = currentPath.split('/');
        pathParts.pop();
        const newPath = `${ pathParts.join('/') }${ AltCustodyAssetPages.ReviewAssets }`;
        dispatch(setAssetsPendingCreation(updatedCurrentAsset));
        navigate(newPath)
      }
    } else {
      if (currentAsset && !currentCustodyAccount) {
        const currentAssetCAId = (currentAsset as any)?.custody_account_id
        const currentAssetCAIsActive = activeCustodyAccounts?.find(({ account_id }: any) => account_id === currentAssetCAId) 
        if(!currentAssetCAIsActive) {
          onChangeCustodyAccount()
          return
        }
      }
      setIsUpdating(true)
      const updates = { ...formValues, custody_account_id: currentCustodyAccount ? currentCustodyAccount.account_id : formValues.custody_account_id }
      if(currentCustodyAccount) {
        await updateAsset({
          assetId: updates.asset_id,
          changes: {
            custody_account_id: updates.custody_account_id,
            account_id: user?.account_id,
          },
        })        
      }
      
      await updateInvestment({
        investmentId: updates.id,
        changes: {
          current_net_asset_value: updates.current_net_asset_value,
          capital_commitment: updates.capital_commitment,
          unfunded_commitment_currency: updates.unfunded_commitment_currency,
        },
      } as any)
      
      navigate(URLs.PROTECTED.DASHBOARD)
    }
  }

  const onBack = () => {
    navigate(-1)
  }


  const onDelete = () => {
  }

  const terminationDate:any = (defaultCustodyAccount as any)?.custodial_agreement_expiry_date ;

  const onChangeCustodyAccount = () => {
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/');
    pathParts.pop();
    const newPath = `${ pathParts.join('/') }/${ LAPages.CustodyAccount }`;
    navigate(newPath)

  }

  const onChangeFormValues = (values:any) => {
    // setFormValues({ ...formValues, ...values })
    // dispatch(setCurrentAssetData(formValues))

  }

  useEffect(() => {
    const urlAsset: Record<any,any> = investments?.find(({ asset_id }: any) => {
      return (asset_id === ASTId)}) || {}
      if (urlAsset) {
        dispatch(setCurrentAssetData(urlAsset))
        dispatch(setCurrentAssetData(urlAsset))
      }
      // console.log({ urlAsset, investments, ASTId })
  }, [ investments ])

  useEffect(() => {
  }, [ currentAsset ])

  return (
    <div style={ { width: "100%" } }>

      <Spinner spinning={ !!(ASTId && !defaultCustodyAccount) || isUpdating }>
        <Formik
          onSubmit={ onSubmit }
          initialValues={ { unfunded_commitment_type: "$", ...currentAsset } }
          validationSchema={ validationSchema }
          validateOnMount
          enableReinitialize
        >
          {({ isValid, values }) => {
            return (
              <Form layout="vertical" requiredMark="optional">
                <Form.Item
                  name="current_net_asset_value"
                  required={ isAlquoteFlow }
                  label={
                    <>
                      {t(`${ translationPrefix }.Form Labels.0`)}{" "}
                      <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.0`) } dark />
                    </>
                  }
                >
                  <InputNumber
                    name="current_net_asset_value"
                    className="small"
                    formatter={ currencyFormatter }
                    parser={ currencyParser }
                    controls={ false }
                    prefix
                    onChange={ ( newVal ) => onChangeFormValues({ current_net_asset_value: newVal }) }
                  />
                </Form.Item>
                <Form.Item
                  name="capital_commitment"
                  required={ isAlquoteFlow }
                  label={
                    <>
                      {t(`${ translationPrefix }.Form Labels.1`)}{" "}
                      <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.1`) } dark />
                    </>
                  }
                >
                  <InputNumber
                    name="capital_commitment"
                    className="small"
                    disabled={ (currentAsset as any).asset_status === "locked_by_bids" }
                    formatter={ currencyFormatter }
                    parser={ currencyParser }
                    onChange={ ( newVal ) => onChangeFormValues({ capital_commitment: newVal }) }
                    controls={ false }
                    prefix
                  />
                </Form.Item>
    
                <Form.Item
                  name="unfunded_commitment_currency"
                  required={ isAlquoteFlow }
                  hidden={ values.unfunded_commitment_type !== "$" }
                  label={
                    <>
                      {t(`${ translationPrefix }.Form Labels.2`)}{" "}
                      <Tooltip content={ t(`${ translationPrefix }.Form Tooltips.2`) } dark />
                    </>
                  }
                >
                  <InputNumber
                    name="unfunded_commitment_currency"
                    className="small"
                    formatter={ currencyFormatter }
                    disabled={ (currentAsset as any).asset_status === "locked_by_bids" }
                    parser={ currencyParser }
                    onChange={ ( newVal ) => onChangeFormValues({ unfunded_commitment_currency: newVal }) }
                    controls={ false }
                    prefix
                  />
                </Form.Item>
                {
                  defaultCustodyAccount && !isAlquoteFlow
                  ? (
                    <>
                      <div className="custody-account-selector">
                        <div className="column" style={ { flex: 1 } }>
                          <div className="title">Custody Account Name</div>
                          <div className="data">{ defaultCustodyAccount.account_name }</div>
                        </div>
                        <div className="column">
                          <div className="title">Date Opened</div>
                          <div className="data">{ getDateFromISO(defaultCustodyAccount.created_date) }</div>
                        </div>
                        <div className="column" style={ { flex: 0.7 } }>
                          <div className="title">Status</div>
                          <div className="data">{ getCustodyAccountStatus(defaultCustodyAccount.account_status) }</div>
                        </div>
                        <div className="column">
                          <div className="title">Termination Date</div>
                          <div className="data">{ `${ getDateFromISO(terminationDate, { timeZone: "America/Chicago" }) } (CST)` }</div>
                        </div>
                        <div className="column">
                          <div className="title">Account Number</div>
                          <div className="data">{ defaultCustodyAccount.display_id }</div>
                        </div>
                        <div className="change-account-button">
                          <Button onClick={ onChangeCustodyAccount }>Change Custody Account</Button>
                        </div>
                      </div>
                    </>
                  )
                  : ''
                }
                <NavigationButtons submitButtonText={ ASTId ? "Update" : undefined } onBack={ onBack } disabled={ !isValid } />
              </Form>
            )}
          }
          
        </Formik>
      </Spinner>
    </div>
  );
};

export default InvestmentDetailsForm;
