/* eslint-disable max-len */
import { IUserDetails, UserType } from "domains/clients/shared/types";
import { AssetType } from "domains/liquidity/shared/constants";
import { LiquidityRequestStatus, TAssetType } from "domains/liquidity/shared/types";
import { realEstatePropertyTypeList } from "v2/features/assets/addAsset/SelectOtherAsset/forms/DirectRealEstateInvestmentForm/constants";

const first80char = (string:any) => {
    const cropped = string.length > 80 ? string.substring(0, 80) : string;
    return cropped
}

export const formatInvestmentForRequest = (
  asset: Partial<TAssetType>,
  user: IUserDetails,
  isAdvisor: boolean,
  hasCustodyAccounts: boolean | null,
  currentClient?: any,
  custodyAccounts?: any[] | null,
): any => {
  const baseRequest = {
    account_id: user.account_id,
    account_display_id: user.account_display_id,
    account_name: user.account_name,
    account_type: isAdvisor ? currentClient?.investor_type ?? "individual" : user.user_type,
    status: "in_portfolio",
    is_active: true,
    asset_type: asset.asset_type,
    asset_name: asset.asset_name,
    alt_custody_asset_status: "InComplete",
    fund_name: asset.asset_name,
    fund_manager: asset.fund_manager,
    capital_commitment: asset.capital_commitment,
    current_net_asset_value: asset.current_net_asset_value,
    unfunded_commitment_currency: asset.unfunded_commitment_currency,
    ...(hasCustodyAccounts && {
      custody_account_status: true,
      custody_account_id: (asset as any).custody_account_id || custodyAccounts?.[ 0 ]?.account_id,
    }),
  };

  const fundFields = {
    amount_invested: asset.amount_invested,
    fund_strategy: asset.fund_strategy,
    vintage_year: asset.vintage,
    geographic_focus: asset.geographic_focus,
  }

  switch (asset.asset_type) {
    case AssetType.PreqinFund:
      return {
        ...baseRequest,
        ...fundFields,
        asset_status: "locked_by_bids",
        fund_id: asset.fund_id,
      };

    case AssetType.Fund:
      return {
        ...baseRequest,
        ...fundFields,
        asset_status: "needs_review_by_bids",
      };

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return {
        ...baseRequest,
        amount_invested: asset.amount_invested,
        asset_description: asset.asset_description,
      };

    case AssetType.CoInvestment:
      return {
        ...baseRequest,
        asset_description: asset.asset_description,
        sponsor: asset.sponsor,
        sponsor_description: asset.sponsor_description,
        fund_name: first80char(asset.sponsor_description),
        asset_name: first80char(asset.sponsor_description),
        fund_manager: first80char(asset.sponsor),
      };

    case AssetType.DirectRealEstateInvestment:
      return {
        ...baseRequest,
        real_estate_property_type: asset.real_estate_property_type,
        real_estate_location: asset.real_estate_location,
        state_or_province: asset.state,
        fund_name: realEstatePropertyTypeList[ asset.real_estate_property_type ?? 0 ].label ,
        asset_name: realEstatePropertyTypeList[ asset.real_estate_property_type ?? 0 ].label ,
      };

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return {
        ...baseRequest,
        amount_invested: asset.amount_invested,
        asset_description: asset.company_description,
        fund_name: asset.account_name,
        asset_name: asset.account_name,
      };

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return {
        ...baseRequest,
        asset_description: asset.asset_description,
        fund_name: first80char(asset.asset_description),
        asset_name: first80char(asset.asset_description),
      };
  }
};


export const formatSnapshotsRequest = (unformatted:any) => {
  const requestBody = {
    account_id: unformatted.account_id, 
    snapshot_json: JSON.stringify(unformatted.data.data),
    account_display_id:  unformatted.account_display_id, 
    name:  unformatted.data.name,
    is_active: true,
    is_test_data: false,
  };

  return requestBody;
}

export const formatInvestmentsForReducer = (investments: any[]): TAssetType[] => {
  return investments.map((investment) => {
    switch (investment.asset_type) {
      case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
        return {
          ...investment,
          account_name: investment.asset_name,
          company_description: investment.asset_description,
        };

      default:
        return investment;
    }
  });
};

export const formatInvestmentForReducer = (asset: any): Partial<TAssetType> => {
  switch (asset.asset_type) {
    case AssetType.Fund:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        fund_manager: asset.sponsor,
        vintage: asset.vintage,
        fund_strategy: asset.fund_strategy,
        geographic_focus: asset.geographic_focus,
        current_net_asset_value: asset.current_net_asset_value,
        capital_commitment: asset.capital_commitment,
        unfunded_commitment_currency: asset.unfunded_commitment_currency,
      };

    case AssetType.PreqinFund:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        fund_id: asset.fund_id,
        fund_manager: asset.sponsor,
        current_net_asset_value: asset.current_net_asset_value,
        capital_commitment: asset.capital_commitment,
        unfunded_commitment_currency: asset.unfunded_commitment_currency,
      };

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        amount_invested: asset.amount_invested,
      };

    case AssetType.CoInvestment:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        sponsor: asset.sponsor,
        sponsor_description: asset.sponsor_description,
      };

    case AssetType.DirectRealEstateInvestment:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        real_estate_property_type: asset.real_estate_property_type,
        real_estate_location: asset.real_estate_location,
        state: asset.state /* state_or_province */,
      };

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        account_name: asset.asset_name,
        amount_invested: asset.amount_invested,
        company_description: asset.asset_description,
      };

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return {
        id: asset.id,
        asset_type: asset.asset_type,
        asset_name: asset.asset_name,
        asset_description: asset.asset_description,
      };

    default:
      return {} as TAssetType;
  }
};

export const formatInvestmentForUpdate = (assetType: AssetType, changes: Partial<TAssetType>) => {
  switch (assetType) {
    case AssetType.PreqinFund:
      return {
        current_net_asset_value: changes.current_net_asset_value,
        capital_commitment: changes.capital_commitment,
        unfunded_commitment_currency: changes.unfunded_commitment_currency,
      };

    case AssetType.Fund:
      return {
        asset_name: changes.asset_name,
        fund_strategy: changes.fund_strategy,
        vintage_year: changes.vintage,
        geographic_focus: changes.geographic_focus,
        current_net_asset_value: changes.current_net_asset_value,
        capital_commitment: changes.capital_commitment,
        unfunded_commitment_currency: changes.unfunded_commitment_currency,
      };

    case AssetType.NonTradedREIT:
    case AssetType.NonTradedBDC:
      return {
        amount_invested: changes.amount_invested,
        asset_name: changes.asset_name,
        asset_description: changes.asset_description,
      };

    case AssetType.CoInvestment:
      return {
        sponsor: changes.sponsor,
        sponsor_description: changes.sponsor_description,
      };

    case AssetType.DirectRealEstateInvestment:
      return {
        real_estate_property_type: changes.real_estate_property_type,
        real_estate_location: changes.real_estate_location,
        state_or_province: changes.state,
      };

    case AssetType.PrivateSharesInSinglePrivatelyHeldCompany:
      return {
        asset_name: changes.account_name,
        amount_invested: changes.amount_invested,
        asset_description: changes.company_description,
      };

    case AssetType.DirectInterestsInNaturalResourcesAsset:
      return {
        asset_description: changes.asset_description,
      };
  }
};

export const prepareTransactionBodyForRequest = (user: IUserDetails) => {
  const isAdvisor = user.user_type === UserType.Advisor || user.user_type === UserType.BenOriginations;
  const accounts = isAdvisor
    ? [
        {
          account_id: parseInt(user.account_id.slice(4)),
          account_name: user.account_name,
          account_display_id: user.account_display_id,
          relationship_type: "2",
          kyc_status: "None",
          is_active: true,
          is_test_data: false,
        },
      ]
    : [
        {
          account_id: parseInt(user.account_id.slice(4)),
          account_name: user.account_name,
          account_display_id: user.account_display_id,
          relationship_type: "3",
          kyc_status: "None",
          is_active: true,
          is_test_data: false,
        },
      ];

  return {
    liquidity_request_type: "AltCustodys",
    account_id: user.account_id,
    account_display_id: user.account_display_id,
    account_name: user.account_name || "",
    created_by: user.user_id,
    created_by_name: user.first_name + " " + user.last_name,
    status: LiquidityRequestStatus.PendingSubmission,
    is_active: true,
    is_test_data: false,
    liquidity_request_name: "",
    accounts,
  };
};
