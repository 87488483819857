import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ILiquidityRequest } from "domains/liquidity/shared/types";
import { URLs } from "common/lib/constants";
import { MyApplicationsPages } from "domains/myApplications/shared/constants";
import { useClientsSelector } from "domains/clients";
import { StandardToDo } from "domains/dashboard/components/CompactToDos";
import { getDateFromISO } from "common/shared/utils/date.utils";
import { EditOutlined } from "common/lib/components";
import EditApplicationNameModal from "domains/dashboard/components/EditApplicationNameModal";
import { useModalProps } from "common/shared-hooks/useModalProps";

import "./ApplicationsListItem.scss";

interface IProps {
  application: ILiquidityRequest;
  isClientPage?: boolean;
}

const ApplicationsListItem = ({ application, isClientPage }: IProps) => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();

  const { isAdvisor } = useClientsSelector();
  const modalProps = useModalProps();

  const [ LRName, setLRName ] = useState("");
  const [ LRId, setLRId ] = useState("");

  const onApplicationItemClick = (application: ILiquidityRequest) => {
    navigate(URLs.PROTECTED.MY_APPLICATIONS + application.id + "/" + MyApplicationsPages.APPLICATION_STATUS);
  };

  const openEditApplicationNameModal = (e: MouseEvent, LRName: string, LRId: string) => {
    e.preventDefault();
    e.stopPropagation();
    setLRName(LRName);
    setLRId(LRId);
    modalProps.handleShow();
  };

  return (
    <div
      className={ isClientPage ? "ben-application-item ben-border-top ben-pt-1" : "ben-application-item" }
      key={ application.display_id }
    >
      <div className="ben-application-item-info">
        <div className="ben-application-name-and-date">
          {
            <div className="ben-application-name">
              <div onClick={ () => onApplicationItemClick(application) }>
                {application && application.liquidity_request_name && (
                  <EditOutlined
                    style={ { margin: "0px 6px -4px -23px" } }
                    onClick={ (event: any) =>
                      openEditApplicationNameModal(event, application.liquidity_request_name, application.id)
                    }
                  />
                )}
                {isAdvisor || isClientPage
                  ? `${ application.client?.account_name ? application.client.account_name + ":" : "" } ${
                      application.liquidity_request_name
                    }`
                  : application.liquidity_request_name}
              </div>
            </div>
          }
          <p className="ben-date-initiated">{`${ t("Initiated") } ${ getDateFromISO(application.created_at) }`}</p>
          {isAdvisor && !isClientPage && <p>{t("By") + " " + application.created_by_name}</p>}
        </div>

        <div className="ben-application-status">
          <b>{t("Status")}</b>
          <p>{application?.currentTask?.display_status}</p>
        </div>
      </div>

      <StandardToDo
        LRId={ application.id }
        toDo={ application.currentTask }
        createdAt={ getDateFromISO(application.created_at) }
      />

      <EditApplicationNameModal
        isVisible={ modalProps.isVisible }
        handleCancel={ modalProps.handleHide }
        LRName={ LRName }
        LRId={ LRId }
      />
    </div>
  );
};

export default ApplicationsListItem;
