/* eslint-disable no-restricted-imports */
import { useEffect, useState } from "react";

import { Table, TableProps } from "antd";
import dayjs from "dayjs";

import { capitalizeFirstLetter, currencyFormatter } from "domains/liquidity/shared/utils/inputValueFormatter";
import { getDateFromISO } from "common/shared/utils/date.utils";
import "../portfolioManager.scss";

export const tableDataToDataSource = (rawObject: any) => rawObject.flatMap((investment: any) => {
  return { 
    ...investment, 
    key: investment.investment_entity_id + investment.fund_entity_id, 
   }
}) 

const columnData: TableProps["columns"] = [
  {
    title: "Fund Name",
    key: "fundName",
    dataIndex: "fund_name",
    align: "center",
    sorter: (a: any, b: any) => a?.fund_name?.localeCompare(b?.fund_name ?? "") ?? 0,
    defaultSortOrder: 'ascend',
  },
  {
    title: "Investment Name",
    key: "investmentName",
    dataIndex: "investment_name",
    align: "center",
    sorter: (a: any, b: any) => a?.investment_name?.localeCompare(b?.investment_name ?? "") ?? 0,
    defaultSortOrder: 'ascend',
  },
  {
    title: "Instrument Type",
    key: "instrumentType",
    dataIndex: "instrument_type",
    align: "center",
    sorter: (a: any, b: any) => a?.instrument_type?.localeCompare(b?.instrument_type ?? "") ?? 0,
  },
  {
    title: "Investor NAV",
    key: "investorNAV",
    dataIndex: "investor_nav",
    align: "center",
    sorter: (a: any, b: any) => {
      const valueA = a?.investor_nav ?? 0;
      const valueB = b?.investor_nav ?? 0;
      return valueA - valueB;
    },
    render: (value) => currencyFormatter(Number(value)),
  },
  {
    title: "Public or Private",
    key: "publicOrPrivate",
    dataIndex: "investment_status",
    align: "center",
    sorter: (a: any, b: any) => a?.investment_status?.localeCompare(b?.investment_status ?? "") ?? 0,
    render: (value) => value || 'N/A',
  },
  {
    title: "Country",
    key: "country",
    dataIndex: "country",
    align: "center",
    sorter: (a: any, b: any) => a?.country?.localeCompare(b?.country ?? "") ?? 0,
  },
  {
    title: "Ben Investment Asset Class",
    key: "benInvestmentAssetClass",
    dataIndex: "asset_class",
    align: "center",
    sorter: (a: any, b: any) => a?.asset_class?.localeCompare(b?.asset_class ?? "") ?? 0,
    render: (value) => capitalizeFirstLetter(value),
  },
  {
    title: "Ben Investment Sub Asset Class",
    key: "benInvestmentSubAssetClass",
    dataIndex: "sub_asset_class",
    align: "center",
    width: 125,
    sorter: (a: any, b: any) => a?.sub_asset_class?.localeCompare(b?.sub_asset_class ?? "") ?? 0,
  },
  {
    title: "GICS Level 1 (Sector)",
    key: "sector",
    dataIndex: "sector",
    align: "center",
    sorter: (a: any, b: any) => a?.sector?.localeCompare(b?.sector ?? "") ?? 0,
  },
  {
    title: "GICS Level 2 (Industry Group)",
    key: "industryGroup",
    dataIndex: "industry_group",
    align: "center",
    sorter: (a: any, b: any) => a?.industry_group?.localeCompare(b?.industry_group ?? "") ?? 0,
  },
  {
    title: "GICS Level 3 (Industry)",
    key: "industry",
    dataIndex: "industry",
    align: "center",
    sorter: (a: any, b: any) => a?.industry?.localeCompare(b?.industry ?? "") ?? 0,
  },
  {
    title: "GICS Level 4 (Sub Industry)",
    key: "sub_industry",
    dataIndex: "sub_industry",
    align: "center",
    sorter: (a: any, b: any) => a?.sub_industry?.localeCompare(b?.sub_industry ?? "") ?? 0,
  },
  {
    title: "NAV As Of Date",
    key: "navAsOfDate",
    dataIndex: "nav_as_of_date",
    align: "center",
    sorter: (a: any, b: any) => {
      const dateA = dayjs(a?.nav_as_of_date);
      const dateB = dayjs(b?.nav_as_of_date);
      return dateA.isAfter(dateB) ? 1 : -1;
    },
    render: (date) => getDateFromISO(date),
  },
];


const InvestmentTable = ({ selectedFragment, tableData, onRowSelectionChange = () => null, rowSelection }:any) => {
  const [ selectedRowKeys, setSelectedRowKeys ] = useState<any>();

  useEffect(() => {
    onRowSelectionChange(selectedRowKeys)
  }, [ selectedRowKeys ])

  useEffect(() => {
    if ( rowSelection && rowSelection !== selectedRowKeys ) {
      setSelectedRowKeys(rowSelection)
    }
  }, [ rowSelection ])

  return (
    <section className="ben-investment-analytics-section">
      <Table
        id="investment-analytics-table"
        className="investment-analytics-table"
        scroll={ { x: 2000 } }
        style={ { fontSize: "10px !important" } }
        pagination={ false }
        columns={ columnData }
        dataSource={ tableDataToDataSource(tableData) }
        rowSelection={ {
          selectedRowKeys,
          onChange: setSelectedRowKeys,
        } }
        rowClassName={ (tableRecord) => {
          if (!selectedFragment || !selectedFragment.chart) {
            return "";
          } else {
            return tableRecord[ selectedFragment.chart.dataKey ] !== selectedFragment.entry.name ? "excluded" : "";
          }
            
        } }
        onChange={ (e) => {
          console.log({ e })
        } }
      />
    </section>
  );
};


export default InvestmentTable;
