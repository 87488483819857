import { AxiosError } from "axios";

import { rootApiReducer } from "store/root.api.reducer";
import proposalsApiService from "domains/proposals/shared/proposals.api.service";
import {
  IAssetGroup,
  IOfferOption,
  IProposals,
  IResubmitAssetsRequest,
  ISetOfferOptionAcceptedRequest,
} from "domains/proposals/shared/types";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";
import { TAppState } from "store/root.store";
import { getOldOfferOptionValue } from "domains/proposals/shared/helpers";
import { apiService } from "common/shared";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { myApplicationsApiReducer } from "domains/myApplications/store/myApplications.api.reducer";

export const proposalsApiReducer = rootApiReducer.injectEndpoints({
  endpoints: (build) => ({
    fetchProposals: build.query<IProposals | null, string>({
      queryFn: async (LRId) => {
        try {
          const data = await proposalsApiService.fetchProposals(LRId);

          return { data };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    fetchPreviousProposals: build.query<IProposals[] | [], string>({
      queryFn: async (LRId) => {
        try {
          const data = await proposalsApiService.fetchPreviousProposals(LRId);

          return { data };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    requestReactivationProposal: build.mutation<void, number>({
      queryFn: async (idProposal) => {
        try {
          await proposalsApiService.requestReactivationProposal(idProposal);

          return { data: undefined };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    setOfferOptionAccepted: build.mutation<void, ISetOfferOptionAcceptedRequest>({
      queryFn: (args, { dispatch }) => {
        dispatch(
          proposalsApiReducer.util.updateQueryData("fetchProposals", args.LRId, (draft) => {
            if (!draft) return;

            return {
              ...draft,
              asset_groups: getOldOfferOptionValue(draft.asset_groups, args),
            };
          }),
        );

        return { data: undefined };
      },
    }),

    updateProposals: build.mutation<void, string>({
      queryFn: async (LRId, { getState, dispatch }) => {
        try {
          const proposals = proposalsApiReducer.endpoints.fetchProposals.select(LRId)(getState() as TAppState).data;
          const { initProposals } = (getState() as TAppState).proposalsReducer;

          const initOfferOptionsValue = initProposals?.asset_groups.reduce(
            (arr: IOfferOption[], asset: IAssetGroup) => [ ...arr, ...asset.offer_options ],
            [],
          );
          const offerOptionsValue = proposals?.asset_groups.reduce(
            (arr: IOfferOption[], asset: IAssetGroup) => [ ...arr, ...asset.offer_options ],
            [],
          );

          const needToUpdate = offerOptionsValue?.filter(
            (option: IOfferOption, index: number) =>
              option.is_accepted !== initOfferOptionsValue?.[ index ].is_accepted,
          );

          needToUpdate &&
            (await Promise.all(
              needToUpdate.map((option: IOfferOption) =>
                proposalsApiService.updateProposals(option.option_id, option.is_accepted),
              ),
            ).catch((error) => {
              return Promise.reject(error);
            }));

          dispatch(
            liquidityApiReducer.endpoints.updateLiquidityRequest.initiate({
              LRId,
              changes: { status: LiquidityRequestStatus.PendingOwnership },
            }),
          );
          dispatch(myApplicationsApiReducer.util.invalidateTags([ { type: "allWorkflowTasks", id: "LIST" } ]));

          return { data: undefined };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),

    resubmitAssets: build.mutation<void, IResubmitAssetsRequest>({
      queryFn: async ({ optionId, note, LRId }, { dispatch }) => {
        try {
          await proposalsApiService.resubmitAssets(optionId, note);

          dispatch(
            proposalsApiReducer.util.updateQueryData("fetchProposals", LRId, (draft) => {
              if (!draft) return;
              return { ...draft, customer_note: note };
            }),
          );

          return { data: undefined };
        } catch (error) {
          return apiService.formatResponseError(error as AxiosError);
        }
      },
    }),
  }),
});
