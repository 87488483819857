import { useCallback, useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/query/react";
import { Select, SelectProps } from "antd";

import { useClientsSelector } from "domains/clients";
import { custodyAccountsApiReducer } from "domains/custodyAccounts/store/custodyAccounts.api.reducer";
import { useAppDispatch } from "store/store.hooks";
import { Tooltip } from "common/lib/components";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";
import { ICustodyAccount } from "domains/custodyAccounts/shared/types";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";

import "./CustodyAccountPicker.scss";

const CustodyAccountPicker = () => {
  const dispatch = useAppDispatch();
  const { currentCustodyAccount } = useCustodyAccountsSelector();
  const { user } = useClientsSelector();
  const { data: custodyAccounts } = custodyAccountsApiReducer.useFetchCustodyAccountsQuery(
    user ? { accountId: user.account_id, userId: user.user_id } : skipToken,
  );

  const allCustodyAcconts = {
    label: "All Custody Accounts",
    value: "all",
  }
  const options: SelectProps["options"] = useMemo(() => {
    return [
      allCustodyAcconts,
    ].concat(
      custodyAccounts?.map((custodyAccount) => ({
        label: custodyAccount.account_name,
        value: custodyAccount.account_id,
      })) ?? [],
    );
  }, [ custodyAccounts ]);

  const handleChange = useCallback((accountId: string | "all") => {
    if (!custodyAccounts) return;

    const custodyAccount = custodyAccounts.find((custodyAccount) => custodyAccount.account_id === accountId);
    dispatch(setCurrentCustodyAccount(custodyAccount as unknown as ICustodyAccount));
  }, []);

  return (
    <div className="custody-account-picker-container" style={ { alignItems: 'flex-start' } }>
      <p style={ { textAlign: "left" } }>
        Custody Account <Tooltip content="" />
      </p>
      <Select onChange={ handleChange } defaultValue="all" options={ options } value={ currentCustodyAccount?.account_id || allCustodyAcconts.value  } />
    </div>
  );
};

export default CustodyAccountPicker;
