import { apiEnvs, apiService, fundSearchService } from "common/shared";
import { IChangedAssetValue, IClientData, IFetchLiquidityRequestsResponse, ILiquidityRequest } from "./types";
import { IUserDetails } from "domains/clients/shared/types";
import { formatLRToUpdate } from "./utils/apiFormatter";
import { getMatchedFunds } from "./helpers";
import { getIsTrustbenAPIRequest } from "domains/trustben/shared/helpers";
import { formatValuesForUpdate } from "common/shared/helpers/formatter/apiFormatter";

export class LiquidityApiService {
  private config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  async getFunds(fundName: string) {
    const preqinFundSearchItems = await fundSearchService.getPreqinFundSearchItems();
    const otherFundSearchItems = await fundSearchService.getOtherFundsSearchItems();
    const matches = getMatchedFunds(fundName, preqinFundSearchItems.concat(otherFundSearchItems));

    return matches;
  }

  public async fetchLRAssets(LRId: string) {
    const { isTrustbenAPIRequest, sessionId } = getIsTrustbenAPIRequest();
    if (isTrustbenAPIRequest) {
      return apiService.get(
        `${ apiEnvs.holdingsAPI }/v1/products/${ LRId }/assets?filter[0][field]=session_id&filter[0][operator]=equal&filter[0][value]=${ sessionId }`,
        this.config,
      );
    }

    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/products/${ LRId }/assets`, this.config);
  }

  async updateLRAsset(LRId: string, changes: IChangedAssetValue[]) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/assets/${ LRId }`, changes, config);
  }

  async deleteLRAsset(assetId: string, LRId: string) {
    return apiService.delete(`${ apiEnvs.holdingsAPI }/v1/assets/${ assetId }/liquidity_requests/${ LRId }`);
  }

  async createLiquidityRequest(liquidityRequest: any) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests`, liquidityRequest, config);
  }

  async createAsset(asset: any) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/assets`, asset, config);
  }

  async updateAsset(assetId: string, changes: IChangedAssetValue[]) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/assets/${ assetId }`, formatValuesForUpdate(changes as any), config);
  }

  async updateLiquidityRequest(LRId: string, changes: Partial<ILiquidityRequest>) {
    const dataToUpdate = formatLRToUpdate(changes);

    return apiService.patch(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }`, dataToUpdate);
  }

  async fetchLiquidityRequests(isAdvisor: boolean, user: IUserDetails): Promise<IFetchLiquidityRequestsResponse> {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return isAdvisor
      ? apiService.get(
          `${ apiEnvs.holdingsAPI }/v1/liquidity_requests?page[size]=100&sort[direction]=desc&sort[field]=updated_date&filter[0][field]=is_notification_allowed&filter[0][operator]=equal&filter[0][value]=true`,
          config,
        )
      : apiService.get(
          `${ apiEnvs.holdingsAPI }/v1/liquidity_requests?shared_with=${ user.user_id }&page[size]=100&sort[direction]=desc&sort[field]=updated_date&filter[0][field]=is_notification_allowed&filter[0][operator]=equal&filter[0][value]=true&filter[0][field]=account_id&filter[0][operator]=equal&filter[0][value]=${ user.account_id }`,
          config,
        );
  }

  async fetchLiquidityRequest(LRId: string) {
    const { isTrustbenAPIRequest, sessionId } = getIsTrustbenAPIRequest();
    if (isTrustbenAPIRequest) {
      return apiService.get(
        `${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }?session_id=${ sessionId }`,
        this.config,
      );
    }

    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }`, this.config);
  }

  async fetchLRAccounts(id: string) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ id }/accounts`, config);
  }

  async fetchLRSharings(LRId: string) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return apiService.get(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/sharings`, config);
  }

  async shareLRAccess(LRId: string, data: IClientData) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return apiService.post(`${ apiEnvs.holdingsAPI }/v1/liquidity_requests/${ LRId }/sharings`, data, config);
  }
}

const liquidityApiService = new LiquidityApiService();

export default liquidityApiService;
