import { useEffect } from "react";

import { message, UploadProps } from "antd";
import { RcFile } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query/react";

import { truncateFileName } from "domains/documents/shared/utils";
import useCustodialAgreement from "common/shared-hooks/useCustodialAgreement";
import { DocumentType, allowedMimeTypes } from "domains/documents/shared/constants";
import { useLiquiditySelector } from "domains/liquidity/store/liquidity.selectors";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import apiService from "common/shared/services/api.service/api.service";
import { DocumentGridStatus, IDocument } from "domains/documents/shared/types";
import { useAppDispatch } from "store/store.hooks";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { LiquidityRequestStatus } from "domains/liquidity/shared/types";

export const useUploadLRDocuments = () => {
  const { t } = useTranslation("liquidity");
  const dispatch = useAppDispatch();
  const { LRId } = useParams();

  const { currentLiquidityRequest } = useLiquiditySelector();
  const { checkCustodialAgreement } = useCustodialAgreement();
  const { data: assets = [] } = liquidityApiReducer.useFetchLRAssetsQuery(LRId ?? skipToken);
  const { data: liquidityRequests } = liquidityApiReducer.useFetchLiquidityRequestsQuery();
  const [ updateLiquidityRequest ] = liquidityApiReducer.useUpdateLiquidityRequestMutation({
    fixedCacheKey: `update-liquidity-request-${ LRId }`,
  });
  const [ uploadDocument, { data: document, isSuccess: isDocumentUploaded } ] =
    documentsApiReducer.useUploadDocumentMutation();
  const [ setLRDocumentGridData, { isLoading: isSettingLRDocumentGridData, isSuccess: isLRDocumentGridDataSetted } ] =
    documentsApiReducer.useSetLRDocumentGridDataMutation();

  const uploadProps: UploadProps = {
    accept: allowedMimeTypes.join(","),
    multiple: true,
    showUploadList: false,
    onDrop: (event) => {
      const invalid = Array.from(event.dataTransfer!.files).some((file) => !allowedMimeTypes.includes(file.type));
      if (invalid) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
      }
    },
    beforeUpload: (file): boolean => {
      if (!checkCustodialAgreement()) {
        return false;
      }

      if (!allowedMimeTypes.includes(file.type)) {
        message.error(t("validation.onlyPDF", { ns: "common" }));
        return false;
      }

      if ((file.size ?? 0) > 500 * 1_000_000) {
        message.error(t("validation.fileSize", { ns: "common", value: 500 }));
        return false;
      }

      return true;
    },
    customRequest: (options) => {
      const file = options.file as RcFile;

      const doc = {
        asset_id: null,
        file_name: truncateFileName(file.name.replace(/[^- _a-zA-Z0-9!.'()]/g, "-")),
        document_name:
          file.name.substring(0, file.name.lastIndexOf(".")).substring(0, 123) || file.name.substring(0, 123),
        document_type: DocumentType.Other,
        reference_type: "liquidity_request",
        file_size: file.size,
        liquidity_request_id: LRId,
        investment_id: null,
        do_not_have_this_document: false,
        id: file.uid,
        uid: file.uid,
        cancelToken: apiService.getCancelToken(),
        mimeType: file.type,
      };

      currentLiquidityRequest?.account_id &&
        uploadDocument({
          accountId: currentLiquidityRequest.account_id,
          document: doc as IDocument,
          file,
          LRId: currentLiquidityRequest.id,
        });
    },
  };

  useEffect(() => {
    if (!isDocumentUploaded || !document) return;

    const LRId = document.liquidity_request_id;
    const liquidityRequestStatus = liquidityRequests?.find((lr) => lr.id === LRId)?.currentTask
      ?.liquidity_request_status;
    const allDocsUploaded = assets.length > 0 && document;

    if (allDocsUploaded && liquidityRequestStatus === LiquidityRequestStatus.PendingAssetStatements && LRId) {
      updateLiquidityRequest({ LRId, changes: { status: LiquidityRequestStatus.InDocumentReview } });
    }
  }, [ isDocumentUploaded ]);

  useEffect(() => {
    if (!isDocumentUploaded || !document || isSettingLRDocumentGridData || isLRDocumentGridDataSetted) return;

    const LRId = document.liquidity_request_id as string;
    setLRDocumentGridData({ LRId, status: DocumentGridStatus.PendingReview });
  }, [ isDocumentUploaded ]);

  return { uploadProps };
};
