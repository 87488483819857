import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/query/react";

import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { useClientsSelector } from "domains/clients";
import { AssetType } from "domains/liquidity/shared/constants";

export const useAssetsHoldingsData = () => {
  const { currentClient, isAdvisor, user } = useClientsSelector();
  const { data: investments, isFetching: isFetchingInvestmnets } = altCustodyApiReducer.useFetchInvestmentsQuery(
    (isAdvisor ? currentClient?.account_id : user?.account_id) || skipToken,
  );

  const fundInvestments = useMemo(
    () =>
      (
        investments?.filter(
          (asset) => asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund,
        ) ?? []
      ).toSorted((a, b) => a?.asset_name?.replace(/\D/g, "")?.localeCompare(b?.asset_name?.replace(/\D/g, ""))),
    [ investments ],
  );

  const nonFundInvestments = useMemo(
    () =>
      investments?.filter(
        (asset) => !(asset.asset_type === AssetType.Fund || asset.asset_type === AssetType.PreqinFund),
      ) ?? [],
    [ investments ],
  );

  return {
    investments,
    fundInvestments,
    nonFundInvestments,
    loading: isFetchingInvestmnets,
  };
};
