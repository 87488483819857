/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { altCustodyApiReducer } from "domains/altCustody/store/altCustody.api.reducer";
import { AltCustodyMutationCacheKey } from "domains/altCustody/shared/constants";
import { rootStore } from "store";
import { useAppDispatch } from "store/store.hooks";
import { setAssetsPendingCustodyAccount } from "domains/liquidity/store/liquidity.reducer";
import { LAPages } from "domains/liquidity";
import { useCustodyAccountsSelector } from "domains/custodyAccounts/store/custodyAccounts.selectors";
import { CustodyAccountStatus } from "domains/custodyAccounts/shared/types";
import { setCurrentCustodyAccount } from "domains/custodyAccounts/store/custodyAccounts.reducer";

export const useInvestments = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const isAlquoteFlow  = pathname.includes('new-altquote')
  const { currentCustodyAccount } = useCustodyAccountsSelector()
  
  const [ create ] = altCustodyApiReducer.useCreateInvestmentMutation({
    fixedCacheKey: AltCustodyMutationCacheKey.CREATE_INVESTMENT,
  });

  type params = {
    skipAccountConfiguration: boolean
  }

  const createInvestment = async ( params?:params ) =>{
    const { custodyAccounts } = rootStore.getState().custodyAccountsReducer;
    const activeCustodyAccounts = custodyAccounts?.filter(
      (custodyAccount) =>
        custodyAccount.account_status !== CustodyAccountStatus.Closed &&
        custodyAccount.account_status !== CustodyAccountStatus.ClosedForNewAccount &&
        custodyAccount.account_status !== CustodyAccountStatus.Duplicate,
    )
    const currentPath = location.pathname;
    const pathParts = currentPath.split('/');
    const isAltquoteCreation = pathname.includes('altquote')
    const shouldCreateCustodyAccount = !activeCustodyAccounts?.length && !isAlquoteFlow && !isAltquoteCreation 
    const selectedCustodyAccount = currentCustodyAccount || (activeCustodyAccounts && activeCustodyAccounts.length && activeCustodyAccounts[ 0 ])
    const shouldUpdateCustodyAccount = (selectedCustodyAccount && (
      selectedCustodyAccount.account_status === CustodyAccountStatus.Closed ||
      selectedCustodyAccount.account_status === CustodyAccountStatus.ClosedForNewAccount ||
      selectedCustodyAccount.account_status === CustodyAccountStatus.Duplicate
    ))

    const isLiquidityRequestCreation  = pathname.includes('liquidity-request')
    let newPath = ``;
    const { data } = await create()
    pathParts.pop();

    if (data) {
      dispatch(setAssetsPendingCustodyAccount(data.asset_id))
    }

    if (params?.skipAccountConfiguration) {
      return
    }

    if (shouldCreateCustodyAccount || shouldUpdateCustodyAccount) {
      newPath = `${ pathParts.join('/') }/${ LAPages.CustodyAccountAgreement }`;
      if (shouldUpdateCustodyAccount) {
        newPath = `${ pathParts.join('/') }/${ LAPages.CustodyAccount }`;
        dispatch(setCurrentCustodyAccount(selectedCustodyAccount as any))
      }
    } else if (isLiquidityRequestCreation ) {
      pathParts.pop();
      newPath = `${ pathParts.join('/') }/new-liquidity-request?confirmAssets=true`;
    } else if  ( isAltquoteCreation) {
      pathParts.pop();
      newPath = `${ pathParts.join('/') }/new-altquote?confirmAssets=true`;
    } else {
      newPath = `${ pathParts.join('/') }/asset_documents`;
    }
    
    navigate(newPath)
    return
  };

  const handlers = useMemo(
    () => ({
      createInvestment,
    }),
    [ createInvestment ],
  );

  return handlers;
};
