import { PieChart as RechartsPieChart, Pie, Cell, ResponsiveContainer, Tooltip, Sector } from "recharts";

import { IChart } from "domains/altCustody/shared/types";
import CustomChartTooltip from "domains/altCustody/components/CustomChartTooltip";
import { ChartType } from "domains/altCustody/shared/constants";

const COLORS = [ "#0088FE", "#00C49F", "#FFBB28", "#FF8042" ];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={ x } y={ y } fill="white" textAnchor="middle" dominantBaseline="central">
      {`${ (percent * 100).toFixed(0) }%`}
    </text>
  );
};

interface IProps {
  data: IChart["data"];
  activeIndex: number | undefined;
  onChartFragmentClick: (activeChartInfo: { entry: IChart["data"][0]; activeIndex: number } | undefined) => void;
}

const PieChart = ({ data, onChartFragmentClick, activeIndex }: IProps) => {
  const renderActiveShape = ({ cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill }: any) => {
    return (
      <g cursor={ "pointer" }>
        <Sector
          cx={ cx }
          cy={ cy }
          innerRadius={ innerRadius }
          outerRadius={ outerRadius + 5 }
          startAngle={ startAngle }
          endAngle={ endAngle }
          fill={ fill }
        />
      </g>
    );
  };

  return (
    <ResponsiveContainer aspect={ 1 }>
      <RechartsPieChart>
        <Pie
          cursor={ "pointer" }
          activeIndex={ activeIndex }
          activeShape={ renderActiveShape }
          data={ data }
          cx="50%"
          cy="50%"
          labelLine={ false }
          label={ renderCustomizedLabel }
          outerRadius={ 80 }
          fill="#8884d8"
          dataKey="value"
          onClick={ (data, index) => {
            if (activeIndex === index) {
              onChartFragmentClick(undefined);
              return;
            }

            onChartFragmentClick({ entry: data, activeIndex: index });
          } }
        >
          {data.map((_entry, index) => (
            <Cell key={ `cell-${ index }` } fill={ COLORS[ index % COLORS.length ] } />
          ))}
        </Pie>

        {data.length === 0 && <Pie dataKey="value" data={ [ { name: "No Data", value: 1 } ] } fill="#eeeeee" />}

        {data.length !== 0 && <Tooltip content={ <CustomChartTooltip type={ ChartType.PIE } /> } />}
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
