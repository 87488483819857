import { FC } from "react";

import {
  BarChart as RechartsBarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { IChart } from "domains/altCustody/shared/types";
import CustomChartTooltip from "domains/altCustody/components/CustomChartTooltip";
import { ChartType } from "domains/altCustody/shared/constants";

interface IProps {
  data: IChart["data"];
  activeIndex: number | undefined;
  onChartFragmentClick: (activeChartInfo: { entry: IChart["data"][0]; activeIndex: number } | undefined) => void;
}

const BarChart: FC<IProps> = ({ data, activeIndex, onChartFragmentClick }) => {
  return (
    <ResponsiveContainer aspect={ 1 }>
      <RechartsBarChart data={ data }>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Bar
          cursor={ "pointer" }
          dataKey="value"
          fill="#8884d8"
          activeIndex={ activeIndex }
          activeBar={ <Rectangle fill="pink" stroke="blue" /> }
          onClick={ (data, index) => {
            if (activeIndex === index) {
              onChartFragmentClick(undefined);
              return;
            }

            onChartFragmentClick({ entry: data, activeIndex: index });
          } }
        />

        {data.length === 0 && <Bar dataKey="name" data={ [ { name: "No Data", value: 1 } ] } fill="#eeeeee" />}

        {data.length !== 0 && (
          <Tooltip content={ <CustomChartTooltip type={ ChartType.BAR } /> } cursor={ { fill: "transparent" } } />
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
