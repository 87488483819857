import { FC, createElement, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";

import EditChartModal from "domains/altCustody/components/EditChartModal";
import { ChartType } from "domains/altCustody/shared/constants";
import BarChart from "domains/altCustody/components/BarChart";
import PieChart from "domains/altCustody/components/PieChart";
import { Button } from "common/lib/components";
import { IChart } from "domains/altCustody/shared/types";
import { useAppDispatch } from "store/store.hooks";
import { updateChart } from "domains/altCustody/store/altCustody.reducer";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";
import { formatChartTitleToHumanReadable, prepareChartData } from "domains/altCustody/shared/utils/helpers";

import "./ChartContainer.scss";

interface IProps {
  chart: IChart;
  tableDataSource: any;
  activeIndex: number | undefined;
  onEdit?: any,
  onChartFragmentClick: (
    activeChartInfo: { chart: IChart; entry: IChart["data"][0]; activeIndex: number } | undefined
  ) => void;
}

// const charts: Record<`${ ChartType }`, ReactNode> = {
const chartsComponents: Record<`${ ChartType }`, any> = {
  bar: BarChart,
  pie: PieChart,
};

const ChartContainer: FC<IProps> = ({ chart, onEdit, tableDataSource, onChartFragmentClick, activeIndex }) => {
  const { t } = useTranslation("");
  const handleChartFragmentClick = useCallback(
    (activeChartInfo: { entry: IChart["data"][0]; activeIndex: number } | undefined) => {
      if (!activeChartInfo) {
        onChartFragmentClick(undefined);
        return;
      }

      onChartFragmentClick({ chart, entry: activeChartInfo.entry, activeIndex: activeChartInfo.activeIndex });
    },
    [ chart ],
  );

  return (
    <div className="ben-chart-container">
      <p className="chart-title">{chart.title}</p>

      <Button className="edit-chart-button" onClick={ onEdit } type="text">
        {t("Edit")}
      </Button>

      {chart.type &&
        createElement(chartsComponents[ chart.type ], {
          data: chart.data,
          onChartFragmentClick: handleChartFragmentClick,
          activeIndex,
        })}
    </div>
  );
};

export default ChartContainer;
