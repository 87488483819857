export interface IChangedValue {
  op: string;
  path: string;
  value: any;
}

export const formatValuesForUpdate = <T extends Record<string, unknown>>(values: T): IChangedValue[] => {
  return Object.entries(values).reduce((acc: IChangedValue[], [ key, value ]) => {
    acc.push({
      op: "replace",
      path: `/${ key }`,
      value: value,
    });

    return acc;
  }, []);
};
