import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILiquidityRequest, TAssetType } from "domains/liquidity/shared/types";
import { liquidityApiReducer } from "./liquidity.api.reducer";

export interface IInitialState {
  liquidity_request_id: string | null;
  isAddManually: boolean;
  isLA: boolean;
  currentAsset: Partial<TAssetType>;
  assetsPendingCreation: TAssetType[];
  assetsPendingCustodyAccount: string[];
  selectedAssetsForLiquidity: string[];
  currentLiquidityRequest: ILiquidityRequest | null;
  currentAssetCustodyAccount: string | null
}

const initialState: IInitialState = {
  isAddManually: false,
  isLA: true,
  liquidity_request_id: null,
  currentAssetCustodyAccount: null,
  currentAsset: {},
  assetsPendingCreation: [],
  selectedAssetsForLiquidity: [],
  assetsPendingCustodyAccount: [],
  currentLiquidityRequest: null,
  
};

const liquidityReducer = createSlice({
  name: "[Liquidity]",
  initialState,
  reducers: {
    setIsLA: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isLA: action.payload,
    }),

    setIsAddManually: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isAddManually: action.payload,
    }),

    setCurrentAssetData: (state, action: PayloadAction<Partial<TAssetType>>) => ({
      ...state,
      currentAsset: {
        ...state.currentAsset,
        ...action.payload,
      },
    }),
    setCurrentAssetCustodyAccount: (state, action: PayloadAction<string>) => {
      return ({
      ...state,
      currentAssetCustodyAccount: action.payload,
    })},
    resetCurrentAssetData: (state) => ({
      ...state,
      currentAsset: {},
    }),

    setAssetsPendingCustodyAccount: (state, action: PayloadAction<any>) => {
      const assetsPendingCustodyAccount = state.assetsPendingCustodyAccount 
      ? [ ...state.assetsPendingCustodyAccount, action.payload ] 
      : [ action.payload ]
      return ({
        ...state,
        assetsPendingCustodyAccount,
      })
    },
    resetAssetsPendingCustodyAccount: (state) => {
      return ({
      ...state,
      assetsPendingCustodyAccount: [],
    })},
    setAssetsPendingCreation: (state, action: PayloadAction<TAssetType>) => ({
      ...state,
      assetsPendingCreation: [ ...state.assetsPendingCreation, action.payload ],
      currentAsset: {},
    }),


    resetAssetsPendingCreation: (state) => {
      return ({
      ...state,
      assetsPendingCreation: [],
    })},

    setSelectedAssetsForLiquidity: (state, action: PayloadAction<any>) => ({
      ...state,
      selectedAssetsForLiquidity:  action.payload,
    }),

    unsetSelectedAssetsForLiquidity: (state, action: PayloadAction<any>) => {
      state.selectedAssetsForLiquidity = state.selectedAssetsForLiquidity.filter(
        (selected) => selected !== action.payload,
      );
    },

    resetSelectedAssetsForLiquidity: (state) => {
      return ({
      ...state,
      selectedAssetsForLiquidity: [],
    })},

    setAssetsRemoveById: (state, action: PayloadAction<string>) => { 
      const assetsPendingCreation = state.assetsPendingCreation.filter(
        (asset) => asset.asset_id !== action.payload,
      )
      return ({
      ...state,
      assetsPendingCreation,
    }) },
    setCurrentLiquidityRequestData: (state, action: PayloadAction<Partial<ILiquidityRequest>>) => {
      return ({
      ...state,
      currentLiquidityRequest: {
        ...state.currentLiquidityRequest,
        ...action.payload,
      } as ILiquidityRequest,
    })},

    resetCurrentLiquidityRequestData: (state) => ({
      ...state,
      currentLiquidityRequest: null,
    }),

    setLiquidityReducerData: (state, action: PayloadAction<Partial<IInitialState>>) => ({
      ...state,
      ...action.payload,
    }),

    resetLiquidityReducer: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(liquidityApiReducer.endpoints.createLRAsset.matchFulfilled, (state) => ({
      ...state,
      currentAsset: initialState.currentAsset,
    })),
      builder.addMatcher(liquidityApiReducer.endpoints.fetchLRAssets.matchPending, (state, action) => ({
        ...state,
        liquidity_request_id: action.meta.arg.originalArgs,
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.updateLRAsset.matchFulfilled, (state) => ({
        ...state,
        currentAsset: initialState.currentAsset,
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.createLiquidityRequest.matchPending, (state, action) => ({
        ...initialState,
        ...action.meta.arg.originalArgs,
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.createLiquidityRequest.matchFulfilled, (state, action) => ({
        ...state,
        liquidity_request_id: action.payload,
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.fetchLiquidityRequest.matchPending, (state, action) => ({
        ...state,
        currentLiquidityRequest:
          state?.currentLiquidityRequest?.id === action.meta.arg.originalArgs
            ? { ...state.currentLiquidityRequest }
            : ({ id: action.meta.arg.originalArgs } as ILiquidityRequest),
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.fetchLiquidityRequest.matchFulfilled, (state, action) => ({
        ...state,
        currentLiquidityRequest: { ...state.currentLiquidityRequest, ...action.payload },
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.createAssetTrustben.matchPending, (state, action) => ({
        ...state,
        assetsPendingCreation: state.assetsPendingCreation.map((asset) =>
          asset.asset_id.includes(action.meta.arg.originalArgs.asset.asset_id.split("_").at(-1)!)
            ? { ...asset, asset_id: action.meta.arg.originalArgs.asset.asset_id }
            : asset,
        ),
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.createAssetTrustben.matchFulfilled, (state, action) => ({
        ...state,
        assetsPendingCreation: state.assetsPendingCreation.filter(
          (asset) => asset.asset_id !== action.payload.dummyAssetId,
        ),
      })),
      builder.addMatcher(liquidityApiReducer.endpoints.createAssetTrustben.matchRejected, (state, action) => ({
        ...state,
        assetsPendingCreation: state.assetsPendingCreation.filter(
          (asset) => asset.asset_id !== action.meta.arg.originalArgs.asset.asset_id,
        ),
      }));
  },
});

export const {
  setIsAddManually,
  setCurrentAssetData,
  resetCurrentAssetData,
  setAssetsPendingCreation,
  resetAssetsPendingCreation,
  setCurrentAssetCustodyAccount,
  setCurrentLiquidityRequestData,
  resetCurrentLiquidityRequestData,
  resetAssetsPendingCustodyAccount,
  unsetSelectedAssetsForLiquidity,
  resetSelectedAssetsForLiquidity,
  setAssetsPendingCustodyAccount,
  setSelectedAssetsForLiquidity,
  setLiquidityReducerData,
  resetLiquidityReducer,
  setAssetsRemoveById,
  setIsLA,
} = liquidityReducer.actions;

export default liquidityReducer.reducer;
