import { URLs } from "common/lib/constants";
import { router } from "common/shared";
import { AltCustodyAssetPages, ChartDataKey, ChartTitle } from "domains/altCustody/shared/constants";
import { AssetType } from "domains/liquidity/shared/constants";
import { IChart } from "domains/altCustody/shared/types";

export const prepareChartData = ({ data, dataKey }: { data: any; dataKey: `${ ChartDataKey }` }): IChart["data"] => {
  const formatData = () =>
    Object.entries(
      data.reduce((acc: any, cur: any) => {
        if (acc[ cur[ dataKey ] ]) {
          acc[ cur[ dataKey ] ] += 1;
        } else {
          acc[ cur[ dataKey ] ] = 1;
        }

        return acc;
      }, {}),
    ).map(([ name, value ]) => ({
      name,
      value,
    })) as unknown as IChart["data"];

  const formatNumericData = (nameDataKey: string, dataKey: string) =>
    Object.entries(
      data.reduce((acc: any, cur: any) => {
        // Validamos que el objeto cur tenga las propiedades esperadas
        if (cur[ nameDataKey ] && cur[ dataKey ]) {
          const numericValue = Number(cur[ dataKey ].replace(/\D/g, ""));
  
          if (acc[ cur[ nameDataKey ] ]) {
            acc[ cur[ nameDataKey ] ] += numericValue;
          } else {
            acc[ cur[ nameDataKey ] ] = numericValue;
          }
        }
        return acc;
      }, {}),
    ).map(([ name, value ]) => ({
      name,
      value,
    })) as unknown as IChart["data"];
  const formatDateData = (nameDataKey: string) =>
    Object.entries(
      data.reduce((acc: any, cur: any) => {
        acc[ cur[ nameDataKey ] ] = new Date(cur[ dataKey ]).getTime();

        return acc;
      }, {}),
    ).map(([ name, value ]) => ({
      name,
      value,
    })) as unknown as IChart["data"];

  switch (dataKey) {
    case ChartDataKey.INSTRUMENT_TYPE:
      return formatData();

    case ChartDataKey.FUND_INVESTMENT_NAV:
      return formatNumericData("fund_name", "investor_nav");

    case ChartDataKey.PUBLIC_OR_PRIVATE:
      return formatData();

    case ChartDataKey.COUNTRY:
      return formatData();

    case ChartDataKey.ASSET_CLASS:
      return formatData();

    case ChartDataKey.SUB_ASSET_CLASS:
      return formatData();

    case ChartDataKey.SECTOR:
      return formatData();

    case ChartDataKey.INDUSTRY_GROUP:
      return formatData();

    case ChartDataKey.INDUSTRY:
      return formatData();

    case ChartDataKey.SUB_INDUSTRY:
      return formatData();

    case ChartDataKey.NAV_AS_OF_DATE:
      return formatData();

    default:
      return {} as IChart["data"];
  }
};

export const formatChartTitleToHumanReadable = (dataKey: `${ ChartDataKey }`) => {
  switch (dataKey) {
    case ChartDataKey.INSTRUMENT_TYPE:
      return ChartTitle.INSTRUMENT_TYPE;

    case ChartDataKey.FUND_INVESTMENT_NAV:
      return ChartTitle.FUND_INVESTMENT_NAV;

    case ChartDataKey.PUBLIC_OR_PRIVATE:
      return ChartTitle.PUBLIC_OR_PRIVATE;

    case ChartDataKey.COUNTRY:
      return ChartTitle.COUNTRY;

    case ChartDataKey.ASSET_CLASS:
      return ChartTitle.ASSET_CLASS;

    case ChartDataKey.SUB_ASSET_CLASS:
      return ChartTitle.SUB_ASSET_CLASS;

    case ChartDataKey.SECTOR:
      return ChartTitle.SECTOR;

    case ChartDataKey.INDUSTRY_GROUP:
      return ChartTitle.INDUSTRY_GROUP;

    case ChartDataKey.INDUSTRY:
      return ChartTitle.INDUSTRY;

    case ChartDataKey.SUB_INDUSTRY:
      return ChartTitle.SUB_INDUSTRY;

    case ChartDataKey.NAV_AS_OF_DATE:
      return ChartTitle.NAV_AS_OF_DATE;

    default:
      return "";
  }
};

export const navigateByAssetType = (assetType: AssetType) => {
  switch (assetType) {
    case AssetType.PreqinFund:
      router.navigate(URLs.PROTECTED.DASHBOARD + URLs.PROTECTED.ALT_CUSTODY + AltCustodyAssetPages.InvestmentDetails);
      break;

    case AssetType.Fund:
      router.navigate(URLs.PROTECTED.DASHBOARD + URLs.PROTECTED.ALT_CUSTODY + AltCustodyAssetPages.AddManually);
      break;

    default:
      router.navigate(
        URLs.PROTECTED.DASHBOARD + URLs.PROTECTED.ALT_CUSTODY + AltCustodyAssetPages.SelectAssets + "?tab=1",
      );
  }
};

export const navigateToMyAssets = (isAdvisor: boolean) => {
  isAdvisor ? router.navigate(URLs.PROTECTED.MY_CLIENTS_ASSETS) : router.navigate(URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS);
};
