import { CustodyAccountStatus } from "./types";

export const getCustodyAccountStatus = (status: string) => {
  switch (status) {
    case CustodyAccountStatus.Active:
      return "Active";
    case CustodyAccountStatus.NeedsId:
      return "Needs ID";
    case CustodyAccountStatus.Pending:
      return "Pending";
    case CustodyAccountStatus.Closed:
      return "Closed";
  }
};
