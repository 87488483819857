import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

import { IDocument } from "domains/documents/shared/types";
import { documentsApiReducer } from "./documents.api.reducer";

interface IInitialState {
  uploadingDocuments: IDocument[];
}

const initialState: IInitialState = {
  uploadingDocuments: [],
};

const documentsReducer = createSlice({
  name: "[Documents]",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(documentsApiReducer.endpoints.uploadDocument.matchPending, (state, action) => {
        const document = action.meta.arg.originalArgs.document;
        const serializedDoc: any = { ...document }
        delete serializedDoc.cancelToken
        if (serializedDoc && typeof serializedDoc.uid === "string") {
          state.uploadingDocuments.push(serializedDoc as IDocument);
        }
      })
      .addMatcher(documentsApiReducer.endpoints.uploadDocument.matchFulfilled, (state, action) => {
        state.uploadingDocuments = state.uploadingDocuments.filter(
          (document) => document.uid !== action.payload.uid,
        );
      })
      .addMatcher(documentsApiReducer.endpoints.uploadDocument.matchRejected, (state, action) => {
        const errorMessage = action.error?.message || "An error occurred while uploading the file";
        message.error(errorMessage);

        state.uploadingDocuments = state.uploadingDocuments.filter(
          (document) => document.uid !== (action.meta.arg.originalArgs.document as IDocument).uid,
        );
      });
  },
});

export default documentsReducer.reducer;
