/* eslint-disable max-len */
/* eslint-disable no-restricted-imports */
import { FC, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";

import { useClientsSelector } from "domains/clients";
import { documentsApiReducer } from "domains/documents/store/documents.api.reducer";
import { AssetPortfolioDocumentsUpload } from "../assetPortfolioDocumentsUpload";
import DocumentsStatusTable from "v2/features/documents/DocumentsStatusTable";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";

import "./portfolioManager.scss";

const PortfolioDocumentsTab: FC = () => {
  const { t } = useTranslation([ "myApplications", "altQuotes" ]);
  const { nonFundInvestments: nonFundAssets, loading } = useAssetsHoldingsData();
  // const { LRId } = useParams();
  // const [ fetchLRAssets, { isFetching: isFetchingLRAssets } ] = liquidityApiReducer.useLazyFetchLRAssetsQuery();
  const { user, isAdvisor, currentClient } = useClientsSelector();
  const { data: documentGridData, isFetching: isFetchingDocumentGridData } =
    documentsApiReducer.useFetchAccountDocumentGridDataQuery(user?.account_id ?? skipToken);


  const renderTable = () => <DocumentsStatusTable nonFundedAssets={ nonFundAssets } documentGridData={ documentGridData } isFetchingDocumentGridData={ isFetchingDocumentGridData || loading } />
    
  return (
    <>
      <div className="ben-assets-included-page">
        <h2>{t("Assets Included")}</h2>
        <div className="ben-assets-included-info">
          <p>{t("Assets Included Info")}</p>
          <p style={ { margin: "20px 0px" } }>
            Once we have done this internal review of the documents, the table below will be updated to reflect the
            status of all required documents per asset included within this application. Asset names may be updated
            based on document review.
          </p>
          <p>
            If a document meets the requirement, then the table will reflect a status of “Received.” If additional
            documents are required for your application, then the table will reflect a status of “Not Received.” If a
            document is not required, then the table will reflect a status of, “Not Needed.”
          </p>
        </div>
        {/* <Spinner spinning={ isFetchingLRAssets }> */}
          <AssetPortfolioDocumentsUpload />
        {/* </Spinner> */}
      </div>
      { renderTable() }
    </>
  );
};

export default PortfolioDocumentsTab;
