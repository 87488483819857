import { FC } from "react";

import { Table } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { desktopNonFundColumns, mobileNonFundColumns } from "./constants";
import { useScreen } from "common/shared-hooks";
import { TNonFundAsset } from "domains/liquidity/shared/types";
import { getAssetName, getAssetType } from "domains/liquidity/shared/utils/formatter";
import { Spinner, Button } from "common/lib/components";
import { useAltQuotesSelector } from "domains/altQuotes";
import { liquidityApiReducer } from "domains/liquidity/store/liquidity.api.reducer";
import { URLs } from "common/lib/constants";

import "./NonFundAssetsTable.scss";

interface IProps {
  title: string;
  nonFundAssets: TNonFundAsset[];
  onDelete?: (asset_id: string, LRId: string) => void;
}

const NonFundAssetsTable: FC<IProps> = ({ title, nonFundAssets, onDelete }) => {
  const { t } = useTranslation([ "liquidity", "common" ]);
  const { pathname } = useLocation();
  const { LRId } = useParams();

  const { mobile } = useScreen();

  const { isAltQuoteTab } = useAltQuotesSelector();
  const [ _, { isLoading: isDeletingLRAsset } ] = liquidityApiReducer.useDeleteLRAssetMutation({
    fixedCacheKey: "delete-lr-asset",
  });

  const isAltCustodyFlow = pathname.includes(URLs.PROTECTED.ALT_CUSTODY);
  const nonFundDataSource = nonFundAssets.map((asset, i) => ({
    key: i,
    asset: (
      <div>
        <b>
          <span>{getAssetName(asset)}</span>
        </b>
        <br />
        <span>{getAssetType(asset)}</span>
      </div>
    ),
    assetName: getAssetName(asset),
    assetType: getAssetType(asset),
    action: (
      <div style={ { display: "inline-block" } }>
        <Spinner
          spinning={
            isDeletingLRAsset || asset.asset_id.startsWith("pending_creation") || asset.asset_id.startsWith("creating")
          }
        >
          {!onDelete || isAltQuoteTab ? null : (
            <Button
              // @ts-ignore
              onClick={ () => (isAltCustodyFlow ? onDelete(asset.id) : onDelete(asset.asset_id, LRId!)) }
              type="primary"
            >
              {t("Delete", { ns: "common" })}
            </Button>
          )}
        </Spinner>
      </div>
    ),
  }));


  if (!nonFundAssets.length) return null;

  return (
    <div className="ben-form-table-container">
      <span className="non-fund-assets-table-title">{title}</span>

      <Table
        pagination={ false }
        dataSource={ nonFundDataSource }
        columns={ mobile ? mobileNonFundColumns : desktopNonFundColumns }
        className="ben-non-fund-assets-table"
      />
    </div>
  );
};

export default NonFundAssetsTable;
