/* eslint-disable no-restricted-imports */
import { FC } from "react";

import { useNavigate } from "react-router-dom";

import { URLs } from "common/lib/constants";
import { Button } from "common/lib/components";
import { AltCustodyAssetPages } from "domains/altCustody/shared/constants";
import { useAppDispatch } from "store/store.hooks";
import { useAssetsHoldingsData } from "domains/altCustody/components/AssetHoldingsTab/useAssetsHoldingsData";
import { resetAssetsPendingCreation } from "domains/liquidity/store/liquidity.reducer";
import { resetCurrentAssetData } from "domains/liquidity/store/liquidity.reducer";
import { useModalProps } from "common/shared-hooks/useModalProps";
import AddAdvisorClientModal from "../addAsset/AddAdvisorClientModal";
import { setCurrentClient } from "domains/clients/store/clients.reducer";
import { useAltCustodySelector } from "domains/altCustody/store/altCustody.selectors";

import "./assetActions.scss";

const AssetActions = ({ isAdvisor }:any) => {
  const { investments } = useAssetsHoldingsData();
  const { chartData, isChartDataFetching } = useAltCustodySelector();

  const modalProps = useModalProps();
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  
  const addAsset = () => {
    dispatch(resetCurrentAssetData());
    dispatch(resetAssetsPendingCreation());
    if (isAdvisor) {
      modalProps.handleShow();
      return;
    }
    navigate(`${ URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS }${ AltCustodyAssetPages.SelectAssets }`)
  }

  const showAnalyticsLink = chartData.length && chartData !== 'nodata'
  return (
    <>
      <div className="alt-custody-asset-list-container">
        <div className="buttons-container">
          { showAnalyticsLink 
            ? 
              <Button onClick={ () => navigate(`${ URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS }?tab=PortfolioAnalytics`) } type="text">
                { `Analytics` }
              </Button>
            : ""
          }

          <Button onClick={ () => navigate(`${ URLs.PROTECTED.MY_ALT_CUSTODY_ASSETS }?tab=PortfolioDocuments`) } type="text">
            { `Documents` }
          </Button>

          <Button onClick={ addAsset } type="text">
            { investments?.length ? "Add Another Asset +" :  isAdvisor ? "Add your First Client's Asset +" : "Add your First Asset +" }
          </Button>
        </div>
      </div>
      <AddAdvisorClientModal
        isVisible={ modalProps.isVisible }
        handleCancel={ modalProps.handleHide }
      />
    </>
  );
};

export default AssetActions;
